import { asPromise } from './promises';

/**
 * Function that return true, false, or undefined based on the specified arguments
 */
export type PredicateCallback<TArgs extends unknown[]> = (
  ...args: TArgs
) => boolean | undefined;

/**
 * Function that return true, false, or undefined based on the specified arguments
 */
export type AsyncPredicateCallback<TArgs extends unknown[]> = (
  ...args: TArgs
) => Promise<boolean | undefined>;

/**
 * Combines predicate functions that return true, false, or undefined into a single function that when called returns a boolean value using the specified operand.
 *
 * Remarks: undefined results from a predicate function are ignored.
 *
 * @param predicates functions that return true, false, or undefined based on the specified arguments
 * @param operand AND or OR, AND all must be true or undefined. OR any must be true
 * @returns function that when called returns a boolean value using the specified operand
 */
export function combinePredicates<TArgs extends unknown[]>(
  predicates: (PredicateCallback<TArgs> | AsyncPredicateCallback<TArgs>)[],
  operand: 'AND' | 'OR',
): (...args: TArgs) => Promise<boolean> {
  return async (...args: TArgs) => {
    if (operand === 'AND') {
      for (const predicate of predicates) {
        if ((await asPromise(predicate(...args))) === false) return false;
      }
      return true;
    } else {
      for (const predicate of predicates) {
        if ((await asPromise(predicate(...args))) === true) return true;
      }
      return false;
    }
  };
}

/**
 * Evaluates provided predicate function against an array of items and returns a boolean value using the specified operand.
 *
 * Remarks: undefined results from a predicate function are ignored.
 *
 * @param items the array of items to be evaluated
 * @param predicate function that return true, false, or undefined based on the specified arguments
 * @param operand AND or OR, AND all must be true or undefined. OR any must be true
 * @returns boolean value using the specified operand
 */
export function evaluatePredicate<T>(
  items: T[],
  predicate: PredicateCallback<[T]>,
  operand: 'AND' | 'OR' = 'OR',
): boolean | undefined {
  const out: boolean | undefined = items.reduce(
    (prev, item) => {
      if (
        (operand === 'AND' && prev === false) ||
        (operand === 'OR' && prev === true)
      ) {
        return prev;
      }
      return predicate(item);
    },
    undefined as boolean | undefined,
  );

  return out;
}
