import { mustGetFirstElement } from './array';
import {
  assertIsNonEmptyString,
  isDefined,
  isDefinedAndNonEmpty,
  throwValidationError,
} from './validation';

/**
 * Combines two or more uri path parts.
 *
 * @param base Can be uri or path part. Honors root relative base path
 * @param pathParts additional path parts to combine.
 * @returns a normalize combined uri or path, or empty string (if base is undefined)
 *
 * @remarks normalizes slashes between path parts and removes empty path parts
 */
export function combinePaths(
  base: string | undefined,
  ...pathParts: (string | undefined)[]
): string {
  const parts = [base, ...pathParts];
  const path = parts
    .map((part) =>
      isDefinedAndNonEmpty(part)
        ? removeLeadingSlash(removeTrailingSlash(part!))
        : undefined,
    )
    .filter((p) => !!p)
    .join('/');

  if (base?.startsWith('/')) {
    return '/' + path;
  }

  return path;
}

/**
 * Builds a root relative canonical url using the supplied parametric path, parameters, and querystring
 * @param endpoint
 * @param options
 * @returns
 */
export function buildParametricRootRelativeUrl(
  path: string,
  paramsAndQuery?: Record<string, unknown>,
): string {
  path = path.startsWith('/') ? path : `/${path}`;

  // eslint-disable-next-line prefer-const
  let { path: replacedPath, unusedParams } = replacePathParams(
    path,
    paramsAndQuery,
  );

  assertNoPathParams(replacedPath);

  return buildRelativeUrl(replacedPath, unusedParams);
}

/**
 * Builds a canonical url using the supplied parameters and querystring
 */
export function buildParametricUrl(
  host: string,
  path: string,
  paramsAndQuery?: Record<string, unknown>,
): string {
  assertIsNonEmptyString(host);

  const pathAndQuery = buildParametricRootRelativeUrl(path, paramsAndQuery);

  return combinePaths(host, pathAndQuery);
}

const parametricPathPart = /:([^:(/)]+)(?:\([^)/]+\))?/gi;

/** Replaces path params in the form /:id/ or /:limit(\d{1,3})/ or /:id? with the supplied parameters
 *
 * Expects param keys to align with path param names and will call toString() on param keys
 */
export function replacePathParams<TParam = any>(
  path: string,
  params: TParam,
): { path: string; unusedParams: Partial<TParam> } {
  // this should probably technically be the opposite of
  // the fastify param deserialization logic, but its not.
  assertIsNonEmptyString(path);

  if (!isDefined(params)) return { path, unusedParams: params };

  const colonIndex = path.indexOf(':');
  if (colonIndex < 0) return { path, unusedParams: params };

  const replacedPath = path
    .replaceAll(parametricPathPart, (original, paramName: string) => {
      if (paramName.endsWith('?')) {
        paramName = paramName.slice(0, -1);
        if (!isDefined((params as any)[paramName])) {
          return '';
        }
      }
      const value = (params as any)[paramName];
      delete (params as any)[paramName];
      if (!isDefined(value)) {
        return original;
      }
      // TODO: this should throw if the matched part is a regex
      // and the value doesn't conform to the provided regex
      // EncodeURI to account for edge-cases such as a param with value `foo/bar/baz`
      return encodeURIComponent(value.toString());
    })
    .replace('*', '');

  return {
    path: replacedPath,
    unusedParams: params,
  };
}
/**
 * asserts there are no parameterized path parts in the value
 */
export function assertNoPathParams(
  value: string,
  errorMessage?: string | Error,
) {
  const matches = [...value.matchAll(parametricPathPart)];

  if (matches.length) {
    const params = matches.map(([_, group]) => group).join(', ');
    throwValidationError(
      errorMessage ??
        `The value must not have parameterized path parts. Parameters ${params} exist`,
    );
  }
}

/**
 * Builds a relative uri from the pathname and querystring object.
 *
 * @returns a relative uri
 */
export function buildRelativeUrl<
  TQuerystring extends Record<string, any> | undefined | null,
>(pathname: string, query?: TQuerystring): string {
  let url = pathname || '';
  const querystring = buildQuerystring(query);

  if (querystring) {
    url += querystring;
  }
  return url;
}

/**
 * Builds a uri querystring from the provided object.
 *
 * @returns a full uri querystring or empty string
 */
export function buildQuerystring<
  TQuerystring extends Record<string, any> | undefined | null,
>(querystring?: TQuerystring): string {
  if (!querystring) return '';

  // this should probably technically be the opposite of
  // the fastify qs deserialization, but its not quite.
  // there are too many options with avj that can control deserialization

  const keys = Object.keys(querystring)
    .filter((k) => isDefined(querystring[k]))
    .sort((a, b) => a.localeCompare(b));

  const pairs = keys.map((key) => {
    const value = querystring[key];

    // handle arrays in a way that fastify can reconstitute them
    if (Array.isArray(value)) {
      return value.map((x) => `${key}=${encodeURIComponent(x)}`).join('&');
    }

    // working code, but currently we are not requiring this kind of transformation
    // if (typeof value === 'object') {
    //   const processSubKeys = (
    //     data: Record<string, any>,
    //     rootKey: string
    //   ): string => {
    //     const subKeys = Object.keys(data).filter((k) => isDefined(data[k]));
    //     const subPairs = subKeys.map((subKey) => {
    //       const subValue = value[subKey];
    //       if (typeof subValue === 'object') {
    //         return processSubKeys(subValue, `${rootKey}.${subKey}`);
    //       }
    //       return `${rootKey}.${subKey}=${encodeURIComponent(subValue)}`;
    //     });
    //     return subPairs.filter((subPair) => !!subPair).join('&');
    //   };
    //
    //   return processSubKeys(value, key);
    // }

    return `${key}=${encodeURIComponent(value)}`;
  });

  const qs = pairs.filter((pair) => !!pair).join('&');

  return qs ? '?' + qs : qs;
}
export function removeLeadingSlash(input: string) {
  if (!input) return input;

  if (!input.startsWith('/')) {
    return input;
  }

  return input.slice(1);
}

export function removeTrailingSlash(input: string) {
  if (!input) return input;

  if (!input.endsWith('/')) {
    return input;
  }

  return input.slice(0, -1);
}

export function getUrlParam(
  input: string | string[] | undefined,
): string | undefined {
  if (!input) return undefined;

  return Array.isArray(input) ? mustGetFirstElement(input) : input;
}
