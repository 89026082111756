import {
  createContext,
  Dispatch,
  FC,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { SimpleModal, Text, Button } from '@homee/ui-web-presentation';
import { useRouter } from 'next/router';

export enum ChangeDetectionActionType {
  Dirty = 'DIRTY',
  ContinueNavigation = 'CONTINUE_NAVIGATION',
  Reset = 'RESET',
}

export type ChangeDetectionType = {
  dirty: boolean;
  continueNavigation: boolean;
  dispatch: Dispatch<ChangeDetectionAction>;
  showModal?: boolean;
};

export type DirtyChangeDetectionAction = {
  type: ChangeDetectionActionType.Dirty;
  payload: boolean;
};
export type ContinueNavigationChangeDetectionAction = {
  type: ChangeDetectionActionType.ContinueNavigation;
  payload: boolean;
};
export type ResetChangeDetectionAction = {
  type: ChangeDetectionActionType.Reset;
};

export type ChangeDetectionAction =
  | DirtyChangeDetectionAction
  | ContinueNavigationChangeDetectionAction
  | ResetChangeDetectionAction;

export const initialState: ChangeDetectionType = {
  dirty: false,
  continueNavigation: false,
  dispatch: null,
};

export const ChangeDetectionContext =
  createContext<ChangeDetectionType>(initialState);

const reducer = (state, action: ChangeDetectionAction): ChangeDetectionType => {
  switch (action.type) {
    case ChangeDetectionActionType.ContinueNavigation:
      return {
        ...state,
        continueNavigation: action.payload,
      };
    case ChangeDetectionActionType.Dirty:
      return {
        ...state,
        dirty: action.payload,
      };
    case ChangeDetectionActionType.Reset:
      return {
        ...state,
        dirty: false,
        continueNavigation: false,
      };
    default:
      return state;
  }
};

export const routeChangeCancelError = 'Route change cancelled';

const ChangeDetectionProvider: FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const continueHandler = useCallback(async () => {
    setShowModal(false);
    dispatch({
      type: ChangeDetectionActionType.ContinueNavigation,
      payload: true,
    });
    await router.push(url);
    // Because this is a context / provider, state persists all throughout the children components.
    // We want to reset the state when user navigates to a new page.
    dispatch({ type: ChangeDetectionActionType.Reset });
  }, [router, url]);

  useEffect(() => {
    const handleRouteChangeStart = (url, { shallow }) => {
      setUrl(url);
      if (state.dirty && !state.continueNavigation) {
        setShowModal(true);

        router.events.emit(
          'routeChangeError',
          routeChangeCancelError,
          url,
          shallow,
        );

        // We want to throw an error to pause the navigation
        // If we don't, the navigation will continue and the modal will open.
        // If we "throw new Error()" then it blows up.
        // If we "throw 'Some error message here'" then it will bubble up and
        // the Notification.tsx will capture it and toast it, but we don't want a toast.
        throw routeChangeCancelError;
      }
    };

    // However, throwing the above will show in the browser's console as an
    // unhandled promise rejection. We add this hack to prevent the console from polluting.
    const handleRouteError = (event: PromiseRejectionEvent) => {
      if (event.reason === routeChangeCancelError) {
        event.preventDefault();
      }
    };

    window.addEventListener('unhandledrejection', handleRouteError);
    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      window.removeEventListener('unhandledrejection', handleRouteError);
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router, state]);

  return (
    <>
      <ChangeDetectionContext.Provider
        value={{ ...state, dispatch, showModal }}
      >
        <SimpleModal
          className="change-detection-modal"
          onRequestClose={() => setShowModal(false)}
          isOpen={showModal}
          actions={
            <>
              <Button
                className="cancel"
                variant="primary"
                size="small"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                className="continue"
                variant="primary"
                size="small"
                onClick={() => continueHandler()}
              >
                Continue
              </Button>
            </>
          }
        >
          <Text>
            You have unsaved changes. Are you sure you want to leave this page?
          </Text>
        </SimpleModal>
        {children}
      </ChangeDetectionContext.Provider>
      <style jsx>
        {`
          :global(.change-detection-modal) {
            z-index: 1000;
          }

          :global(.change-detection-modal .inner .button-group) {
            justify-content: space-around !important;
          }
        `}
      </style>
    </>
  );
};

export default ChangeDetectionProvider;
