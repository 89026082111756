import type { MinimalUser, AuthUserV2 } from './auth-user.types';

export * from './common.types';

export const CURRENT_AUTH_USER_VERSION = 2;
export const VALIDATION_TTL_MS = 5 * 60 * 1000; // 5 minutes

export { Role, UserStatus } from '@homee/types-api-v2';
export type { MinimalUser } from './auth-user.types';

export type PartialAuthUser = MinimalUser & Partial<AuthUser>;

export type AuthUser = AuthUserV2;
