import { datadogRum } from '@datadog/browser-rum';
import getConfig from './config';

const {
  publicRuntimeConfig: {
    version,
    datadogRum: {
      dataDogService,
      dataDogSite,
      dataDogEnv,
      dataDogApplicationId,
      dataDogClientToken,
      sessionSampleRate,
      sessionReplaySampleRate,
      tracingSampleRate,
      enabled,
    } = {} as any,
  },
} = getConfig();

export function initializeDatadog() {
  if (enabled) {
    datadogRum.init({
      applicationId: dataDogApplicationId,
      clientToken: dataDogClientToken,
      site: dataDogSite,
      service: dataDogService,
      env: dataDogEnv,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: version,
      sessionSampleRate: sessionSampleRate,
      sessionReplaySampleRate: sessionReplaySampleRate,
      traceSampleRate: tracingSampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [(url) => url.includes('homee.com')],
    });

    datadogRum.startSessionReplayRecording();
  }
  return;
}

export function datadogSetContext(
  user: { id: string; email: string; firstName: string; lastName: string },
  selectedCompanyId: string,
  companies: { id: string; name: string }[],
): void {
  if (!enabled) return;

  try {
    if (user?.id) {
      const { id } = user;
      const userData = {
        id,
      };
      datadogRum.setUser(userData);
    }

    if (selectedCompanyId) {
      const company = companies?.find((c) => c.id === selectedCompanyId);
      if (company) {
        const companyData = {
          id: company?.id,
          name: company?.name,
        };
        datadogRum.setGlobalContextProperty('company', companyData);
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error setting datadog context', error);
  }
}
