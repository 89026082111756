// design system docs: https://zeroheight.com/83195d329/p/905f12/b/637964
import React from 'react';
import {
  FONT_SIZE,
  LINE_HEIGHT,
  COLOR,
  LETTER_SPACING,
  FONT_WEIGHT,
} from './theme';

const sizes = {
  'x-large': {
    fontSize: FONT_SIZE.s18,
    lineHeight: LINE_HEIGHT.s18,
    letterSpacing: LETTER_SPACING.sm,
  },
  large: {
    fontSize: FONT_SIZE.s16,
    lineHeight: LINE_HEIGHT.s16,
    letterSpacing: LETTER_SPACING.sm,
  },
  medium: {
    fontSize: FONT_SIZE.s13,
    lineHeight: LINE_HEIGHT.s13,
    letterSpacing: LETTER_SPACING.xxs,
  },
  small: {
    fontSize: FONT_SIZE.s11,
    lineHeight: LINE_HEIGHT.s11,
    letterSpacing: LETTER_SPACING.sm,
  },
  ['x-small']: {
    fontSize: FONT_SIZE.s10,
    lineHeight: LINE_HEIGHT.s11,
    letterSpacing: LETTER_SPACING.sm,
  },
} as const;

export type TextSize = keyof typeof sizes;

const colors = {
  default: {
    light: COLOR.black,
    dark: COLOR.white,
  },
  gray: {
    light: COLOR.darkGray,
    dark: COLOR.softGray,
  },
  selected: {
    light: COLOR.blue,
    dark: COLOR.blue,
  },
  success: {
    light: COLOR.green,
    dark: COLOR.green,
  },
  error: {
    light: COLOR.red,
    dark: COLOR.red,
  },
  link: {
    light: COLOR.blue,
    dark: COLOR.blue,
  },
  disabled: {
    light: COLOR.lightGray,
    dark: COLOR.softGray,
  },
} as const;

export type TextColor = keyof typeof colors;

export type TextProps = {
  color?: TextColor;
  element?: React.ElementType;
  onDark?: boolean;
  size?: TextSize;
  weight?: keyof typeof FONT_WEIGHT;
  fontStyle?: string;
  lineHeight?: string;
} & Omit<React.HtmlHTMLAttributes<HTMLElement>, 'color'>;

export const Text: React.FC<TextProps> = ({
  children,
  className,
  color = 'default',
  element = 'p',
  onDark = false,
  size = 'large',
  style = {},
  weight = 'regular',
  fontStyle = 'normal',
  lineHeight,
  ...props
}: TextProps) => {
  const sizeValues = sizes[size];
  const textStyle = {
    fontSize: sizeValues.fontSize,
    lineHeight: lineHeight || sizeValues.lineHeight,
    fontWeight: FONT_WEIGHT[weight],
    letterSpacing: sizeValues.letterSpacing,
    color: onDark ? colors[color].dark : colors[color].light,
    textDecoration: color === 'link' ? 'underline' : 'none',
    fontStyle,
  };

  const mergedStyles = { ...textStyle, ...style };

  return React.createElement(
    element,
    {
      className,
      style: mergedStyles,
      ...props,
    },
    children,
  );
};

export default Text;
