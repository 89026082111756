import type { ChildrenProps } from '@homee/ui-web-presentation';
import { asPromise } from '@homee/util-common';
import React, { createContext, Dispatch, useEffect, useReducer } from 'react';

export enum RefetchActionType {
  Changed = 'CHANGED',
  Refetching = 'REFETCHING',
  Refetched = 'REFETCHED',
}

export type ChangedAction = {
  type: RefetchActionType.Changed;
};

type RefetchingAction = {
  type: RefetchActionType.Refetching;
};

type RefetchedAction = {
  type: RefetchActionType.Refetched;
};

type RefetchActions = ChangedAction | RefetchingAction | RefetchedAction;

export type RefetchContextType = {
  dirty: boolean;
  refetching: boolean;
  dispatch: Dispatch<ChangedAction>;
};

export const initialState: RefetchContextType = {
  dirty: false,
  refetching: false,
  dispatch: null,
};

export const RefetchContext = createContext<RefetchContextType>(initialState);

const reducer = (
  state: RefetchContextType,
  action: RefetchActions,
): RefetchContextType => {
  switch (action.type) {
    case RefetchActionType.Changed:
      return {
        ...state,
        dirty: true,
      };
    case RefetchActionType.Refetching:
      return {
        ...state,
        refetching: true,
      };
    case RefetchActionType.Refetched:
      return {
        ...state,
        dirty: false,
        refetching: false,
      };
    default:
      return state;
  }
};

type Props = ChildrenProps<{
  onChange: () => Promise<any> | any;
}>;

export const RefetchProvider: React.FC<Props> = ({ onChange, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.dirty && !state.refetching) {
      dispatch({ type: RefetchActionType.Refetching });
      void asPromise(onChange()).finally(() => {
        dispatch({ type: RefetchActionType.Refetched });
      });
    }
  }, [state, onChange]);

  return (
    <RefetchContext.Provider value={{ ...state, dispatch }}>
      {children}
    </RefetchContext.Provider>
  );
};
