import classnames from 'classnames';
import css from 'styled-jsx/css';
import React from 'react';

import Icon, { Icons } from './Icon';
import Text from './Text';
import ThreeLoadingDots from './ThreeLoadingDots';
import { COLOR, SPACING, FONT_SIZE, LINE_HEIGHT, TRANSITION } from './theme';

export type ButtonSize = 'large' | 'small' | 'x-small';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'positive'
  | 'negative'
  | 'ghost'
  | 'link';

type Color = 'default' | 'gray' | 'selected' | 'success' | 'error' | 'link';

const fontColor: Record<Color, string> = {
  default: COLOR.black,
  gray: COLOR.darkGray,
  selected: COLOR.blue,
  success: COLOR.green,
  error: COLOR.red,
  link: COLOR.blue,
};

const fontColorOnDark: Record<Color, string> = {
  default: COLOR.white,
  gray: COLOR.softGray,
  selected: COLOR.blue,
  success: COLOR.green,
  error: COLOR.red,
  link: COLOR.blue,
};

const { className: glyphClassName, styles: glyphStyles } = css.resolve`
  .icon {
    font-size: ${FONT_SIZE.s16};
    line-height: ${LINE_HEIGHT.s16};
  }
`;

function getColor(variant: ButtonVariant, disabled = false): Color {
  const variantColor: Record<ButtonVariant, Color> = {
    primary: disabled ? 'gray' : 'default',
    secondary: 'selected',
    tertiary: 'gray',
    positive: 'success',
    negative: 'error',
    ghost: 'selected',
    link: 'link',
  };

  return variantColor[variant];
}

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  children: string | React.ReactElement;
  className?: string;
  disabled?: boolean;
  glyph?: Icons | React.ReactElement;
  glyphPlacement?: 'left' | 'right';
  id?: string;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: ButtonSize;
  weight?: 'bold' | 'heading' | 'semi' | 'regular';
  style?: Record<string, string | number>;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariant;
  viewBox?: string;
  iconSize?: number | string;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled = false,
      glyph,
      glyphPlacement,
      id,
      isLoading = false,
      onClick,
      size = 'large',
      weight,
      style,
      type = 'button',
      variant = 'primary',
      viewBox,
      iconSize,
      ...buttonAttrs
    },
    ref,
  ) => {
    const color: Color = getColor(variant, disabled);

    const opacity = disabled ? 0.6 : 1;
    const iconColor: string =
      variant === 'primary' ? fontColorOnDark[color] : fontColor[color];

    const icon = glyph && (
      <>
        {typeof glyph === 'string' && (
          <Icon
            size={iconSize}
            viewBox={viewBox}
            className={`icon icon-${
              glyphPlacement ?? 'left'
            } ${glyphClassName}`}
            icon={glyph}
            style={{
              color: iconColor,
            }}
          />
        )}
        {typeof glyph !== 'string' && (
          <div
            className={`icon icon-${
              glyphPlacement ?? 'left'
            } ${glyphClassName}`}
            style={{
              color: iconColor,
            }}
          >
            {glyph}
          </div>
        )}
      </>
    );

    return (
      <button
        aria-busy={isLoading ? 'true' : undefined}
        aria-live="polite"
        className={classnames('button', className, size, variant, {
          loading: isLoading,
          ['with-icon']: !!icon,
        })}
        disabled={disabled || isLoading}
        id={id}
        onClick={onClick}
        ref={ref}
        style={style}
        type={type}
        {...buttonAttrs}
      >
        {glyphPlacement !== 'right' && icon}
        <Text
          color={color}
          element="span"
          onDark={variant === 'primary'}
          size={size === 'x-small' ? 'medium' : 'large'}
          weight={weight ?? 'semi'}
        >
          {children}
        </Text>
        {isLoading && (
          <div className="loading-overlay">
            <ThreeLoadingDots height={13} />
          </div>
        )}
        {glyphPlacement === 'right' && icon}
        <style jsx>
          {`
            /* Root */
            .button {
              align-items: center;
              border-color: ${COLOR.darkGray};
              border-style: solid;
              border-width: 1px;
              border-radius: 2px;
              display: flex;
              justify-content: center;
              overflow: hidden;
              padding: 0 ${SPACING.sm}px;
              position: relative;
              transition:
                background-color ${TRANSITION.duration}
                  ${TRANSITION.timingFunction},
                border-color ${TRANSITION.duration} ${TRANSITION.timingFunction};
            }

            .button:not(.link) {
              text-transform: capitalize;
            }

            .button:disabled {
              pointer-events: none;
              border-color: ${COLOR.lightGray};
            }

            .button:hover,
            .button:focus {
              background-color: ${COLOR.softGray};
              border-color: ${COLOR.black};
            }

            .button :global(.icon),
            .button :global(span) {
              opacity: ${opacity};
              transition: color ${TRANSITION.duration}
                ${TRANSITION.timingFunction};
            }

            .large:not(.link) {
              min-height: 56px;
              padding-bottom: ${SPACING.sm}px;
              padding-top: ${SPACING.sm}px;
            }

            .small:not(.link) {
              min-height: 40px;
              padding-bottom: ${SPACING.xs}px;
              padding-top: ${SPACING.xs}px;
            }

            .x-small:not(.link) {
              min-height: 32px;
              padding-bottom: ${SPACING.xxs}px;
              padding-top: ${SPACING.xxs}px;
            }

            /* Icon */

            .button.with-icon :global(.icon-left + span) {
              padding-left: 12px;
              padding-right: 0;
            }

            .button.with-icon :global(span) {
              padding-right: 12px;
            }

            /* Loading */
            .loading-overlay {
              align-items: center;
              bottom: 0;
              display: flex;
              height: 100%;
              justify-content: center;
              left: 0;
              position: absolute;
              width: 100%;
            }

            .loading {
              color: ${COLOR.darkGray};
            }

            .loading :global(.icon),
            .loading :global(span) {
              opacity: 0;
            }

            /* Primary */
            .primary {
              background-color: ${COLOR.blue};
              border-color: ${COLOR.blue};
            }

            .primary:hover,
            .primary:focus {
              background-color: ${COLOR.blueHover};
              border-color: ${COLOR.blueHover};
            }

            .primary:not(.loading):disabled {
              background-color: ${COLOR.lightGray};
              border-color: ${COLOR.lightGray};
            }

            .primary:not(.loading):disabled :global(.icon),
            .primary:not(.loading):disabled :global(span) {
              color: ${COLOR.darkGray} !important;
            }

            .primary.loading {
              color: ${COLOR.white};
            }

            /* Ghost & Link Styles */
            .ghost,
            .ghost:active,
            .ghost:focus,
            .ghost:visited,
            .ghost:hover,
            .ghost.loading,
            .ghost:disabled,
            .link:active,
            .link,
            .link:focus,
            .link:visited,
            .link:hover,
            .link.loading,
            .link:disabled {
              background-color: transparent;
              border-color: transparent;
            }

            .ghost:hover :global(.icon),
            .ghost:hover :global(span),
            .link:hover :global(.icon),
            .link:hover :global(span),
            .ghost:focus :global(.icon),
            .ghost:focus :global(span),
            .link:focus :global(.icon),
            .link:focus :global(span) {
              color: ${COLOR.blueHover} !important;
            }

            .ghost:hover :global(span),
            .ghost:focus :global(span) {
              text-decoration: underline !important;
            }

            .link {
              align-items: center;
              display: inline-flex;
              padding: 0;
            }

            /* Other Variants */
            .secondary,
            .tertiary,
            .positive,
            .negative {
              background-color: ${COLOR.white};
            }

            .secondary:hover :global(.icon),
            .secondary:hover :global(span),
            .secondary:focus :global(.icon),
            .secondary:focus :global(span) {
              color: ${COLOR.blueHover} !important;
            }

            .tertiary:hover :global(.icon),
            .tertiary:hover :global(span),
            .tertiary:focus :global(.icon),
            .tertiary:focus :global(span) {
              color: ${COLOR.black} !important;
            }

            .positive:hover :global(.icon),
            .positive:hover :global(span),
            .positive:focus :global(.icon),
            .positive:focus :global(span) {
              color: ${COLOR.greenHover} !important;
            }

            .negative:hover :global(.icon),
            .negative:hover :global(span),
            .negative:focus :global(.icon),
            .negative:focus :global(span) {
              color: ${COLOR.redHover} !important;
            }
          `}
        </style>
        {glyphStyles}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
