// borrowed from https://mui.com/material-ui/react-avatar/
export function stringToColor(input?: string) {
  if (!input) {
    return '#bdbdbd';
  }

  let hash = 0;
  let i;

  for (i = 0; i < input.length; i += 1) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}
