import React from 'react';
import RadioOption, { RadioOptionValue } from './RadioOption';
import {
  BREAKPOINT_VALUE,
  FONT_SIZE,
  LETTER_SPACING,
  LINE_HEIGHT,
  SPACING,
} from './theme';

type Props<T extends string | number | readonly string[] | undefined = string> =
  {
    name: string;
    options?: RadioOptionValue<T>[];
    onSelected: (option: RadioOptionValue<T>) => void;
  };

export function RadioOptions<
  T extends string | number | readonly string[] | undefined = string,
>({ name, options, onSelected }: Props<T>) {
  const onChange = (opt: RadioOptionValue<T>) => {
    onSelected(opt);
  };

  return (
    <ul>
      {options?.map((opt) => (
        <RadioOption<T>
          key={opt.id}
          {...opt}
          onChange={(o) => onChange(o)}
          name={name}
        />
      ))}
      <style jsx>
        {`
          ul {
            list-style-type: none;
            margin: ${SPACING.md}px 0 0 0;
            padding: 0;
            max-height: 380px;
            overflow-y: auto;
            position: relative;
          }

          li {
            width: 95%;
            margin-bottom: ${SPACING.xs}px;
            border-bottom: 1px solid black;
          }

          label {
            display: flex;
            justify-content: left;
            align-items: center;
            cursor: pointer;
            margin-left: 15px;
          }

          label > :global(span) {
            padding-left: ${SPACING.xs}px;
          }

          @media (max-width: ${BREAKPOINT_VALUE.sm}px) {
            label > :global(div[role='radio'] .radio) {
              width: ${FONT_SIZE.s32};
              height: ${FONT_SIZE.s32};
              position: relative;
              left: unset;
              top: unset;
            }

            label > :global(div[role='radio']) {
              height: 40px;
              width: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            label > :global(span) {
              font-size: ${FONT_SIZE.s16} !important;
              line-height: ${LINE_HEIGHT.s16} !important;
              letter-spacing: ${LETTER_SPACING.sm} !important;
            }
          }
        `}
      </style>
    </ul>
  );
}

// RadioOptions.propTypes = {
//   name: PropTypes.string.isRequired,
//   options: PropTypes.arrayOf(
//     PropTypes.exact({
//       id: PropTypes.string,
//       label: PropTypes.string,
//       value: PropTypes.string,
//     })
//   ).isRequired,
//   selected: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
// };

// RadioOptions.defaultProps = {
//   selected: null,
// };

export default RadioOptions;
