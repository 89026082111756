import { Avatar, AvatarSize, COLOR } from '@homee/ui-web-presentation';
import { getInitials } from '@homee/util-common';
import React from 'react';
import { UserStatus, UserAvatarFragment } from '../lib/generated/types';

export type Props = {
  user: UserAvatarFragment;
  backgroundColor?: string;
  cache?: boolean;
  size?: number | AvatarSize;
} & Omit<React.HTMLProps<HTMLElement>, 'size' | 'src'>;

export const UserAvatar: React.FC<Props> = ({
  user,
  backgroundColor,
  cache,
  size,
  ...extraProps
}) => {
  if (!user) {
    return (
      <Avatar
        {...extraProps}
        indicatorColor={COLOR.lightGray}
        size={size}
        backgroundColor={backgroundColor}
      />
    );
  }

  const { status, firstName, lastName, avatarThumbnail: src } = user;
  const initials = getInitials(`${firstName} ${lastName}`);

  let indicatorColor: string | undefined;
  if (status === UserStatus.Deactivated) {
    indicatorColor = COLOR.red;
  } else if (status === UserStatus.Suspended) {
    indicatorColor = COLOR.orangeStatus;
  } else if (status === UserStatus.Pending) {
    indicatorColor = COLOR.lightGray;
  } else {
    indicatorColor = COLOR.green;
  }

  return (
    <Avatar
      {...extraProps}
      indicatorColor={indicatorColor}
      initials={initials}
      size={size}
      src={src}
      backgroundColor={backgroundColor}
    />
  );
};

UserAvatar.defaultProps = {
  backgroundColor: COLOR.darkGray,
  cache: true,
  size: 'medium',
};

export default UserAvatar;
