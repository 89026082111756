export const DefaultPageSize = 10;

export function getDefaultPageSize(id: string, defaultValue?: number): number {
  const paramName = `${id}-limit`;
  return (
    (global.localStorage
      ? Number(localStorage?.getItem(paramName))
      : undefined) ||
    defaultValue ||
    DefaultPageSize
  );
}
