export abstract class ErrorWithCause extends Error {
  constructor(
    message: string,
    readonly cause?: unknown,
  ) {
    super(message);
  }

  toJSON() {
    return {
      message: this.message,
      cause: this.cause,
    };
  }
}
