
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "IEstimateLineItemSelection": [
      "EstimateItemAction",
      "EstimateItemGrade",
      "EstimateItemTitle",
      "EstimateItemType",
      "EstimateLineItemSelection"
    ],
    "IJobLineItem": [
      "MaterialLineItem"
    ],
    "IMedia": [
      "EstimateAttachment",
      "Media"
    ],
    "JobLineItem": [
      "MaterialLineItem"
    ],
    "Node": [
      "Answer",
      "Answers",
      "Asset",
      "Banking",
      "BankingInfo",
      "Comment",
      "CommentMention",
      "CommentThread",
      "Company",
      "CompanyJobFilters",
      "CompanyService",
      "CompanyServiceArea",
      "CompanyServiceId",
      "ContactInfo",
      "Estimate",
      "EstimateAttachment",
      "EstimateAttachmentPackage",
      "EstimateAttachments",
      "EstimateItemAction",
      "EstimateItemActions",
      "EstimateItemGrade",
      "EstimateItemGrades",
      "EstimateItemTitle",
      "EstimateItemTitles",
      "EstimateItemType",
      "EstimateItemTypes",
      "EstimateLineItem",
      "EstimateLineItemSelection",
      "EstimateLineItems",
      "EstimateNote",
      "EstimateNotes",
      "EstimateRevision",
      "EstimateRevisions",
      "EstimateRoom",
      "EstimateRooms",
      "Job",
      "JobAttachment",
      "JobLineItems",
      "Jobs",
      "MaterialLineItem",
      "Media",
      "Notification",
      "Notifications",
      "Service",
      "Stats",
      "Submission",
      "User",
      "UserRole",
      "Users"
    ]
  }
};
      export default result;
    