import getNextConfig from 'next/config';

/**
 * This shouldn't need to exist, but its a workaround for an issue
 * in some gitlab pipelines that when building the
 * publicRuntimeConfig/serverRuntimeConfig are undefined
 */
export default function getConfig() {
  let config = getNextConfig();

  if (process?.env?.CI === 'true') {
    config = config || {};
    if (!config.serverRuntimeConfig) {
      config.serverRuntimeConfig = {};
    }
    if (!config.publicRuntimeConfig) {
      config.publicRuntimeConfig = {};
    }
  }

  return config;
}
