import { serializeError, ErrorObject } from 'serialize-error';
import type { StatusCodes } from 'http-status-codes';
import type { LogLevel } from '../log-level';

export type HomeeErrorOptions = {
  /**
   * Additional data associated with the error
   */
  data?: any;
  /**
   * Original error, if any
   */
  innerError?: unknown;
  /**
   * LogLevel to use when logging this error to system logger
   */
  logLevel?: LogLevel;
  /**
   * HTTP status code applicable to this error
   */
  statusCode?: StatusCodes;
};

export class HomeeError extends Error {
  /**
   * LogLevel to use when logging this error to system logger
   */
  logLevel: LogLevel;
  /**
   * HTTP status code applicable to this error
   */
  statusCode?: StatusCodes;
  innerError?: ErrorObject;
  data?: object;
  constructor(message: string, options?: HomeeErrorOptions | unknown) {
    const parsedOptions: HomeeErrorOptions = buildHomeeErrorOptions(options);

    super(message);

    this.name = 'HomeeError';

    this.logLevel = parsedOptions.logLevel ?? 'error';
    this.statusCode = parsedOptions.statusCode;

    this.data = parsedOptions.data;

    if (parsedOptions.innerError) {
      this.innerError = serializeError(parsedOptions.innerError, {
        maxDepth: 4,
      });
    }
  }

  toString(): string {
    return JSON.stringify(serializeError(this));
  }
}

/**
 * Allow passing basically anything as options and handle sensibly
 */
export function buildHomeeErrorOptions(
  ...arr: Array<HomeeErrorOptions | unknown>
): HomeeErrorOptions {
  return arr.reduce((prev, options) => {
    const previous = prev as HomeeErrorOptions;

    if (!options) {
      return previous;
    }

    if (options instanceof Error) {
      return { ...previous, innerError: options };
    }

    const o = options as HomeeErrorOptions;

    if (o.data || o.innerError || o.statusCode || o.logLevel) {
      return { ...previous, ...o };
    }

    const out: HomeeErrorOptions = {
      ...previous,
      data: { ...previous.data, ...(options as object) },
    };

    return out;
  }, {}) as HomeeErrorOptions;
}
