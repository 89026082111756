const uninitialized = Symbol('once');
export function once<T>(fn: () => T): () => T {
  let result: T | typeof uninitialized = uninitialized;
  return function () {
    if (result === uninitialized) {
      result = fn();
    }
    return result;
  };
}
