import type { ApolloError, QueryResult } from '@apollo/client';
import type { ChildrenProps } from '@homee/ui-web-presentation';
import type { ArrayElement } from '@homee/util-common';
import React, { createContext, Dispatch, useEffect, useReducer } from 'react';
import useTypedSession from '../hooks/useTypedSession';
import { datadogSetContext } from '../lib/datadog';
import {
  AppContextQuery,
  AppContextQueryVariables,
  useAppContextQuery,
} from '../lib/generated/types';

export type AppUserFragment = AppContextQuery['user'];
export type AppContextLoading = {
  loading: boolean;
  error: ApolloError;
};
export type AppContextPartial<T extends keyof AppContextType> =
  AppContextLoading & {
    [P in T]: AppContextType[T];
  };
export type AppContextCompaniesFragment = AppUserFragment['companies'];
export type AppContextCompanyFragment =
  ArrayElement<AppContextCompaniesFragment>;
export type AppContextState = AppContextLoading & {
  selectedCompanyId?: string;
  user?: AppUserFragment;
  companies?: AppContextCompaniesFragment;
};
export type AppContextType = AppContextState & {
  dispatch: Dispatch<AppActions>;
};
export function getInitialState(): AppContextType {
  const out: AppContextType = {
    loading: true,
    error: null,
    dispatch: null,
    selectedCompanyId: null,
  };
  return out;
}
export const AppContext = createContext<AppContextType>({
  loading: true,
  error: null,
  dispatch: null,
});
export enum AppActionType {
  SelectCompany = 'SELECT_COMPANY',
}
export type SelectCompanyAction = {
  type: AppActionType.SelectCompany;
  companyId: string;
};
export type AppActions = SelectCompanyAction;
type QueryChangedAction = {
  type: 'QUERY_CHANGED';
  results: QueryResult<AppContextQuery, AppContextQueryVariables>;
};
type ContextActions = QueryChangedAction;
const reducer = (
  state: AppContextState,
  action: AppActions | ContextActions,
): AppContextState => {
  switch (action.type) {
    case AppActionType.SelectCompany:
      writeLocalSelectedCompanyId(action.companyId);

      return {
        ...state,
        selectedCompanyId: action.companyId,
      };
    case 'QUERY_CHANGED':
      return handleResults(state, action.results);
    default:
      return state;
  }
};
function handleResults(
  state: AppContextState,
  results: QueryResult<AppContextQuery, AppContextQueryVariables>,
): AppContextState {
  const { data, loading, error } = results;
  const user = data?.user;
  const companies = user?.companies;

  const selectedCompanyId = getSelectedCompanyId(companies);

  datadogSetContext(user, selectedCompanyId, companies);

  const out: AppContextState = {
    ...state,
    user,
    companies,
    loading,
    error,
    selectedCompanyId,
  };

  return out;
}

export const AppContextProvider: React.FC<ChildrenProps> = ({ children }) => {
  const { data } = useTypedSession();
  const results = useAppContextQuery({ skip: !data?.user?.gt });

  const [state, dispatch] = useReducer(reducer, getInitialState());
  useEffect(() => {
    dispatch({ type: 'QUERY_CHANGED', results });
  }, [results]);
  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

function getSelectedCompanyId(
  companies: AppContextCompaniesFragment,
): string | undefined {
  if (!companies?.length) return undefined;

  let selectedCompanyId: string | undefined = undefined;

  try {
    let localCompanyId = readLocalSelectedCompanyId();

    if (localCompanyId) {
      //TODO: remove this at some point
      // for backwards compatibility with old stuff
      if (localCompanyId === 'undefined') {
        localCompanyId = undefined;
      }
      if (localCompanyId?.startsWith('"')) {
        localCompanyId = localCompanyId.slice(1, -1);
      }

      selectedCompanyId = companies?.find((c) => c.id === localCompanyId)?.id;
    }

    if (!selectedCompanyId) {
      selectedCompanyId = companies[0].id;
    }

    if (selectedCompanyId && selectedCompanyId !== localCompanyId) {
      writeLocalSelectedCompanyId(selectedCompanyId);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Error parsing localStorage selected CompanyId', e);
  }

  return selectedCompanyId;
}

function readLocalSelectedCompanyId(): string | undefined {
  if (!global.localStorage) return undefined;

  const localCompanyIdValue = localStorage.getItem('selectedCompanyId');

  return localCompanyIdValue;
}
function writeLocalSelectedCompanyId(companyId: string | undefined): void {
  if (!global.localStorage) return;

  if (!companyId) {
    localStorage.removeItem('selectedCompanyId');
  } else {
    localStorage.setItem('selectedCompanyId', companyId);
  }
}
