import React, { PropsWithChildren } from 'react';

import { SPACING, COLOR } from '@homee/ui-web-presentation';

type Props = PropsWithChildren<{
  isExpanded?: boolean;
}>;

const ChildNavList: React.FC<Props> = ({ children, isExpanded }) => (
  <ul className={isExpanded ? 'expanded' : undefined}>
    {children}
    <style jsx>
      {`
        ul {
          list-style: none;
          padding-left: 40px;
          margin: 0;
          display: none;
          overflow: hidden;
        }

        ul.expanded {
          display: block;
        }

        ul :global(li) {
          padding-left: ${SPACING.sm}px;
          margin: ${SPACING.sm}px 0;
        }

        ul :global(li:last-child) {
          margin-bottom: ${SPACING.md}px;
        }
      `}
    </style>
  </ul>
);

ChildNavList.defaultProps = {
  isExpanded: false,
};

export default ChildNavList;
