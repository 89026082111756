import { ClassyPlaceholder } from './classy';

export interface SimpleLogFn {
  (message?: any, ...optionalParams: any[]): void;
}

export class ILogger extends ClassyPlaceholder<{
  info: SimpleLogFn;
  warn: SimpleLogFn;
  error: SimpleLogFn;
  trace: SimpleLogFn;
  debug: SimpleLogFn;
}> {}

export const ConsoleLogger: ILogger = {
  // eslint-disable-next-line no-console
  info: console.info,
  // eslint-disable-next-line no-console
  warn: console.warn,
  // eslint-disable-next-line no-console
  error: console.error,
  // eslint-disable-next-line no-console
  trace: console.trace,
  // eslint-disable-next-line no-console
  debug: console.debug,
};
