import React, { useEffect, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { useIntercom } from 'react-use-intercom';

import Head from 'next/head';
import Header from './Header';
import PageTitle from '../PageElements/PageTitle';
import MainNav from './MainNav';

import {
  COLOR,
  BREAKPOINT_VALUE,
  MOBILE_HEADER_HEIGHT,
  SPACING,
} from '@homee/ui-web-presentation';

type Props = PropsWithChildren<{
  title?: string | React.ReactNode;
  metaTitle?: string;
  scroll?: boolean;
}>;

const MainLayout: React.FC<Props> = ({
  children,
  title,
  metaTitle,
  scroll = false,
}) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, [boot]);

  return (
    <div
      className={classnames('root', {
        openNav: isMobileNavOpen,
      })}
    >
      {(metaTitle || title) && (
        <Head>
          <title>{metaTitle || title} | HOMEE</title>
        </Head>
      )}
      <MainNav setIsMobileNavOpen={setIsMobileNavOpen} />
      <div className="main">
        <Header setIsMobileNavOpen={setIsMobileNavOpen} />
        <div
          className={classnames('page', {
            scroll,
          })}
        >
          {title && <PageTitle title={title} />}
          <div className="content">{children}</div>
        </div>
        <footer> </footer>
      </div>

      <style jsx>
        {`
          .root {
            background-color: ${COLOR.offWhite};
            display: flex;
            flex-direction: row;
          }

          .root .main {
            flex: 1;
          }

          .root .page.scroll {
            overflow: auto;
          }

          .root .page .content {
            flex: 1;
            //overflow: scroll;
            display: flex;
            flex-direction: column;
            padding: 0;
          }

          .root footer {
            height: ${SPACING.lg}px;
          }

          .root .page :global(.content) {
            height: 100%;
          }

          .root .page :global(header + .content) {
            height: calc(100% - 77px); // page title
          }

          @media (max-width: ${BREAKPOINT_VALUE.sm - 0.02}px) {
            .root .main {
              max-width: unset;
              width: 100%;
              height: 100%;
            }

            .root .page {
              height: 100%;
              overflow-y: auto;
              display: block;
              padding-top: ${MOBILE_HEADER_HEIGHT}px; // mobile header height
            }

            .root :global(.global-nav) {
              display: none;
            }

            .root.openNav :global(.global-nav) {
              display: block;
            }
          }
        `}
      </style>
    </div>
  );
};

export default MainLayout;
