import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';

import FilterButton from './FilterButton';
import ButtonGroup from '../ButtonGroup';
import { SPACING, SHADOW, COLOR, BREAKPOINT_VALUE, FONT_SIZE } from '../theme';
import Button from '../Button';
import Icon from '../Icon';
import Heading from '../Heading';
import LoadingOverlay from '../LoadingOverlay';

type Props = PropsWithChildren<{
  id: string;
  className?: string;
  label: string;
  title: string;
  selectedOptionCount?: number;
  selectedMark?: boolean;
  isActive: boolean;
  loading?: boolean;
  onApply?: () => void;
  onClear?: () => void;
  position?: 'left' | 'right';
  isOpen?: boolean;
  onOpenChange: any;
  onReset?: () => void;
  onApplyButtonName?: string;
  onClearButtonName?: string;
}>;

export const Filter: React.FC<Props> = ({
  children,
  id,
  className,
  label,
  title,
  selectedOptionCount,
  selectedMark,
  isActive,
  loading = false,
  onApply,
  onClear,
  position = 'left',
  isOpen = false,
  onOpenChange,
  onReset,
  onApplyButtonName = 'Apply',
  onClearButtonName = 'Clear',
}) => {
  const filterRef = React.useRef<HTMLInputElement>(null);
  const toggleFilter = () => {
    // clear out any selected options that weren't applied
    onReset && onReset();
    onOpenChange((prev: boolean) => !prev);
  };

  const hasActions = !!onApply && !!onClear;
  const [isMobileView, setIsMobileView] = React.useState(
    globalThis.innerWidth <= BREAKPOINT_VALUE.sm,
  );

  const handleOutsideClick = React.useCallback(
    (event: MouseEvent) => {
      if (isOpen && !filterRef?.current?.contains(event.target as Node)) {
        onOpenChange(false);
      }
    },
    [isOpen, onOpenChange],
  );

  React.useEffect(() => {
    function updateisMobileView() {
      setIsMobileView(globalThis.innerWidth <= BREAKPOINT_VALUE.sm);
    }

    globalThis.addEventListener('mousedown', handleOutsideClick, false);
    globalThis.addEventListener('resize', updateisMobileView);

    return () => {
      globalThis.removeEventListener('mousedown', handleOutsideClick, false);
      globalThis.removeEventListener('resize', updateisMobileView);
    };
  }, [handleOutsideClick]);

  return (
    <div
      className={classnames('filter-wrapper', className, { open: isOpen })}
      ref={filterRef}
    >
      <div className="mobile filter-header">
        <button type="button" onClick={() => onOpenChange(false)}>
          <Icon icon="arrowBack" />
        </button>
        <Heading className="filter-label" element="h2" variant="h4">
          {title}
        </Heading>
      </div>
      <FilterButton
        label={label}
        selectedMark={selectedMark}
        selectedOptionCount={selectedOptionCount}
        isActive={isActive || isOpen}
        isOpen={isOpen}
        onClick={toggleFilter}
        id={`filter-${id}`}
        hasOptions={!!children}
      />
      {children && (
        <div className={classnames('dropdown-wrapper', position)}>
          <LoadingOverlay loading={loading}>
            {children}
            {hasActions && (
              <ButtonGroup marginTop={0} marginBottom={0} stackMobile={false}>
                <Button
                  size={isMobileView ? 'small' : 'x-small'}
                  variant="secondary"
                  onClick={() => {
                    onClear();
                  }}
                >
                  {onClearButtonName}
                </Button>
                <Button
                  size={isMobileView ? 'small' : 'x-small'}
                  onClick={() => {
                    onApply();
                    onOpenChange(false);
                  }}
                >
                  {onApplyButtonName}
                </Button>
              </ButtonGroup>
            )}
          </LoadingOverlay>
        </div>
      )}
      <style jsx>
        {`
          .filter-wrapper {
            display: inline-block;
            position: relative;
            margin: ${SPACING.xxs}px ${SPACING.xs}px ${SPACING.xxs}px 0;
          }

          .dropdown-wrapper {
            display: none;
            position: absolute;
            min-width: 250px;
            max-height: calc(
              100vh - 220px
            ); // prevent dropdown from overflowing off screen
            margin-top: ${SPACING.xxs}px;
            background: ${COLOR.white};
            box-shadow: ${SHADOW.dp6};
            z-index: 1;
          }

          .dropdown-wrapper.right {
            right: 0;
          }

          .open .dropdown-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .dropdown-wrapper :global(.button-group) {
            padding: ${SPACING.sm}px;
            margin: 0;
          }

          /* Mobile Filter Header */
          .filter-header {
            display: none;
            position: relative;
            text-align: center;
            padding: ${SPACING.xs}px 0;
            margin: 0 ${SPACING.sm}px;
            border-bottom: 1px solid ${COLOR.lightGray};
          }

          .filter-header button {
            position: absolute;
            left: 0;
            border: none;
            background: none;
            color: ${COLOR.blue};
            height: 36px;
          }

          .filter-header button:hover {
            color: ${COLOR.blueHover};
          }

          .filter-header button :global(i) {
            font-size: ${FONT_SIZE.s18} !important;
          }
        `}
      </style>
    </div>
  );
};

export default Filter;
