import type { Role, AuthUser } from '@homee/pro-portal/types-api';
import { PageRole, PageRoles } from './types/next';

export function isAllowed(
  user?: AuthUser,
  allowedRoles?: PageRole[],
  companyId?: string,
): boolean {
  const roleSet = new Set(allowedRoles || []);

  // we have to assume no roles === public to account for
  // common next pages like ErrorPage
  if (roleSet.size === 0 || roleSet.has(PageRoles.Public)) {
    return true;
  }

  if (user && roleSet.has(PageRoles.Any)) {
    return true;
  }

  const roles = user?.r;

  return roles?.some((role) => roleSet.has(role as unknown as Role)) ?? false;
}
