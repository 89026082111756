import { combinePaths } from './url';

export const constructSymbilityDeepLink = (
  baseUri: string,
  claimNumber: string,
) => {
  if (claimNumber.includes('-')) {
    claimNumber = claimNumber.substring(0, claimNumber.lastIndexOf('-'));
  }

  return combinePaths(baseUri, `/ux/site/#/claims;quickSearch=${claimNumber}`);
};
