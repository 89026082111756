export const BREAKPOINT_VALUE = Object.freeze({
  xs: 321,
  sm: 577,
  md: 769,
  lg: 993,
  xl: 1201,
  xxl: 1441,
  xxxl: 1920,
});

export const COLOR = {
  brandBlue: '#3C97D3',
  brandNavy: '#0F385C',
  brandRed: '#E74C3C',
  brandYellow: '#ECC11C',
  brandGreen: '#49A078',
  black: '#0D131A',
  darkGray: '#636466',
  lightGray: '#DCDDDE',
  mediumGray: '#C4C4C4',
  softGray: '#F2F2F3',
  offWhite: '#FAFAFA',
  white: '#FFFFFF',
  blue: '#0059A0',
  blueLight: '#3D97D2',
  blueHover: '#003866',
  blueStatus: '#008CFF',
  green: '#4F7F48',
  greenHover: '#2E6127',
  greenStatus: '#70C246',
  red: '#9A3433',
  redStatus: '#DB4046',
  redHover: '#662222',
  orange: '#E06C00',
  orangeStatus: '#F58616',
  orangePending: '#E44E3D',
  yellowStatus: '#FFD11E',
  overlay: 'rgb(13, 19, 26, .8)',
  // old colors
  neutral0: '#FFFFFF',
  neutral5: '#F6F7F8',
  neutral10: '#E5EBF0',
  neutral20: '#C3C8CD',
  neutral30: '#B2BBC2',
  neutral40: '#93A2B0',
  neutral50: '#6D8190',
  neutral60: '#41596D',
  neutral70: '#2A3F50',
  neutral80: '#1F2F3C',
  neutral90: '#17232D',
  neutral100: '#000000',

  // colors used in stories, but maybe no where else
  green60: '#1F8600',
  red40: '#ED3036',
} as const;

export const Z_INDEX = Object.freeze({
  // bootstrap navbar           1000
  // bootstrap dropdown         1000
  // bootstrap fixed            1030
  // bootstrap modal-background 1040
  // bootstrap modal            1050
  // bootstrap popover          1060
  // bootstrap tooltip          1070

  dropdownMenu: 2000,
  calendar: 1,
  actionList: 2,
  mainHeader: 5,
  carousel: 2001,
  mobileMenu: 2002,
  toast: 2003,
});

export const SPACING = {
  xxs: 4,
  xs: 8,
  sm: 16,
  md: 24,
  lg: 48,
  xl: 96,
} as const;

const baseFontSize = 16;
const rem = (pixels: number): string => `${pixels / baseFontSize}rem`;

export const FONT_SIZE = {
  s8: rem(8),
  s10: rem(10),
  s11: rem(11),
  s13: rem(13),
  s15: rem(15),
  s16: rem(16),
  s18: rem(18),
  s24: rem(24),
  s32: rem(32),
  s40: rem(40),
  s64: rem(64),
} as const;

export const LINE_HEIGHT = {
  s8: rem(12),
  s10: rem(14),
  s11: rem(16),
  s13: rem(20),
  s16: rem(24),
  s18: rem(27),
  s24: rem(36),
  s32: rem(48),
  s40: rem(48),
  s64: rem(96),
} as const;

export const FONT_WEIGHT = {
  heading: 400,
  bold: 700,
  semi: 600,
  regular: 400,
} as const;

export const LETTER_SPACING = {
  xxs: rem(0.32),
  xs: rem(0.38),
  sm: rem(0.5),
  md: rem(1.11),
  lg: rem(1.5),
  xl: rem(1.6),
} as const;

export const TRANSITION = {
  duration: '150ms',
  timingFunction: 'linear',
} as const;

export const SHADOW = Object.freeze({
  dp2: '0 3px 1px -2px rgba(0,0,0,0.20), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12)',
  dp6: '0 3px 5px -1px rgba(0,0,0,0.20), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12)',
  dp12: '0 7px 8px -4px rgba(0,0,0,0.20), 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12)',
  dp24: '0 11px 15px -7px rgba(0,0,0,0.20), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12)',
});

export const MOBILE_HEADER_HEIGHT = 64;

export const MOBILE_HEADER_HEIGHT_INTERNAL = 120;
