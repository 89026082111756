export enum Role {
  Consumer = 1,
  Provider = 2,
  Internal = 3,
  ClaimsInternal = 4,
  ClaimsAdjuster = 5,
  ProTech = 6,
  ProDispatcher = 7,
  ProAdmin = 8,
}

export enum UserStatus {
  Deactivated = -1,
  Active = 1,
  Pending = 2,
  Suspended = 3,
}

export enum RegistrationStatus {
  Invited = 1,
  Pending = 2,
  Registered = 3,
}

export enum ApplicationType {
  Consumer = 1,
  Provider = 2,
}

export enum LegacyServiceType {
  Electrical = 1,
  HVAC = 2,
  Plumbing = 3,
  Handyman = 4,
  LawnCare = 5,
  PestControl = 6,
  PoolMaintenance = 7,
  Cleaning = 8,
  Fencing = 9,
  Flooring = 10,
  Hauling = 11,
  HomeInspection = 12,
  Locksmith = 13,
  Painting = 14,
  PowerWashing = 15,
  Restoration = 16,
  Roofing = 17,
  SnowRemoval = 18,
  SmartHome = 19,
  Assembly = 20,
  Appliance = 21,
  Exterior = 22,
  SmartHomeThermostat = 23,
  SmartHomeDoor = 24,
  SmartHomeSecurity = 25,
  SmartHomeEvCharger = 26,
  SmartHomeSensor = 27,
  SmartHomeLight = 28,
  SmartHomeOther = 29,
  SmartHomeAssistant = 30,
  GarageDoor = 31,
  Dispenser = 32,
  AboveGround = 33,
  Animals = 34,
  BasicElectrical = 35,
  BasicPlumbing = 36,
  BoilersAndRadiator = 37,
  Carpentry = 38,
  Carpet = 39,
  CentralAir = 40,
  CentralAppliances = 41,
  CentralHeatingSystem = 42,
  CentralVacuum = 43,
  FourPoint = 44,
  Door = 45,
  Drains = 46,
  Driveway = 47,
  Drywall = 48,
  DuctsAndVents = 49,
  ElectricWallAndRadiantHeatSystems = 50,
  Environmental = 51,
  ExteriorPaint = 52,
  Facade = 53,
  FaucetsAndFixtures = 54,
  Freezer = 55,
  FullHome = 56,
  Generator = 57,
  Geothermal = 58,
  Gutters = 59,
  Hardwood = 60,
  Home = 61,
  HotTubs = 62,
  IceMaker = 63,
  InGround = 64,
  Insects = 65,
  Insulation = 66,
  InteriorPaint = 67,
  LaminateVinyl = 68,
  LandscapeLawnCare = 69,
  LightingAndFans = 70,
  MoveInOutReport = 71,
  Mulching = 72,
  Opener = 73,
  OutletsAndSwitches = 74,
  ParkingLot = 75,
  Septic = 76,
  SprinklerSystem = 77,
  Stone = 78,
  Storm = 79,
  Tile = 80,
  TreeTrimming = 81,
  Warranty = 82,
  Water = 83,
  WaterConditioning = 84,
  WaterSoftenersPurificationSystem = 85,
  WiringAndPanel = 86,
  Tarping = 88,
  EmergencyBoardUp = 89,
  RestorationFire = 90,
  RestorationWaterDamage = 91,
  RemediationAsbestos = 92,
  RemediationMold = 93,
  RemediationLead = 94,
  ProductInstallation = 100,
  ApplianceDishwasher = 101,
  ApplianceMicrowave = 102,
  ApplianceGarbageDisposal = 103,
  ApplianceTrashCompactor = 104,
  ApplianceRefrigerator = 105,
  ApplianceDryer = 106,
  ApplianceWasher = 107,
  ApplianceOvenStoveRange = 108,
  AppliancePump = 109,
  ApplianceWaterHeater = 110,
  ApplianceBasicHVAC = 111,
  ApplianceWaterCooler = 112,
  DesjTree = 113,
  DesjPainting = 114,
  DesjLocksmith = 115,
  DesjAppliance = 116,
  DesjFloor = 117,
  DesjGutter = 118,
  DesjDrywall = 119,
  DesjCeramic = 120,
  PGResidential = 200,
  PGCommercial = 201,
  PGGeneralContractor = 202,
  PGRemediation = 203,
  PGBoardUpTarp = 204,
  PGCabinetry = 205,
  PGCountertops = 206,
  PGLeakLineDetection = 207,
  PGMasonry = 208,
  PGPoolEnclosures = 209,
  PGTreeRemoval = 210,
  PGSiding = 211,
  ContentsPackout = 212,
}

export enum ConsumerRequestStatus {
  Scheduled = 65,
  Pending = 70,
  PendingApproval = 80,
  PendingTenant = 90,
  PendingPm = 100,
  Timeout = 101,
  AcceptedPr = 110,
  RejectedPr = 120,
  Cancelled = 130,
  DeclinedPm = 126,
  ManualAssign = 140,
}

export enum Brands {
  Ferguson = 1,
  Ecobee = 2,
  Honeywell = 3,
  Lux = 4,
  Nest = 5,
  SensiEmerson = 6,
  August = 7,
  Ring = 8,
  Schlage = 9,
  Kwikset = 10,
  Samsung = 11,
  ChargePoint = 12,
  JuiceBox = 13,
  Siemens = 14,
  KeenHome = 15,
  Streamlabs = 16,
  Moen = 17,
  Kidde = 18,
  Leviton = 19,
  LutronCasseta = 20,
  WemoBelkin = 21,
  Google = 22,
  Hive = 23,
  HomeDepot = 24,
  GeorgiaPower = 25,
  ArcadiaPower = 26,
  GeorgiaPacific = 27,
  FloTechnologies = 28,
  Aquanta = 29,
  AmazonAlexa = 30,
  Nextera = 31,
  AOSmith = 32,
  Amana = 33,
  AmericanStandard = 34,
  Bosch = 35,
  BradfordWhite = 36,
  Carrier = 37,
  Electrolux = 38,
  Frigidaire = 39,
  GE = 40,
  GEProfile = 41,
  Gladiator = 42,
  Goodman = 43,
  Haier = 44,
  Hotpoint = 45,
  Insinkerator = 46,
  Kenmore = 47,
  KitchenAid = 48,
  LG = 49,
  Lennox = 50,
  Liberty = 51,
  Maytag = 52,
  Navien = 53,
  Panasonic = 54,
  Reliance = 55,
  Rheem = 56,
  Rinnai = 57,
  Ruud = 58,
  Sharp = 59,
  Superior = 60,
  Takagi = 61,
  Trane = 62,
  WasteKing = 63,
  Wayne = 64,
  WhirlPool = 65,
  York = 66,
  Zoeller = 67,
  FloWaterInstallation = 68,
  FloWaterMaintenance = 69,
  FloWaterFilterChange = 70,
  Palmetto = 71,
  Flowie = 72,
  Floodie = 73,
  Sumpie = 74,
  Shuttie = 75,
  Farmers = 76,
}

export enum RevisionStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Suggested = 'SUGGESTED',
}

export enum TimeUnitInternalName {
  Hours = 'HOURS',
  Minutes = 'MINUTES',
}

export enum JobEstimateStatus {
  ProviderStarted = 'PROVIDER_STARTED',
  SentForInternalReview = 'SENT_FOR_INTERNAL_REVIEW',
  SentForExternalReview = 'SENT_FOR_EXTERNAL_REVIEW',
  DeclinedInternal = 'DECLINED_INTERNAL',
  DeclinedConsumer = 'DECLINED_CONSUMER',
  DeclinedExternal = 'DECLINED_EXTERNAL',
  SentToConsumer = 'SENT_TO_CONSUMER',
  AcceptedConsumer = 'ACCEPTED_CONSUMER',
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED',
  InternalModifiedSentToProForReview = 'INTERNAL_MODIFIED_SENT_TO_PRO_FOR_REVIEW',
  InternalModifying = 'INTERNAL_MODIFYING',
}

export enum JobEstimateReviewStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  AwaitingApproval = 'AWAITING_APPROVAL',
}

export enum JobEstimateReviewType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
}

export enum JobStatus {
  Unknown = 0,
  ConsumerServiceCancelled = 20,
  ConsumerServiceCancelledTimeout = 21, // Not used, kept for legacy job statuses
  ConsumerServiceCancelledOther = 22, // Not used, kept for legacy job statuses
  ProviderServiceRejected = 30,
  ProviderScheduleRequested = 50, // Not used, kept for legacy job statuses
  ConsumerScheduleAccepted = 70,
  PmInhouseQueued = 74,
  ProviderQueued = 75,
  ProviderEnroute = 80,
  ProviderOnsite = 90,
  ProviderTimerRequested = 100,
  ConsumerTimerRejected = 110,
  ConsumerTimerAccepted = 120,
  ProviderPaused = 130,
  ProviderResumeRequested = 140,
  ConsumerResumeRejected = 150,
  ConsumerResumeAccepted = 160,
  ProviderSuspended = 169,
  ProviderSuspendRequested = 170,
  ConsumerSuspendRejected = 180,
  ConsumerSuspendAccepted = 190,
  ProviderUnsuspendRequested = 200,
  ProviderUnsuspendCancelled = 201,
  ConsumerUnsuspendRejected = 210,
  ConsumerUnsuspendAccepted = 220,
  ProviderScheduleRejected = 240,
  ProviderScheduleAccepted = 250,
  ProviderCompleted = 259,
  ConsumerCompleteAccepted = 280, // Not used, kept for legacy job statuses
  ConsumerEndRequested = 290,
  ProviderEndRejected = 300,
  ProviderEndAccepted = 310,
  ProviderFinalizeRequested = 330,
  ConsumerFinalizeRejected = 340,
  ConsumerFinalizeAccepted = 350,
}

export enum JobChargeType {
  Dispatch = 1,
  Final = 2,
  Total = 3,
  Tip = 4,
}

export enum JobImageType {
  Pre = 1,
  Post = 2,
  InProcess = 3,
}

export enum UserServiceStatus {
  Inactive = -1,
  Active = 1,
}

export enum CouponType {
  SingleUse = 1,
  MultiUse = 2,
}

export enum CouponCategory {
  Promo = 1,
  Referral = 2,
  FixedBid = 3,
  Warranty = 4,
  CustomerSatisfaction = 5,
  PriceAdjustment = 6,
}

export enum LineItemUnitType {
  Each = 'each',
  Hours = 'hours',
  Fixed = 'fixed',
}

export enum LineItemType {
  Dispatch = 'dispatch',
  Fee = 'fee',
  FlatRate = 'flat_rate_total',
  Insurance = 'insurance',
  Labor = 'labor',
  Material = 'material',
  SurgePrice = 'surge_price',
  Tax = 'tax',
  Tip = 'tip',
  Discount = 'discount',
}

export enum LineItemStatus {
  Active = 'Active',
  RolledBack = 'Rolled Back',
}

export enum LineItemPayer {
  Organization = 'organization',
  Consumer = 'consumer',
}

export enum ZipGeoType {
  Military = 'M',
  PoBox = 'P',
  Standard = '',
  Unique = 'U',
}

export enum MarketStatus {
  Active = 20,
  Pending = 10,
  Inactive = 0,
}

export enum OrganizationInvoiceTerm {
  Zero = 0,
  Two = 2,
  Thirty = 30,
}

export enum OrganizationRole {
  Admin = 1,
  Member = 2,
}

export enum OrganizationStatus {
  DELETED = -1,
  ACTIVE = 1,
  // NOTE: any int other than -1 also means an organization is active (i.e 0)
}

export enum WebhookType {
  JobRequestedEvent = 'JobRequestedEvent',
  JobTransitionedEvent = 'JobTransitionedEvent',
  JobUpdatedEvent = 'JobUpdatedEvent',
  JobCompletedEvent = 'JobCompletedEvent',
  EstimateCreatedEvent = 'EstimateCreatedEvent',
  EstimateTransitionedEvent = 'EstimateTransitionedEvent',
  ProviderEnrouteEvent = 'ProviderEnrouteEvent',
  ProviderOnsiteEvent = 'ProviderOnsiteEvent',
  TimerStartedEvent = 'TimerStartedEvent',
  TimerStoppedEvent = 'TimerStoppedEvent',
}

export enum PartnerInternalName {
  Homesage = 'HOMESAGE',
  NextEraHome = 'NEXTERA',
}

export enum ProviderRequestStatus {
  Pending = 10,
  Accepted = 20,
  Rejected = 30,
  Timeout = 31,
  ConsumerCancelled = 40,
  ConsumerAccepted = 50,
  ConsumerAcceptedOther = 51,
  ConsumerRejected = 60,
  ManualAssign = 70,
  ManualAssignOther = 71,
}

export enum ReferralLogType {
  Applied = 1,
  AppLaunched = 2,
  JobCompleted = 3,
  PartnerDisplayed = 4,
  ProCompletesJob = 5,
  ConsumerCompletesJob = 6,
  ConsumerAppliesCode = 7,
}

export enum ReferrerType {
  ConsumerStandard = 1,
  ConsumerPartner = 2,
  ConsumerStrategic = 3,
  ConsumerCustomer = 4,
  ConsumerProvider = 5,
}

export enum SourceType {
  User = 'User',
  Organization = 'Organization',
  Partner = 'Partner',
}

export enum AccountTypes {
  Individual = 1,
  Company = 2,
}

export enum CompanyEmployeeStatus {
  DELETED = -1,
  LEGACY_ACTIVE = 0,
  ACTIVE = 1,
}

export enum CompanyServiceStatus {
  Inactive = -1,
  Active = 1,
}

export enum UserCompanyStatus {
  Deleted = -1,
  LegacyActive = 0,
  Active = 1,
}

export enum BrandAttributeNames {
  Name = 'name',
  LogoUrl = 'logo_url',
  ContactSupport = 'contact_support',
  RequestActionText = 'request_action_text',
  LogoIconUrl = 'logo_icon_url',
  ProRequirementsUrl = 'pro_requirements_url',
}

export enum UserPropertyResidentStatus {
  Accepted = 1,
  Deleted = -1,
  Pending = 0,
}

export enum PropertyStatus {
  Deleted = -1,
  LegacyActive = 0,
  Active = 1,
}

export enum PropertyType {
  Personal = 1,
  Managed = 2,
}

export enum PropertyClass {
  Undefined = 0,
  Single = 1,
  Multi = 2,
  Commercial = 3,
}

export enum VeteranBranch {
  Army = 1,
  Navy = 2,
  MarineCorps = 3,
  AirForce = 4,
  CoastGuard = 5,
}

export enum InstallationVendor {
  Ferguson = 1,
  Ecobee = 2,
  HomeDepot = 24,
}

export enum UserBackgroundStatus {
  Clear = 'clear',
  Pending = 'pending',
  Fail = 'fail',
  Consider = 'consider',
  Timeout = 'timeout',
  Declined = 'declined',
  Expired = 'expired',
  Expiring = 'expiring',
  Dispute = 'dispute',
  Suspended = 'suspended',
  ConsiderPreAdverse = 'consider_pre_adverse',
}

export enum UserBackgroundMonitorStatus {
  Active = 'active',
}

export enum BankAccountType {
  Personal = 1,
  Business = 2,
}

export enum LegalEntityType {
  Individual = 1,
  Business = 2,
}

export enum UserVerifiedStatus {
  BgCheckNotVerified = 0,
  BgCheckVerified = 1,
  BgCheckWaitingOnFull = 2,
}

export enum UserActiveStatus {
  Inactive = -1,
  Active = 1,
  Pending = 2,
  Suspended = 3,
}

export enum WorkorderPriority {
  Low = 1,
  Normal = 2,
  High = 3,
}

export enum WorkOrderStatus {
  Deleted = -1,
  Rejected = 2,
  Requested = 5,
  Pending = 10,
  Draft = 15,
  InProgress = 20,
  Completed = 30,
  Reserved = 40,
}

export enum WorkorderType {
  General = 1,
  Rehab = 2,
  Turn = 3,
  Install = 4,
  Repair = 5,
  Replace = 6,
  Diagnostic = 7,
}

export enum ServiceUserType {
  CONSUMER = 'consumer',
  PROVIDER = 'provider',
}
