import css from 'styled-jsx/css';
import Text, { TextSize } from './Text';
import { FONT_WEIGHT, SPACING } from './theme';
import getInputTextColorName from './utils/getInputTextColorName';

const { className: labelSpanClassName, styles: labelSpanStyles } = css.resolve`
  span {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 1px 0;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

type Props = {
  required?: boolean;
  isFocused?: boolean;
  imitateFocused?: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
  label?: React.ReactNode;
  weight?: keyof typeof FONT_WEIGHT;
  size?: TextSize;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

export const Label: React.FC<Props> = ({
  className,
  required = false,
  isFocused = false,
  imitateFocused = false,
  isInvalid = false,
  disabled = false,
  weight = 'bold',
  size = 'medium',
  label,
  children,
  ...labelAttrs
}: Props) => {
  const labelColor = getInputTextColorName({
    disabled,
    isFocused: isFocused || imitateFocused,
    isInvalid,
  });

  return (
    <label className={className} {...labelAttrs}>
      {label && (
        <Text
          color={labelColor}
          element="span"
          size={size}
          weight={weight}
          style={{ paddingTop: 0, paddingBottom: SPACING.xxs }}
        >
          {required && <span className="required">* </span>}
          {label}
        </Text>
      )}
      {children}
      <style jsx>
        {`
          label {
            display: block;
            margin: 0;
            padding: 0;
            text-align: left;
          }
          .required {
            color: red;
          }
        `}
      </style>

      {labelSpanStyles}
    </label>
  );
};

export default Label;
