import { ApolloError } from '@apollo/client';
import { HomeeError } from '@homee/util-common';

export function getErrorMessage(
  error: Error,
  defaultMessage = 'An error occurred',
): string {
  if (!error) return defaultMessage;
  if (error instanceof HomeeError || error instanceof ApolloError) {
    return error.message;
  }
  if (error instanceof Error) {
    return defaultMessage;
  }

  return defaultMessage;
}

export function getErrorStatus(error: any): number | undefined {
  if (!error) return undefined;
  if (error instanceof HomeeError) {
    return error.statusCode;
  }

  if (error instanceof ApolloError) {
    const found = error.graphQLErrors.find(
      (e) => (e.extensions?.exception as any)?.statusCode,
    );

    if (found) {
      error = found;
    }
  }

  if (error.extensions?.exception) {
    return (error.extensions?.exception as any)?.statusCode;
  }

  return undefined;
}
