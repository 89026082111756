import { deleteUndefined } from '../utils/object';

export type FullAddress = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode?: string;
  country?: string;
};

export function extractAddress(address?: string): FullAddress | undefined {
  if (!address) return undefined;

  const [lines, city, statePostalCode, ...rest] = address.split(',');

  const [line1, line2] = lines.split('\n');
  const postalCode = extractPostalCode(statePostalCode);
  const state = postalCode
    ? statePostalCode.replace(postalCode, '')
    : statePostalCode;

  const out: FullAddress = deleteUndefined({
    addressLine1: line1.trim(),
    addressLine2: line2?.trim(),
    city: city?.trim(),
    state: state?.trim(),
    postalCode: postalCode?.trim(),
    country: rest.length ? rest.join(', ').trim() : undefined,
  });

  return out;
}

function extractPostalCode(input: string): string | undefined {
  const matches = input.match(
    /((?:\d{5}(?:\d{4})?|[A-Za-z][0-9][A-Za-z](?: [0-9][A-Za-z][0-9])?))/,
  );

  return matches?.length ? matches[0] : undefined;
}

export function stringifyAddress(address?: FullAddress | string): string {
  if (!address) return '';

  if (typeof address === 'string') return address;

  const { addressLine1, addressLine2, city, state, postalCode, country } =
    address;

  const lines = [addressLine1, addressLine2].filter((x) => x);
  const statePostalCode = postalCode ? `${state} ${postalCode}` : state;
  const rest = [country].filter((x) => x);

  const out = [lines.join('\n'), city, statePostalCode, ...rest].join(', ');

  return out;
}
