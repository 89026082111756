import { deleteUndefined } from '../utils/object';

export type FullName = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
};

export function extractName(fullName: string): FullName {
  fullName = fullName?.trim() ?? '';
  const [first, ...rest] = fullName.split(' ');
  const last = rest?.pop();

  const out: FullName = deleteUndefined({
    firstName: first?.trim() ?? '',
    middleName: rest.join(' ').trim() ?? '',
    lastName: last?.trim() ?? '',
  });

  return out;
}

export function combineName(input: {
  firstName?: string;
  lastName?: string;
}): string {
  const { firstName, lastName } = input ?? {};

  return `${firstName ?? ''} ${lastName ?? ''}`.trim() ?? '';
}

export function getInitials(
  input:
    | string
    | {
        firstName?: string;
        lastName?: string;
      },
): string {
  let first: string | undefined;
  let last: string | undefined;

  if (typeof input === 'string') {
    input = input?.trim() ?? '';
    const [firstName, ...rest] = input.split(' ');
    first = firstName?.trim() ?? '';
    last = rest?.pop()?.trim() ?? '';
  } else {
    first = input?.firstName?.trim() ?? '';
    last = input?.lastName?.trim() ?? '';
  }

  const initials = [first, last]
    .filter((n) => n)
    .map((n) => n.substring(0, 1))
    .join('');

  return initials;
}
