import camelCase from 'lodash/camelCase';
import { ValidationError } from '../errors/validation.error';
import type {
  Entries,
  Enum,
  EnumOrString,
  EnumValue,
  MaybeFunc,
  ValueUnion,
} from './types';

export function throwValidationError(error: string | Error) {
  if (typeof error === 'string') {
    throw new ValidationError(error);
  }
  throw error;
}

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function assertIsDefined<T>(
  value?: T | null | undefined,
  errorMessage?: string | Error,
): asserts value is T {
  if (!isDefined(value)) {
    throwValidationError(
      errorMessage || 'The value must be defined and not null.',
    );
  }
}
export function assertIsNotDefined<T>(
  value?: T | null | undefined,
  errorMessage?: string | Error,
): asserts value is T {
  if (isDefined(value)) {
    throwValidationError(errorMessage || 'The value must be not defined');
  }
}
export function assertIsValidDate(
  value?: Date | unknown,
  errorMessage?: string | Error,
): asserts value is Date {
  if (!(value instanceof Date) || !isValidDate(value)) {
    throwValidationError(errorMessage || 'The value must be a Date object.');
  }
}

export function assertIsString(
  value?: unknown,
  errorMessage?: string | Error,
): asserts value is string {
  if (!(typeof value === 'string')) {
    throwValidationError(errorMessage || 'The value must be a string.');
  }
}

export function isNonEmptyString(value?: string): boolean {
  return isDefined(value) && value !== '';
}

/**
 * Asserts that the value is a string, and is not empty
 */
export function assertIsNonEmptyString(
  value: unknown,
  errorMessage?: string | Error,
): asserts value is string {
  errorMessage = errorMessage ?? 'The value must be a non-empty string';
  assertIsString(value, errorMessage);
  if (!isNonEmptyString(value)) {
    throwValidationError(errorMessage);
  }
}

/**
 * Returns true if the value is non-null; non-undefined; and if a string; non-empty
 */
export function isDefinedAndNonEmpty(value: unknown) {
  if (typeof value === 'string') {
    return isNonEmptyString(value);
  }
  return isDefined(value);
}

/**
 * Returns true if the operand can be converted to a valid number
 */
export function isNumeric(value: unknown): boolean {
  const num = Number(value);
  return isNumber(num);
}

/**
 * Convert a value to a number, or throw an error
 */
export function toNumber(
  value: unknown,
  errorMessage?: string | Error,
): number {
  const num = Number(value);
  assertIsNumber(num, errorMessage);
  return num;
}

/**
 * Returns true if the operand is a number type and is non NaN
 */
export function isNumber(value: unknown): boolean {
  return typeof value === 'number' && !Number.isNaN(value);
}

export function assertIsNumber(
  value: unknown,
  errorMessage?: string | Error,
): asserts value is number {
  if (!isNumber(value)) {
    throwValidationError(errorMessage ?? 'The value must be a number');
  }
}

export function isValidDate(value: Date): boolean {
  return value instanceof Date && !Number.isNaN(value.getTime());
}

export function toDate(value: string, errorMessage?: string | Error): Date {
  const valueAsDate = new Date(value);
  if (!isValidDate(valueAsDate)) {
    throwValidationError(errorMessage ?? 'The value is not a valid date/time');
  }
  return valueAsDate;
}

export function assertIsInArray<T>(
  array: T[] | readonly T[],
  value: T,
  errorMessage?: string | Error,
) {
  if (!array.includes(value)) {
    throwValidationError(
      errorMessage || 'The value is not a member of the array.',
    );
  }
}

export function isInEnum<TEnum extends Enum>(
  enumValue: TEnum,
  value: any,
): boolean {
  const values = enumValues<TEnum>(enumValue);

  // The wrapping here is to handle certain mixed types enums
  // and how the values are persisted
  const match = values.some((x) =>
    typeof x === 'number' ? x === Number(value) : x === String(value),
  );

  return match;
}

/**
 * Returns the keys of an enum object.
 *
 * Notes & Caveats: Typescript enums are double linked. e.g.
 * ```
 * enum MyEnum { Foo = 'foo'
 * console.log(MyEnum['Foo']) // foo
 * console.log(MyEnum['foo']) // Foo
 * ```
 * This function tries to only return the unique keys of the enum object. `['Foo']` in the example above.
 * But because of the double-linked nature of enums, for string enums this isn't 100% possible.
 * So we use heuristics to attempt to filter out possible values from the list of keys.
 * This function will work correctly for the following types of enums:
 * * Numeric - `enum MyEnum { Foo = 1 }`
 * * Ambient - `enum MyEnum { Foo }`
 * * lower/lower - `enum MyEnum { foo = 'foo' }`
 * * Pascal/kebab-case - `enum MyEnum { Foo = 'foo-bar' }`
 * * Pascal/snake_case - `enum MyEnum { Foo = 'foo_bar' }`
 * * Pascal/SCREAMING_SNAKE - `enum MyEnum { Foo = 'FOO_BAR' }`
 * * Pascal/camelCase - `enum MyEnum { Foo = 'fooBar' }`
 * * Pascal/Title Case - `enum MyEnum { Foo = 'Title Case' }`
 *
 * Other patterns may not work as intended, and the returned list of keys may include values as well.
 *
 * @param enumValue an enum object.
 * @returns a string array of keys of the provided enum object
 */
export function enumKeys<TEnum extends Enum>(enumValue: TEnum) {
  const keys = Object.keys(enumValue).filter((key) => isNaN(Number(key)));

  const set = new Set(keys);

  // this is gross
  // see notes in tsdoc. we are applying a heuristic to filter out possible values
  // if we have some keys that start with a capital (pascalCase),
  // and not all the keys are upper case (screaming snake case)
  // then we will try to filter out possible values from the keys
  if (
    keys.some((key) => key[0] === key[0].toUpperCase()) &&
    !keys.every((key) => key === key.toUpperCase())
  ) {
    keys.forEach((key) => {
      // assume possible values to be all lower, all upper, including a space, or camelCase keys
      if (
        key === key.toLowerCase() ||
        key == key.toUpperCase() ||
        key.includes(' ') ||
        key == camelCase(key)
      ) {
        set.delete(key);
      }
    });
  }

  return [...set];
}

export function enumKey<TEnum extends Enum>(
  enumValue: TEnum,
  value: EnumValue<TEnum> | string | undefined,
) {
  const keys = enumEntries(enumValue);

  const filtered = keys.filter(([_, v]) => v === value);

  return filtered.length ? filtered[0][0] : undefined;
}

export function enumValues<TEnum extends Enum>(
  enumValue: TEnum,
): EnumValue<TEnum>[] {
  const keys = enumKeys<TEnum>(enumValue);

  const values = keys.map((key) => enumValue[key]) as EnumValue<TEnum>[];

  return values;
}

export function enumEntries<TEnum extends Enum>(
  enumValue: TEnum,
): [keyof TEnum, EnumValue<TEnum>][] {
  const keys = enumKeys<TEnum>(enumValue);

  const values = keys.map((key) => [key, enumValue[key]]) as [
    keyof TEnum,
    EnumValue<TEnum>,
  ][];

  return values;
}

export function enumOrStringToEnum<TEnum extends string>(
  enumOrString: EnumOrString<TEnum>,
): TEnum {
  return enumOrString as unknown as TEnum;
}

export function assertIsInEnum<TEnum extends Enum>(
  enumValue: TEnum,
  value: any,
  errorMessage?: string | Error,
) {
  if (!isInEnum<TEnum>(enumValue, value)) {
    if (!errorMessage) {
      let type: string = typeof value;
      if (type === 'undefined') type = 'value';
      if (type === 'string') value = `'${value}'`;

      const values = enumValues<TEnum>(enumValue)
        .map((x) => (typeof x === 'string' ? `'${x}'` : x))
        .join(', ');

      errorMessage = `The ${type} ${value} is not valid and must be one of ${values}.`;
    }

    throwValidationError(errorMessage);
  }
}

export function assertIsBoolean(
  value: unknown,
  errorMessage?: string | Error,
): asserts value is boolean {
  if (typeof value !== 'boolean') {
    throwValidationError(errorMessage ?? 'The value must be a boolean');
  }
}

export function assertIsArray<T>(
  value: unknown,
  errorMessage?: string | Error,
): asserts value is T[] {
  if (!Array.isArray(value)) {
    throwValidationError(errorMessage ?? 'The value must be an array');
  }
}

export function isPlainObject(value: unknown): boolean {
  return (
    typeof value === 'object' && value !== null && value.constructor === Object
  );
}

export function assertIsPlainObject<T = Record<string, unknown>>(
  value: unknown | T,
  errorMessage?: string | Error,
): asserts value is T {
  if (!isPlainObject(value)) {
    throwValidationError(errorMessage || `The value must be a plain object.`);
  }
}

export function assertIsTrue(
  value: boolean,
  errorMessage?: string | Error,
): asserts value is true {
  if (!value) {
    throwValidationError(errorMessage || 'The value must be true');
  }
}

export function assertNever(
  value: never,
  errorMessage?: string | Error,
): never {
  errorMessage = errorMessage ?? `Unexpected value: ${String(value)}`;
  if (typeof errorMessage === 'string') {
    throw new ValidationError(errorMessage);
  }
  throw errorMessage;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertNeverNoThrow(_value: never): void {
  /* noop */
}

export function objectEntries<T extends object>(t: T): Entries<T> {
  return Object.entries(t) as unknown as Entries<T>;
}

export function objectKeys<T extends object>(t: T): (keyof T)[] {
  return Object.keys(t) as unknown as (keyof T)[];
}

export function objectValues<T extends object>(t: T): ValueUnion<T>[] {
  return Object.values(t) as unknown as ValueUnion<T>[];
}

export function resolveMaybeFunc<T>(input: MaybeFunc<T>): T {
  return typeof input === 'function' ? (input as () => T)() : input;
}
