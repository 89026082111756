export function initialCaps<T extends string>(text: T): Capitalize<T> {
  if (!text) {
    return '' as Capitalize<T>;
  }
  return (text[0].toUpperCase() + text.slice(1)) as Capitalize<T>;
}

export const trimString = <T extends string | undefined | null>(text: T): T => {
  if (!text) {
    return text;
  }
  return text.trim() as T;
};
