import css from 'styled-jsx/css';
import classnames from 'classnames';
import React, { ChangeEvent, ReactElement } from 'react';
import Icon, { Icons } from './Icon';
import { COLOR, SPACING } from './theme';
import Text from './Text';

const { className: iconClassName, styles: iconStyles } = css.resolve`
  /* default state */
  .radio {
    cursor: pointer;
    color: ${COLOR.darkGray};
    top: 0;
    left: 0;
  }

  .focused:not(.checked):not(.error) {
    color: ${COLOR.black};
  }

  /* checked/selected state */
  .checked {
    color: ${COLOR.blue};
  }

  .checked.focused:not(.error) {
    color: ${COLOR.blueHover};
  }

  /* error state */
  .error {
    color: ${COLOR.red};
  }

  .error.focused:not(.checked) {
    color: ${COLOR.redHover};
  }
`;

type Callback<
  T extends string | number | readonly string[] | undefined = string,
> = (opt: RadioOptionValue<T>) => void;

export type RadioOptionValue<
  T extends string | number | readonly string[] | undefined = string,
> = {
  id: string;
  label: string | ReactElement;
  icon?: '' | Icons | ReactElement;
  iconColor?: string;
  value: T;
  selected: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
};

type Props<T extends string | number | readonly string[] | undefined = string> =
  RadioOptionValue<T> & {
    name: string;
    onChange?: Callback<T>;
    iconStyle?: React.CSSProperties;
  };

export function RadioOption<
  T extends string | number | readonly string[] | undefined = string,
>({
  name,
  id,
  label,
  value,
  selected = false,
  icon,
  iconColor,
  onChange,
  iconStyle,
  disabled,
  isInvalid,
}: Props<T>) {
  const [isFocused, setIsFocused] = React.useState(false);

  const inputRef = React.createRef<HTMLInputElement>();

  const onKeyPress = () => {
    // Do nothing; The radio button is automatically selected when user tabs
    // to radio or uses the arrow keys.
  };

  return (
    <li className="root">
      <label htmlFor={`checkbox-${id}`}>
        {typeof icon === 'string' ? (
          <div className="radio-icon">
            {icon && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  minWidth: '20px',
                }}
              >
                <Icon
                  icon={icon}
                  size={14}
                  color={iconColor ? 'white' : 'black'}
                />
              </div>
            )}
          </div>
        ) : (
          icon
        )}
        {typeof label === 'string' ? (
          <Text className="radio-option-label" size="medium" element="span">
            {label}
          </Text>
        ) : (
          label
        )}
        <div
          className="radio-option"
          aria-checked={selected ? 'true' : 'false'}
          onClick={() => {
            const { current } = inputRef;
            if (current) {
              current.focus();
              current.click();
            }
          }}
          onFocus={() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
          onKeyPress={onKeyPress}
          role="radio"
          tabIndex={-1}
        >
          <input
            checked={selected}
            id={`checkbox-${id}`}
            name={name}
            onBlur={() => {
              setIsFocused(false);
            }}
            onChange={(event) => {
              const opt: RadioOptionValue<T> = {
                id,
                label,
                icon,
                iconColor,
                value,
                selected: event.target.checked,
                disabled,
                isInvalid,
              };
              onChange?.(opt);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            ref={inputRef}
            type="radio"
            value={value}
            disabled={disabled}
          />
          <Icon
            className={classnames('radio', iconClassName, {
              selected,
              focused: isFocused,
              error: isInvalid,
            })}
            size={24}
            icon={selected ? 'radioButtonChecked' : 'radioButtonUnchecked'}
            style={iconStyle}
          />
        </div>
      </label>
      <style jsx>
        {`
          .root {
            margin-right: ${SPACING.xs}px;
            padding: ${SPACING.xs}px;
            border-bottom: 1px solid ${COLOR.black};
          }
          .root label {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
          }
          .root :global(.radio-icon) {
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            background: ${iconColor};
            border-radius: 3px;
          }
          .root :global(.radio-option-label) {
            flex: 1;
            width: 0;
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 ${SPACING.xs}px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .radio-option {
            display: flex;
            align-items: center;
          }

          input {
            height: 1px; /* making 0 hides from some screen readers */
            left: -10px; /* hide by pushing out of container */
            opacity: 0;
            position: absolute;
            width: 1px;
          }

          input:disabled + :global(.radio) {
            color: ${COLOR.lightGray};
            cursor: default;
          }
        `}
      </style>
      {iconStyles}
    </li>
  );
}

export default RadioOption;
