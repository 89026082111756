import getConfig from '../lib/config';
import { useMemo } from 'react';
import { ApolloClientFactory } from '../lib/data/apollo-client-factory';

export function useApollo(pageProps: any) {
  const state = pageProps?.apolloState;
  const { publicRuntimeConfig: config } = getConfig() || {};
  const store = useMemo(
    () => new ApolloClientFactory(config).init(state),
    [state, config],
  );
  return store;
}
