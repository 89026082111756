import Link from 'next/link';
import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { useRouter } from 'next/router';

import {
  COLOR,
  SPACING,
  BREAKPOINT_VALUE,
  Icons,
} from '@homee/ui-web-presentation';
import { Text, Icon } from '@homee/ui-web-presentation';
import ChildNavList from './ChildNavList';

type Props = PropsWithChildren<{
  label: string;
  href: string;
  icon?: React.ReactNode | Icons;
  imgUrl?: string;
  isActive?: boolean;
  className?: string;
  onClick?: (
    event: React.SyntheticEvent<HTMLAnchorElement>,
  ) => any | Promise<any>;
  isNavCollapsed?: boolean;
}>;

const ParentNavItem: React.FC<Props> = ({
  label,
  href,
  icon,
  imgUrl,
  isActive = false,
  children,
  className,
  onClick,
  isNavCollapsed = false,
}) => {
  const { pathname, push } = useRouter();
  const isItemActive = pathname.includes(href);

  const [isItemExpanded, setIsItemExpanded] =
    React.useState<boolean>(isItemActive);

  const renderButton = () => (
    <button
      type="button"
      className={classnames('parent-item', {
        active: isItemActive,
      })}
      onClick={() => {
        if (isNavCollapsed && window.innerWidth >= BREAKPOINT_VALUE.sm) {
          void push(href);
        } else {
          setIsItemExpanded((prev) => !prev);
        }
      }}
    >
      {!!icon &&
        (typeof icon === 'string' ? (
          <figure className={classnames('workbench')}>
            <Icon icon={icon as Icons} />
          </figure>
        ) : (
          <figure className="workbench">{icon}</figure>
        ))}
      <Text element="span" weight={isItemActive ? 'bold' : 'regular'}>
        {label}
        {children && (
          <Icon
            className="accordion-arrow"
            icon={isItemExpanded ? 'expandLess' : 'expandMore'}
          />
        )}
      </Text>
    </button>
  );

  const renderAnchor = () => (
    // Using legacyBehavior due to styling complexities below
    <Link href={href} legacyBehavior>
      <a
        role="link"
        className={classnames('parent-item', {
          active: isItemActive,
        })}
        onClick={onClick}
        onKeyDown={onClick}
        tabIndex={0}
      >
        {!!icon &&
          (typeof icon === 'string' ? (
            <figure className={classnames('workbench')}>
              <Icon icon={icon as Icons} />
            </figure>
          ) : (
            <figure className="workbench">{icon}</figure>
          ))}
        {imgUrl && (
          <img
            alt=""
            height="32"
            src={imgUrl}
            width="32"
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              e.currentTarget.src = '/static/images/avatar.png';
            }}
          />
        )}
        <Text element="span" weight={isItemActive ? 'bold' : 'regular'} onDark>
          {label}
        </Text>
      </a>
    </Link>
  );

  return (
    <li
      className={classnames(className, 'parent-nav', {
        expanded: isItemExpanded,
      })}
    >
      {children ? renderButton() : renderAnchor()}
      {children && (
        <ChildNavList isExpanded={isItemExpanded}>{children}</ChildNavList>
      )}
      <style jsx>
        {`
          /* Nav Item Layout */
          li {
            padding: 0;
            margin: 0;
          }

          li > :global(.parent-item) {
            display: inline-flex;
            align-items: center;
            margin: 0;
            padding: 0 ${SPACING.sm}px;
            width: 100%;
            height: 49px;
          }

          li > :global(button) {
            background: none;
            border: none;
            margin: 0;
          }

          li > :global(.parent-item span) {
            padding-left: ${SPACING.xs}px;
            display: inline-flex;
            flex: 1;
          }

          li > :global(.parent-item figure) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            width: 32px;
            height: 32px;
          }

          li > :global(.parent-item .accordion-arrow) {
            margin-left: auto;
            margin-right: ${SPACING.xs}px;
          }

          li > :global(.parent-item img) {
            border-radius: 20px;
            height: 32px;
            object-fit: cover;
            overflow: hidden;
            width: 32px;
          }

          li > :global(.parent-item:hover) {
            text-decoration: none;
          }

          /* Default Item Colors */
          li > :global(.parent-item figure),
          li > :global(.parent-item span),
          li > :global(.parent-item .accordion-arrow) {
            color: ${COLOR.softGray} !important;
          }

          li > :global(.parent-item:hover figure),
          li > :global(.parent-item:hover span),
          li > :global(.parent-item:hover .accordion-arrow),
          li.expanded > :global(.parent-item:not(.active) figure),
          li.expanded > :global(.parent-item:not(.active) span),
          li.expanded > :global(.parent-item:not(.active) .accordion-arrow) {
            filter: brightness(85%);
          }

          /* Active Item Colors */
          li > :global(.parent-item.active figure),
          li > :global(.parent-item.active span),
          li > :global(.parent-item.active .accordion-arrow) {
            color: ${COLOR.blueLight} !important;
          }

          li > :global(.parent-item.active:hover figure),
          li > :global(.parent-item.active:hover span),
          li > :global(.parent-item.active:hover .accordion-arrow) {
            filter: brightness(85%);
          }

          @media (max-width: ${BREAKPOINT_VALUE.sm - 0.02}px) {
            li > :global(.parent-item) {
              display: inline-flex;
              align-items: center;
              margin: 0;
              padding: 0;
              width: 100%;
            }
          }
        `}
      </style>
    </li>
  );
};

export default ParentNavItem;
