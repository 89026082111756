import { COLOR, Icon, SPACING, Text } from '@homee/ui-web-presentation';
import React, { useCallback, useMemo } from 'react';

import ConfirmCompanyChangeProvider from '../../components/ConfirmCompanyChangeProvider';
import { RefetchProvider } from '../../components/RefetchProvider';
import { useDefaultPageSize } from '../../hooks/useDefaultPageSize';
import { useNotificationsQuery } from '../../lib/generated/types';
import NotificationsModal from './NotificationsModal';

const MAX_UNREAD = 6;

const NotificationButton: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const limit = useDefaultPageSize('notifications');

  const { data, refetch, loading } = useNotificationsQuery({
    variables: {
      offset: 0,
      limit,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 1000 * 60 * 5, // 5 minutes - pollInterval is in milliseconds
  });

  const notifications = useMemo(() => data?.notifications.items ?? [], [data]);

  const unreadCount = useMemo(
    () => data?.notifications.unreadCount ?? 0,
    [data],
  );
  const hasUnread = useMemo(() => unreadCount > 0, [unreadCount]);

  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className="notification">
      <ConfirmCompanyChangeProvider>
        <RefetchProvider onChange={refetch}>
          <button
            type="button"
            onClick={(e) => {
              if (!isOpen) {
                // e.stopPropogation allows the modal to open without it closing immediately
                // e.stopPropogation is in the conditional to allow users to click the notification button again to close the modal
                e.stopPropagation();
                setIsOpen(!isOpen);
              }
            }}
          >
            <Icon icon="notifications" size={24} />
            {!!unreadCount && (
              <Text
                element="span"
                size={unreadCount > MAX_UNREAD ? 'x-small' : 'small'}
                onDark
                weight="semi"
                className="notif-number"
              >
                {unreadCount ? unreadCount : `${MAX_UNREAD}+`}
              </Text>
            )}
          </button>
          <NotificationsModal
            isOpen={isOpen}
            isLoading={loading}
            notifications={notifications}
            hasUnread={hasUnread}
            onRequestClose={onRequestClose}
          />
          <style jsx>{`
            .notification {
              display: flex;
              position: relative;
            }
            button {
              display: flex;
              align-items: center;
              border: none;
              padding: 0 2px;
              color: ${unreadCount ? COLOR.blue : COLOR.darkGray};
              background: none;
              border: ${unreadCount ? `1px solid ${COLOR.blue}` : 'none'};
              border-radius: 4px;
            }
            button :global(span.notif-number) {
              background-clip: padding-box;
              display: ${unreadCount ? 'block' : 'none'};
              background-color: ${unreadCount
                ? COLOR.red
                : 'transparent'} !important;
              border-radius: 50%;
              padding: 0 3px;
              height: ${SPACING.sm}px;
              min-width: ${SPACING.sm}px;
            }
          `}</style>
        </RefetchProvider>
      </ConfirmCompanyChangeProvider>
    </div>
  );
};

export default NotificationButton;
