import 'reflect-metadata';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import NextApp, { AppProps, AppContext, AppInitialProps } from 'next/app';
import getConfig from '../lib/config';
import NextNProgress from 'nextjs-progressbar';
import { IntercomProvider } from 'react-use-intercom';
import AccessAllowed from '../components/AccessAllowed';
import { AppContextProvider } from '../components/AppContext';
import { useApollo } from '../hooks/useApollo';
import MainLayout from '../layouts/MainLayout';
import type { LayoutGetter, CustomNextPage } from '../lib/types/next';
import Toaster from '../components/Toaster';
import { initializeDatadog } from '../lib/datadog';

import 'react-toastify/dist/ReactToastify.css';
import '../public/global.css';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { NextAdapter } from 'next-query-params';
import { QueryParamProvider } from 'use-query-params';
import ChangeDetectionProvider from '../components/ChangeDetectionProvider';

const TermsAndConditionsModal = dynamic(
  () => import('../components/TermsAndConditionsModal'),
  { ssr: false },
);

type CustomAppProps = AppProps & {
  Component: CustomNextPage;
};

type CustomApp = React.FC<CustomAppProps> & {
  getInitialProps: ({ Component, ctx }: AppContext) => Promise<AppInitialProps>;
};

const App: CustomApp = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  // Use the layout defined at the page level, if available
  // otherwise fallback to the auth layout
  const getLayout: LayoutGetter =
    Component.getLayout ?? ((page) => <MainLayout>{page}</MainLayout>);

  const apolloClient = useApollo(pageProps);

  validateAllowedRoles(Component);

  const {
    publicRuntimeConfig: { intercomAppId },
  } = getConfig();

  useEffect(() => {
    initializeDatadog();
  }, []);

  return (
    <>
      <Head>
        <link rel="icon" href="/pro-portal.svg" type="image/svg+xml" />
      </Head>
      <QueryParamProvider adapter={NextAdapter}>
        <SessionProvider session={session} refetchInterval={60}>
          <ApolloProvider client={apolloClient}>
            <Toaster />
            <AccessAllowed roles={Component.allowedRoles} forceSignIn={true}>
              <IntercomProvider appId={intercomAppId}>
                <AppContextProvider>
                  <TermsAndConditionsModal />
                  <ChangeDetectionProvider>
                    <NextNProgress options={{ showSpinner: false }} />
                    {getLayout(<Component {...pageProps} />)}
                  </ChangeDetectionProvider>
                </AppContextProvider>
              </IntercomProvider>
            </AccessAllowed>
          </ApolloProvider>
        </SessionProvider>
      </QueryParamProvider>
    </>
  );
};

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  return { ...appProps };
};

const ignoredPages = new Set(['ErrorPage']);
function validateAllowedRoles(Component: CustomNextPage) {
  if (ignoredPages.has(Component.displayName)) return;
  if (Component.allowedRoles?.length > 0) return;

  // TODO: better logging? Should this be dev-time only?
  // eslint-disable-next-line no-console
  console.warn(
    `Warning page ${Component.displayName} does not include allowedRoles and will be defaulted 'public' visibility`,
  );
}

export default App;
