import { get, isEqual } from 'lodash';
import { Type } from '@sinclair/typebox';
import type { Paths, PickByPath } from './types';

export const ChangeSchema = Type.Object({
  path: Type.String(),
  before: Type.Optional(Type.Unknown()),
  after: Type.Optional(Type.Unknown()),
});

// Produces an object, but we only care about the values not the keys so this is inner
type ChangeInner<T extends Record<PropertyKey, unknown>> = {
  [K in Paths<T>]: {
    path: K;
    before: PickByPath<T, K>;
    after: PickByPath<T, K>;
  };
};

export type Change<T extends Record<PropertyKey, unknown>> =
  ChangeInner<T>[keyof ChangeInner<T>];

export function diff<T extends Record<string, unknown>>(
  a: T,
  b: T,
  paths?: string[],
): Change<T>[] {
  const keys = (paths ||
    Array.from(new Set([...Object.keys(a), ...Object.keys(b)]))) as Paths<T>[];
  return keys
    .map((key: Paths<T>): Change<T> | undefined => {
      // support dot notation
      const aValue = get(a, key);
      const bValue = get(b, key);
      if (isEqual(aValue, bValue)) return undefined;
      return {
        path: key,
        before: aValue as PickByPath<T, typeof key>,
        after: bValue as PickByPath<T, typeof key>,
      };
    })
    .filter((d): d is Change<T> => !!d);
}
