import {
  BREAKPOINT_VALUE,
  Button,
  COLOR,
  Icon,
  SPACING,
  Icons,
} from '@homee/ui-web-presentation';
import classnames from 'classnames';
import {
  Id,
  toast,
  ToastContainer,
  ToastContent,
  ToastContentProps,
  ToastOptions,
  ToastPromiseParams,
  UpdateOptions,
} from 'react-toastify';
import { getErrorMessage } from '../lib/errors.util';
import { isFunction } from 'lodash';
import { routeChangeCancelError } from './ChangeDetectionProvider';

// Wrapping ToastContainer so we can set global options and apply theme
const Toaster: React.FC = () => {
  return (
    <>
      <ToastContainer theme="colored" position="bottom-center" />
      <style jsx global>
        {`
          :root {
            --toastify-color-info: ${COLOR.blue};
            --toastify-color-success: ${COLOR.green};
            --toastify-color-warning: ${COLOR.orangeStatus};
            --toastify-color-error: ${COLOR.red};
          }
        `}
      </style>
    </>
  );
};

// Having the majority of the calls to toast run through
// these methods gives us the ability to (later) modify the options
// or components globally
export function toastSuccess<TData = unknown>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData> | undefined,
): Id {
  return toast.success(content, options);
}

export function toastError<TData = unknown>(
  content: ToastContent<TData> | Error,
  options?: (ToastOptions<TData> & { defaultMessage: string }) | undefined,
): Id {
  let msg: ToastContent<TData> | string | undefined;

  if (content === routeChangeCancelError) {
    return undefined;
  }

  if (content instanceof Error) {
    msg = getErrorMessage(content, options?.defaultMessage);
  } else {
    msg = content ?? options?.defaultMessage ?? 'An error occurred';
  }

  return toast.error(msg, options);
}

export function toastWarning<TData = unknown>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData> | undefined,
): Id {
  return toast.warning(content, options);
}

export function toastInfo<TData = unknown>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData> | undefined,
): Id {
  return toast.info(content, options);
}

export function toastLoading<TData = unknown>(
  content: ToastContent<TData>,
  options?: ToastOptions<TData> | undefined,
): Id {
  return toast.loading(content, options);
}

export function toastUpdate<TData = unknown>(
  toastId: Id,
  options?: UpdateOptions<TData> | undefined,
): void {
  return toast.update(toastId, options);
}

export function toastPromise<
  TData = unknown,
  TError = unknown,
  TPending = unknown,
>(
  content: Promise<TData>,
  resolved: ToastPromiseParams<TData, TError, TPending>,
  options?: ToastOptions<TData> | undefined,
): Promise<TData> {
  return toast.promise(content, resolved, options);
}

export function toastGreatSuccess<TData = unknown>(
  content: ToastContent<TData>,
  closeButtonText = 'Continue',
  icon?: Icons,
  iconSize = 60,
  options?: ToastOptions<TData> | undefined,
): Id {
  const { className, toastId, ...otherOptions } = options || {};
  const toastClassName = classnames(
    'toast-great-success',
    typeof className === 'string' ? className : undefined,
  );

  return toast.success(
    (props: ToastContentProps<TData>) => {
      const toastContent = isFunction(content) ? content(props) : content;

      return (
        <div className="toast-great-success-content">
          {toastContent}
          <style jsx>{`
            :global(.toast-great-success) {
              text-align: center;
              display: flex;
              flex-direction: column;
              background-color: ${COLOR.white} !important;
              padding: 80px 152px 38px;
              margin: auto;
              width: 550px;
            }

            :global(.toast-great-success .Toastify__toast-body) {
              color: ${COLOR.black};
              flex-direction: column;
            }

            :global(.toast-great-success .Toastify__toast-icon) {
              width: auto !important;
            }

            :global(.toast-great-success .inner .button-group) {
              justify-content: center !important;
            }

            :global(.toast-great-success svg) {
              margin-bottom: ${SPACING.sm}px;
              background-color: ${COLOR.green};
              border-radius: 50%;
            }

            :global(.toast-great-success svg path) {
              fill: ${COLOR.white};
            }

            @media (max-width: ${BREAKPOINT_VALUE.md}px) {
              :global(.toast-great-success) {
                width: 360px;
                padding: 80px 60px 38px;
              }
            }
          `}</style>
        </div>
      );
    },
    {
      className: toastClassName,
      toastId: toastId ?? 'great-success',
      icon: <Icon icon={'checkRoundEdges' || icon} size={iconSize} />,
      closeButton: (
        <Button size={'small'} variant="primary">
          {closeButtonText}
        </Button>
      ),
      autoClose: false,
      ...otherOptions,
    },
  );
}

export default Toaster;
