import { signIn } from 'next-auth/react';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useCompanyId } from '../hooks/useCompanyId';
import useTypedSession from '../hooks/useTypedSession';
import { PageRole, PageRoles } from '../lib/types/next';
import { isAllowed } from '../lib/auth.utils';

type Props = PropsWithChildren<{
  roles?: PageRole[];
  denied?: ReactNode;
  forceSignIn?: boolean;
}>;

const AccessAllowed: React.FC<Props> = ({
  roles,
  children,
  denied,
  forceSignIn,
}) => {
  const { data } = useTypedSession({
    required: true,
    onUnauthenticated() {
      // when the user is unauthenticated, if the allowed role isn't "public" force a sign-in
      if (forceSignIn && !roles?.some((role) => role === PageRoles.Public)) {
        void signIn();
      }
    },
  });
  const companyId = useCompanyId();
  const allowed = useMemo(() => {
    const { user } = data ?? {};

    return isAllowed(user, roles, companyId);
  }, [roles, data, companyId]);

  return <>{allowed ? children : denied}</>;
};

export default AccessAllowed;
