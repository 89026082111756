import classNames from 'classnames';
import Text, { TextProps } from './Text';

type Props = {
  isInvalid?: boolean;
} & TextProps;

export const InputHelperText: React.FC<Props> = ({
  children,
  isInvalid,
  size = 'medium',
  style,
  weight,
  color = 'gray',
  className,
  ...extraProps
}: Props) => {
  if (typeof children !== 'string') return null;

  const textStyle = {
    marginBottom: 0,
    marginTop: 4,
    ...(isInvalid && { fontStyle: 'italic' }),
    ...style,
  };

  return (
    <Text
      color={isInvalid ? 'error' : color}
      size={size}
      style={textStyle}
      weight={weight}
      className={classNames(className, 'helper-text')}
      {...extraProps}
    >
      {children}
    </Text>
  );
};

export default InputHelperText;
