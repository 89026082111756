function dateReviver(_key: string, value: unknown) {
  if (typeof value === 'string') {
    const a =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(Z|([+-])(\d{2}):(\d{2}))$/.exec(
        value,
      );
    if (a) {
      return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
    }
  }
  return value;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface JsonEncoded<_T> extends String {
  _brand: 'JsonEncoded';
}

/**
 * Parse JSON, and revive dates
 */
export function parseJson<TOut, TIn extends string = string>(text: TIn): TOut {
  const parsed = JSON.parse(text as string, dateReviver) as TOut;
  return parsed;
}

export function stringifyJson<TIn, TOut extends string = string>(
  obj: TIn,
): TOut {
  return JSON.stringify(obj) as unknown as TOut;
}
