import { isNonEmptyString } from '../utils/validation';

/**
 * Formats input into a string in the format (###)###-####.
 * @param  {string} input - 1234567890'
 * @param  {func} format - a certain format user would want for mobile display
 * @return {string} (123)-456-7890'
 */
export function formatPhoneNumber(input: string | undefined) {
  if (!isNonEmptyString(input)) return '';

  let parsedInput = input!.replace(/[^0-9]+/g, '');

  const parts: string[] = [];

  let countryCode = '';
  if (parsedInput.length > 10) {
    // Extract the country code from the input (assuming it's at the beginning)
    countryCode = parsedInput.slice(0, parsedInput.length - 10);
    parsedInput = parsedInput.slice(-10);
  }

  if (countryCode) {
    parts.push(`+${countryCode} `);
  }

  if (parsedInput.length > 0) {
    parts.push('(');
    parts.push(parsedInput.slice(0, 3));
  }

  if (parsedInput.length > 3) {
    parts.push(') ');
    parts.push(parsedInput.slice(3, 6));
  }

  if (parsedInput.length > 6) {
    parts.push('-');
    parts.push(parsedInput.slice(6, 10));
  }

  return parts.join('');
}

export const toE164Format = (phoneNumberString: string, countryCode = '+1') => {
  if (!phoneNumberString || typeof phoneNumberString !== 'string') {
    return '';
  }

  const numericPhoneNumber = phoneNumberString.replace(/\D/g, '');

  // If the input has no country code, add the default country code
  const formattedNumber =
    countryCode === '' || numericPhoneNumber.startsWith(countryCode)
      ? `+${numericPhoneNumber}`
      : `${countryCode}${numericPhoneNumber}`;

  return formattedNumber;
};

export const isE164Format = (phoneNumberString: string) => {
  return /^\+[1-9]\d{1,14}$/.test(phoneNumberString);
};
/**
 * removes any non numeric character from a string.
 * @param  {string} phoneNumber - +1(234)-656-7890'
 * @param  {boolean} keepPlus - a flag to leave the plus character
 * @default keepPlus = false
 * @return {string} 12346567890'
 */
export const stripPhoneNumber = (
  phoneNumber: string | number | undefined | unknown,
  keepPlus = false,
): string => {
  const num = phoneNumber?.toString();
  if (!num || !isNonEmptyString(num)) return '';
  const regex = keepPlus ? /[^\d+]+/g : /[^\d]+/g;
  const res = num.replace(regex, '');
  if (res === '+') return '';
  return res;
};

export default formatPhoneNumber;
