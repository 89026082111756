/**
 * Use this in a default case in a switch to ensure you've explicitly handled all cases
 * Because if you handle all cases exhaustively, you should never reach the default.
 * Then you can call this there
 *
 * @link https://www.youtube.com/watch?v=93RA21QfqIo
 * */
export function assertUnreachable(x: never, error?: string | Error): never {
  if (error instanceof Error) {
    throw Error;
  }
  const message = error ?? 'This should not be reachable';

  throw new Error(message);
}

export function assertUnreachableNoThrow(x: never) {
  /* noop */
}
