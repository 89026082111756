/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { fromPromise } from '@apollo/client/link/utils';
import { withScalars } from 'apollo-link-scalars';
import type { OperationDefinitionNode } from 'graphql/language/ast';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { getTypedSession } from '../auth/session';
import generatedIntrospection from '../generated/introspection';
import type { AppSharedConfig } from '../types/config';
import type { ApolloAppCache, ApolloAppClient } from './apollo.types';
import schemaJson from '../generated/schema.json';
import { buildClientSchema, IntrospectionQuery } from 'graphql/utilities';
import { typesMap } from './apollo.util';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { signOut } from 'next-auth/react';

/**
 * Class for creating Apollo Client instances.
 */
export class ApolloClientFactory {
  constructor(private config: AppSharedConfig) {}

  private static client: ApolloAppClient;

  init(initialState?: ApolloAppCache) {
    const client = ApolloClientFactory.client ?? this.create();

    // hydrate initial state from Next.js page
    if (initialState) {
      // Get existing cache, loaded during client side data fetching
      const existingCache = client.extract();

      // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
      const data = merge(existingCache, initialState, {
        // combine arrays using object equality
        arrayMerge: (destinationArray, sourceArray) => [
          ...sourceArray,
          ...destinationArray.filter((d) =>
            sourceArray.every((s) => !isEqual(d, s)),
          ),
        ],
      });

      // Restore the cache with the merged data
      client.cache.restore(data);
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return client;

    // Create the Apollo Client once in the client
    if (!ApolloClientFactory.client) ApolloClientFactory.client = client;

    return client;
  }

  /**
   * creates a Apollo client, this should only be called via init
   */
  private create(initialState?: ApolloAppCache): ApolloAppClient {
    const isBrowser = typeof window !== 'undefined';

    return new ApolloClient({
      connectToDevTools: isBrowser,
      ssrMode: !isBrowser, // Disables forceFetch on the server (so queries only run once)
      link: ApolloLink.from(this.getLinks()),
      cache: new InMemoryCache({
        possibleTypes: generatedIntrospection.possibleTypes,
      }).restore(initialState || {}),
    });
  }

  /**
   * Creates an ordered array of {@link https://www.apollographql.com/docs/react/api/link/introduction/ | Apollo Links }
   * that handle the network requests from the apollo client
   */
  private getLinks() {
    const { uri } = this.config.graph;
    const schema = buildClientSchema(
      schemaJson as unknown as IntrospectionQuery,
    );
    return [
      // sets the auth context using the session
      setContext(async (_, { headers }) => {
        const session = await getTypedSession();
        const { user } = session || {};
        const { gt } = user || {};

        return {
          headers: {
            ...headers,
            authorization: gt ? `Bearer ${gt}` : '',
          },
        };
      }),
      // retrys failed calls caused by network errors
      new RetryLink({
        delay: {
          initial: 200,
          jitter: true,
        },
        attempts: {
          max: 3,
          retryIf: (error, operation) => {
            // if the operation is a mutation, don't retry
            // since mutations may not be idempotent,
            // and we aren't sure what happened on a network error.
            if (this.getOperationType(operation) === 'mutation') {
              return false;
            }

            return true;
          },
        },
      }),
      // handles other errors.
      onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            switch (err.extensions.code) {
              case 'UNAUTHENTICATED':
                /* In an OAuth2 with refresh token, we should attempt to refresh the access token
                 something like:
                return fromPromise(
                  (async () => {
                    try {
                      const token = await refreshToken();
                      const headers = operation.getContext().headers;
                      operation.setContext({
                        headers: {
                          ...headers,
                          authorization: token ? `Bearer ${token}` : '',
                        },
                      });
                    } catch (e) {
                      signOut();
                    }
                  })()
                ).flatMap(() => forward(operation));

                But, we are using the credential provider for nextauth
                and there doesn't seem to be a way to get a new jwt
                so we will just sign the user out, and not await the promise
                */
                return;

              case 'INTERNAL_SERVER_ERROR': {
                // In the event the API token has expired, we should sign the user out
                if (this.isExpiredApiTokenError(err)) {
                  return fromPromise(signOut()).flatMap(() =>
                    forward(operation),
                  );
                }
              }
            }

            void this.debugPrintOperation(operation);
          }
        }

        // These errors are not usually very user friendly.
        // We may want to wrap and rethrow them as something with a prettier message
        if (networkError) {
          // TODO: should these logs use a LogEmitter, pino on the client?
          console.error('GraphQL Network Error', networkError);
          //throw networkError;
        }
      }),
      withScalars({ schema, typesMap }),
      createUploadLink({
        uri,
        fetch,
        headers: { 'Apollo-Require-Preflight': 'true' },
      }),
    ];
  }

  private async debugPrintOperation(operation: Operation) {
    if (this.config.production || !this.config.graph.debug) return;

    // I don't want to include this in the main prod bundle
    const { print } = await import('graphql/language/printer');

    const query = print(operation.query);
    console.info('GraphQL Query');
    console.info(query);
    console.info('Variables');
    console.info(operation.variables);
  }

  private getOperationType(operation: Operation): string | undefined {
    const definitionNode = operation?.query?.definitions.find(
      (d) => (d as OperationDefinitionNode).operation,
    ) as OperationDefinitionNode;

    return definitionNode?.operation;
  }

  private isExpiredApiTokenError(error: any): any {
    const originalError = this.getOriginalError(error);

    const out =
      originalError?.code === 401 &&
      originalError?.response.body?.error?.message ===
        'Authorization token is not valid';

    return out;
  }

  private getOriginalError(error: any): any {
    return error?.extensions?.exception?.originalError;
  }
}
