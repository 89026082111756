type Props = {
  className?: string;
  color?: string;
  height?: number;
  style?: React.CSSProperties;
};

export const ThreeLoadingDots: React.FC<Props> = ({
  className,
  color,
  style,
  height,
}) => (
  <svg
    className={className}
    height={height}
    viewBox="0 0 265 65"
    xmlns="http://www.w3.org/2000/svg"
    style={{ color, ...style }}
  >
    <g transform="translate(7.5 7.5)">
      <circle cx="25" cy="25" r="25" fill="currentColor" />
    </g>
    <g transform="translate(107.5 7.5)">
      <circle cx="25" cy="25" r="25" fill="currentColor" />
    </g>
    <g transform="translate(207.5 7.5)">
      <circle cx="25" cy="25" r="25" fill="currentColor" />
    </g>
    <style jsx>
      {`
        @keyframes grow-shrink-one {
          0% {
            transform: translate(7.5px, 7.5px) scale(1);
          }
          30% {
            transform: translate(7.5px, 7.5px) scale(1.3);
          }
          70% {
            transform: translate(7.5px, 7.5px) scale(1);
          }
          100% {
            transform: translate(7.5px, 7.5px) scale(1);
          }
        }

        @keyframes grow-shrink-two {
          0% {
            transform: translate(107.5px, 7.5px) scale(1);
          }
          30% {
            transform: translate(107.5px, 7.5px) scale(1.3);
          }
          70% {
            transform: translate(107.5px, 7.5px) scale(1);
          }
          100% {
            transform: translate(107.5px, 7.5px) scale(1);
          }
        }
        @keyframes grow-shrink-three {
          0% {
            transform: translate(207.5px, 7.5px) scale(1);
          }
          30% {
            transform: translate(207.5px, 7.5px) scale(1.3);
          }
          70% {
            transform: translate(207.5px, 7.5px) scale(1);
          }
          100% {
            transform: translate(207.5px, 7.5px) scale(1);
          }
        }

        g {
          animation-duration: 0.6s;
          animation-iteration-count: infinite;
          transform-origin: 10% 40%;
        }

        g:nth-of-type(1) {
          animation-delay: 0s;
          animation-name: grow-shrink-one;
        }

        g:nth-of-type(2) {
          animation-delay: 0.1s;
          animation-name: grow-shrink-two;
        }

        g:nth-of-type(3) {
          animation-delay: 0.2s;
          animation-name: grow-shrink-three;
        }
      `}
    </style>
  </svg>
);

ThreeLoadingDots.defaultProps = {
  className: undefined,
  color: undefined,
  height: undefined,
  style: {},
};

export default ThreeLoadingDots;
