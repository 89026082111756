import { useDialog, Dialog, ChildrenProps } from '@homee/ui-web-presentation';
import React, { useCallback } from 'react';
import { useAppContext } from '../hooks/useAppContext';
import { AppActionType } from './AppContext';

type Company = {
  id: string;
  name: string;
};

type ConfirmCompanyChangeContextType = {
  confirmCompanyChange: (companyId?: string) => Promise<boolean>;
};

const ConfirmCompanyChangeContext =
  React.createContext<ConfirmCompanyChangeContextType>(null);

export function useConfirmCompanyChange(): ConfirmCompanyChangeContextType {
  const context = React.useContext(ConfirmCompanyChangeContext);

  if (!context) {
    throw new Error(
      'useConfirmCompanyChange must be used within a ConfirmCompanyChangeProvider',
    );
  }

  return context;
}

export const ConfirmCompanyChangeProvider: React.FC<ChildrenProps> = ({
  children,
}) => {
  const { open, close, isOpen, displayProps } = useDialog<boolean>();

  const {
    loading,
    selectedCompanyId,
    companies,
    dispatch: appDispatch,
  } = useAppContext();

  const confirmCompanyChange = useCallback(
    async (companyId?: string): Promise<boolean> => {
      if (!companyId) return true;

      if (companyId.toLowerCase() === selectedCompanyId.toLowerCase()) {
        return true;
      }

      const company = companies.find((c) => c.id === companyId);

      if (!company) {
        // eslint-disable-next-line no-console
        console.warn(
          `Cannot switch to provided company. Company (id: ${companyId}) not found in users companies`,
        );
        return false;
      }

      const switchCompany = await open({
        title: company.name
          ? `Switch company to ${company.name}?`
          : 'Switch company?',
      });

      if (!switchCompany) {
        return false;
      }

      appDispatch({
        type: AppActionType.SelectCompany,
        companyId: company.id,
      });

      return true;
    },
    [open, selectedCompanyId, companies, appDispatch],
  );

  return (
    <ConfirmCompanyChangeContext.Provider value={{ confirmCompanyChange }}>
      {children}
      <Dialog<boolean>
        dismissible
        title={`Switch company?`}
        description="Switch to this company now?"
        onClose={close}
        isOpen={isOpen}
        actions={[
          {
            variant: 'negative',
            size: 'small',
            title: 'Cancel',
            result: false,
          },
          {
            variant: 'primary',
            size: 'small',
            type: 'submit',
            title: 'Yes',
            result: true,
          },
        ]}
        {...displayProps}
      />
    </ConfirmCompanyChangeContext.Provider>
  );
};

export default ConfirmCompanyChangeProvider;
