import type { ApolloAppClient } from './apollo.types';
import {
  DateResolver,
  TimeResolver,
  DateTimeResolver,
  TimestampResolver,
  UtcOffsetResolver,
  DurationResolver,
  LocalDateResolver,
  LocalTimeResolver,
  LocalEndTimeResolver,
  EmailAddressResolver,
  NegativeFloatResolver,
  NegativeIntResolver,
  NonEmptyStringResolver,
  PhoneNumberResolver,
  PositiveFloatResolver,
  PositiveIntResolver,
  PostalCodeResolver,
  URLResolver,
  UUIDResolver,
  LatitudeResolver,
  LongitudeResolver,
  USCurrencyResolver,
  CurrencyResolver,
  JSONResolver,
  JSONObjectResolver,
  CountryCodeResolver,
  LocaleResolver,
  RoutingNumberResolver,
  AccountNumberResolver,
} from 'graphql-scalars';

export function addApolloState(client: ApolloAppClient, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props.apolloState = client.cache.extract();
  }

  return pageProps;
}

export const typesMap = {
  Date: DateResolver,
  Time: TimeResolver,
  DateTime: DateTimeResolver,
  Timestamp: TimestampResolver,
  UtcOffset: UtcOffsetResolver,
  Duration: DurationResolver,
  LocalDate: LocalDateResolver,
  LocalTime: LocalTimeResolver,
  LocalEndTime: LocalEndTimeResolver,
  EmailAddress: EmailAddressResolver,
  NegativeFloat: NegativeFloatResolver,
  NegativeInt: NegativeIntResolver,
  NonEmptyString: NonEmptyStringResolver,
  PhoneNumber: PhoneNumberResolver,
  PositiveFloat: PositiveFloatResolver,
  PositiveInt: PositiveIntResolver,
  PostalCode: PostalCodeResolver,
  URL: URLResolver,
  UUID: UUIDResolver,
  Latitude: LatitudeResolver,
  Longitude: LongitudeResolver,
  USCurrency: USCurrencyResolver,
  Currency: CurrencyResolver,
  JSON: JSONResolver,
  JSONObject: JSONObjectResolver,
  CountryCode: CountryCodeResolver,
  Locale: LocaleResolver,
  RoutingNumber: RoutingNumberResolver,
  AccountNumber: AccountNumberResolver,
} as const;
