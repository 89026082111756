import type { TextColor } from '../Text';

type Args = {
  disabled?: boolean;
  isFocused?: boolean;
  isInvalid?: boolean;
};

export default function getInputTextColorName({
  disabled = false,
  isFocused = false,
  isInvalid = false,
}: Args): TextColor {
  if (disabled) return 'gray';

  if (isInvalid) return 'error';

  if (isFocused) return 'selected';

  return 'default';
}
