import { Comparer, getAnyComparer } from './compare';
import type { DeepPartial } from './types';
import { assertIsTrue, isPlainObject } from './validation';

export type NodeVisited = {
  /**
   * The property name
   */
  key: string;
  /**
   * True if this is a leaf node, e.g. has no regular properties
   */
  isLeaf: boolean;
  /**
   * If true, this node has references to a parent of itself
   */
  isCircularRef: boolean;
  /**
   * The value of the node
   */
  value: any;
  /**
   * Reference to parent
   */
  parent: NodeVisited | undefined;
  /**
   * Array represent path to this node from the object root, e.g. ["a","b","c"]
   * for { a: { b: { c: "" }}}
   */
  path: string[];
  /**
   * Replace the value of this node
   */
  setValue: (value: any) => void;
  /**
   * Will prevent traversal of children of this node
   */
  stopTraversingNode: () => void;
};
/**
 * Traverse all nodes of an object calling back for each node.
 */
export function traverseObject<T extends object>(
  obj: T,
  callback: (node: NodeVisited) => void,
  path?: string[],
  cache?: Set<any>,
  parent?: NodeVisited,
) {
  const circularCache = cache || new Set();
  Object.entries(obj).forEach(([key, value]) => {
    const newPath = path === undefined ? [key] : [...path, key];
    const isLeaf = !isPlainObject(value);
    const isCircularRef = !isLeaf && circularCache.has(value);
    let stopTraversing = false;
    const nodeVisited = {
      key,
      isLeaf,
      isCircularRef,
      value,
      parent,
      path: newPath,
      // TODO: This doesn't make much sense here since using it would
      // cause this to fail.
      setValue: (newValue: any) => {
        (obj as any)[key] = newValue;
      },
      stopTraversingNode: () => {
        stopTraversing = true;
      },
    };

    callback(nodeVisited);
    if (!isLeaf && !isCircularRef && !stopTraversing) {
      circularCache.add(obj);
      traverseObject(value, callback, newPath, cache, nodeVisited);
    }
  });
}

/**
 * Given an array of strings representing a path through nodes in an object,
 * set a value at the target of that path.
 * If overwriteBranch is false or not specified, the function will throw an error if
 * an attempt is made to overwrite a branch with children
 */
export function setRecursive(
  obj: any,
  nodePath: string[],
  value: any,
  overwriteBranch = false,
) {
  assertIsTrue(
    nodePath.length > 0,
    'You must provide nodePath with at least one element',
  );
  const parents = nodePath.slice(0, nodePath.length - 1);
  let currentNode = obj;
  parents.forEach((parent) => {
    // eslint-disable-next-line no-prototype-builtins
    if (currentNode.hasOwnProperty(parent)) {
      if (!isPlainObject(currentNode[parent])) {
        throw new Error(
          `The path "${nodePath.join(
            '.',
          )}" is incompatible with existing nodes in the object.`,
        );
      }
      currentNode = currentNode[parent];
      return;
    }
    currentNode[parent] = {};
    currentNode = currentNode[parent];
  });

  const lastNode = nodePath[nodePath.length - 1];
  if (!overwriteBranch) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      currentNode.hasOwnProperty(lastNode) &&
      isPlainObject(currentNode[lastNode])
    ) {
      throw new Error(
        `The path "${nodePath.join('.')}" already exists with children.`,
      );
    }
  }

  currentNode[lastNode] = value;
}

/**
 * Given an array of strings representing a path through nodes in an object,
 * return the value at the target of that path
 */
export function getRecursive(obj: any, nodePath: string[]) {
  assertIsTrue(
    nodePath.length > 0,
    'You must provide nodePath with at least one element',
  );
  const parents = nodePath.slice(0, nodePath.length - 1);
  let currentNode = obj;
  parents.forEach((parent) => {
    if (currentNode) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        currentNode.hasOwnProperty(parent) &&
        isPlainObject(currentNode[parent])
      ) {
        currentNode = currentNode[parent];
        return;
      }
      currentNode[parent] = {};
      currentNode = currentNode[parent];
    }
  });

  const lastNode = nodePath[nodePath.length - 1];

  return currentNode ? currentNode[lastNode] : undefined;
}

/**
 * Return an object containing only properties that are changed in obj2 compared
 * to obj1. Arrays are always ignored!
 */
export function getObjectDiff<T extends object>(
  obj1: Partial<T>,
  obj2: Partial<T>,
  options: { comparer?: Comparer<object> } = {},
): Partial<T> {
  const out: Partial<T> = {};

  const comparer = options.comparer ?? getAnyComparer();
  // delete things in obj1 but not obj2
  traverseObject(obj1, (node) => {
    const currentValue = getRecursive(obj2, node.path);

    if (node.value !== undefined && currentValue === undefined) {
      setRecursive(out, node.path, undefined);
      // don't continue traversing
      if (!node.isLeaf) {
        node.stopTraversingNode();
      }
    }
  });

  // add things in obj2 and not in obj1
  traverseObject(obj2, (node) => {
    if (!node.isLeaf) {
      return;
    }
    const oldValue = getRecursive(obj1, node.path);
    if (!Array.isArray(node.value) && comparer(node.value, oldValue) !== 0) {
      setRecursive(out, node.path, node.value);
    }
  });
  return out;
}

/**
 * Given an object, and another object that's a DeepPartial of the first one,
 * return only the properties of the first object, that appear on the 2nd.
 */
export function filterObject<T>(
  obj1: T,
  obj2: object,
): T extends DeepPartial<T> ? T : DeepPartial<T> {
  const out: DeepPartial<T> = {};

  traverseObject(obj2, (node) => {
    const value = getRecursive(obj1, node.path);
    if (node.isLeaf) {
      setRecursive(out, node.path, value);
    }
  });
  return out as any;
}

/**
 * Like traverseObject, but does not mutate the original object; calling "set" on
 * the visitor will set the node in the target object.
 */
export function mapObject<T extends object>(
  obj: T,
  callback: (node: NodeVisited) => void,
): T {
  const out: object = {};

  traverseObject(obj, (node) => {
    if (node.isLeaf) {
      callback({
        ...node,
        setValue: (newValue: any) => {
          setRecursive(out, node.path, newValue);
        },
      });
    }
  });
  return out as T;
}

/**
 * A merge, using the same node/leaf rules as traverseObject. Arrays are treated as leaf nodes
 * and will not be merged.
 */
export function deepAssign<T extends object>(obj1: T, obj2: Partial<T>): T {
  traverseObject(obj2, (node) => {
    if (node.isLeaf) {
      setRecursive(obj1, node.path, node.value);
    }
  });
  return obj1;
}
