import { Role } from '@homee/types-api-v2';

export enum CompanyRole {
  ProTech = Role.ProTech,
  ProDispatcher = Role.ProDispatcher,
  ProAdmin = Role.ProAdmin,
}

export enum UserRole {
  Consumer = Role.Consumer,
  Provider = Role.Provider,
  Internal = Role.Internal,
  ClaimsInternal = Role.ClaimsInternal,
  ClaimsAdjuster = Role.ClaimsAdjuster,
}

export enum LanguageCodes {
  English = 'en',
  French = 'fr',
}

export type LanguageCode = `${LanguageCodes}`;
