export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
      ? ReadonlyArray<DeepPartial<U>>
      : DeepPartial<T[P]>;
};

/**
 * Any class constructor
 */
export interface Constructor<T = object, TArgs extends any[] = any[]> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: TArgs): T;
}

/**
 * Properly typed Object.entries
 */
export type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][];

/**
 * Given an object TObject, get the type of the value of a specific key TKey
 */
export type TypeOfKey<
  TObject extends Record<string, unknown>,
  TKey extends keyof TObject,
> = {
  [K in keyof TObject]: TObject[K];
}[TKey];

/**
 * Given a branded type (a type that extends String); convert to just string
 */
export type Standardize<T> = {
  [K in keyof T]: T[K] extends string
    ? string
    : // eslint-disable-next-line @typescript-eslint/ban-types
      T[K] extends String
      ? // eslint-disable-next-line @typescript-eslint/ban-types
        String
      : T[K] extends Date
        ? Date
        : T[K] extends object
          ? Standardize<T[K]>
          : T[K];
};

/**
 * Represents a type that can be a value or a function that returns a value
 */
export type MaybeFunc<T> = T | (() => T);

export type MaybePromise<T> = T | Promise<T>;

export type MaybePromiseFunc<T> = MaybeFunc<MaybePromise<T>>;

export type NonFunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

/**
 * Removes all fields that are of a specific type from the given type
 */
export type ExcludeFields<TBase, TExclude> = {
  [K in keyof TBase as TBase[K] extends TExclude ? never : K]: TBase[K];
};

/**
 * Removes all fields that are undefined or null from the given type
 */
export type ExcludeUndefinedOrNull<TBase> = {
  [K in keyof TBase as undefined | null | never extends TBase[K]
    ? never
    : K]: TBase[K];
};

/**
 * Given an Array of type TArray (e.g. string[]), get the type of the array element (e.g. string)
 */
export type ArrayElement<TArray> = TArray extends (infer T)[] ? T : never;

/**
 * Represents the type of object when using the enum keyword e.g. ```enum MyEnum { A = 1, B = 2 }```
 */
export type Enum<T = number | string> = {
  [id: string]: T | string;
  [nu: number]: string;
};

/**
 * Represents the types of an enum's values e.g. for ```enum MyEnum { A = 1, B = 'two' }```, the EnumValue<typeof MyEnum> is ```1 | 'two'```
 */
export type EnumValue<TEnum extends Enum> =
  TEnum extends Enum<infer TEnumValue>
    ? TEnumValue extends string
      ? `${TEnumValue}`
      : TEnumValue
    : never;

/**
 * Type representing the enum or one of its string values. Useful for allowing assigning inputs from strings
 */
export type EnumOrString<TEnum extends string> = TEnum | `${TEnum}`;

/**
 * Given a type or Array of type TArray (e.g. string | string[]), get the type of the array element or type (e.g. string)
 */
export type MaybeArrayElement<T> = T extends (infer U)[] ? U : T;

/**
 * Returns the union of all values in a given object
 */
export type ValueUnion<T extends object> = T[keyof T];

export type Nullable<T> = T | null;

export type NullToUndefined<T> = {
  [K in keyof T]: T[K] extends Nullable<T[K]> ? Exclude<T[K], null> : T[K];
};

export type MaybeNullToUndefined<T> = T extends undefined | null
  ? NullToUndefined<T> | undefined
  : NullToUndefined<T>;

export type JsonValue = string | number | boolean | JSONObject | JsonArray;

export type JSONObject = {
  [x: string]: JsonValue;
};

export type JsonArray = Array<JsonValue>;

export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

/**
 * Assert that a type extends true, or error
 * */
export type Expect<_T extends true> = never;

/**
 * Returns a type that includes only the fields of T that extend U
 */
export type Include<T = any, U = unknown> = {
  [K in keyof T as T[K] extends U ? K : never]: T[K];
};

export type ExcludeUndefinedKeys<T> = {
  [K in keyof T]-?: Exclude<T[K], undefined> extends never ? never : K;
}[keyof T];

/**
 * Returns a type that excludes all undefined fields, and requires all non-undefined fields
 */
export type RequireNonUndefined<T> = Required<Pick<T, ExcludeUndefinedKeys<T>>>;

type _ScalarField = string | number | boolean | Date;

/**
 * Given a type T, returns a union of all possible paths to fields in T
 * Example:
 * ```ts
 * type MyType = { foo: { bar: string; baz: number }, bar: string };
 * type TPaths = Paths<MyType>; // 'foo' | foo.bar' | 'foo.baz' | 'bar'
 * ```
 *
 * Note: Be careful with this type, it can be quite slow for large types
 */
export type Paths<T> = {
  [K in Exclude<keyof T, symbol>]: T[K] extends _ScalarField
    ? `${K}`
    : NonNullable<T[K]> extends (infer E)[]
      ? `${K}` | `${K}.${Paths<NonNullable<E>>}`
      : NonNullable<T[K]> extends object
        ? `${K}` | `${K}.${Paths<NonNullable<T[K]>>}`
        : // eslint-disable-next-line @typescript-eslint/ban-types
          T[K] extends Function
          ? never
          : `${K}`;
}[Exclude<keyof T, symbol>];

/**
 * Given a type T and a dot path from Paths<T> (e.g. 'foo.bar'),
 * returns the type of the field at that path
 * Example:
 * ```ts
 * type MyType = { foo: { bar: string; baz: number }, bar: string };
 * type V1 = PickByPath<MyType, 'foo.baz'>; // number
 * type V1 = PickByPath<MyType, 'foo.baz'>; // number
 * ```
 *
 * Note: Be careful with this type, it can be quite slow for large types
 */
export type PickByPath<
  TObject,
  TPath extends Paths<TObject>,
> = _PickByPathString<TObject, TPath>;

type _PickByPathString<T, TPath extends string> = T extends null
  ? _PickByPathNonNullable<T, TPath> | null
  : undefined extends T
    ? _PickByPathNonNullable<T, TPath> | undefined
    : T extends (infer E)[]
      ? _PickByPathNonNullable<E, TPath>[]
      : _PickByPathNonNullable<T, TPath>;

type _PickByPathNonNullable<T, TPath extends string> =
  NonNullable<T> extends infer TNonNullable
    ? // Constraining TKey so we don't need to check if its keyof TObject
      TPath extends `${infer TKey extends keyof TNonNullable &
        string}.${infer TRest}`
      ? _PickByPathString<TNonNullable[TKey], TRest>
      : TPath extends keyof TNonNullable
        ? TNonNullable[TPath]
        : never
    : never;
