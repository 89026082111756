import { StatusCodes } from 'http-status-codes';
import { isNumber } from '../utils/validation';
import { ValidationError } from './validation.error';
import { HomeeError } from './homee.error';

export function getErrorStatusCode(err: unknown): number {
  const anyError = err as any;
  const maybeStatusCode = anyError?.statusCode ?? anyError?.status;

  if (isNumber(maybeStatusCode)) {
    return maybeStatusCode;
  }

  if (err instanceof ValidationError) {
    return StatusCodes.BAD_REQUEST;
  }

  return StatusCodes.INTERNAL_SERVER_ERROR;
}

export type GetErrorMessageOptions = {
  defaultErrorMessage?: string;
  useDefaultMessageForErrorInstances?: boolean;
  useErrorDetailsForKnownErrors?: boolean;
};

export function getErrorMessage(
  err: unknown,
  options: GetErrorMessageOptions = {},
): string {
  const {
    useDefaultMessageForErrorInstances,
    defaultErrorMessage = 'Unknown error',
  } = options;

  let message: string | undefined = undefined;

  if (
    err instanceof HomeeError ||
    (err instanceof Error && !useDefaultMessageForErrorInstances)
  ) {
    message = err.message;
  }

  message = message || defaultErrorMessage;

  return message;
}
