// For date/time formatting, stick to Material Design guidelines
// unless a "Homee Rule" supersedes it.
//
// Material Guidelines:
// https://material.io/design/communication/data-formats.html#date-and-time
//
// Homee Guidelines:
// https://zeroheight.com/83195d329/p/060364-grammar--mechanics/t/08e4e2

/**
 * @external https://charbase.com/2013-unicode-en-dash
 */
export const EN_DASH = '\u2013';

/**
 * @example "2020-12-07"
 */
export const API_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * @example "Dec 7, 2020"
 */
export const DATE_FORMAT = 'll';

/**
 * @example "Dec 7"
 */
export const DATE_FORMAT_NO_YEAR = 'MMM D';

/**
 * Equivalent of h:mm A
 *
 * @example "8:59 AM"
 */
export const TIME_FORMAT = 'LT';

/**
 * @example "Fri, Dec 7"
 */
export const DATE_SHORT_FORMAT = 'ddd, MMM D';

/**
 * @example "12/07/2020"
 */
export const DATE_NUMERIC_FORMAT = 'L';

/**
 * @example "Friday, December 07"
 */
export const DAY_MONTH_DATE_FORMAT = 'dddd, MMMM D';

/**
 * @example "Dec 7"
 */
export const MONTH_DATE_FORMAT = 'MMM D';

/**
 * @example "Fri"
 */
export const DAY_SHORT_FORMAT = 'ddd';

/**
 * @example "1360013296"
 */
export const UNIX_TIMESTAMP_FORMAT = 'X';
