import React, { SyntheticEvent, useCallback, useContext, useMemo } from 'react';
import moment from 'moment';
import {
  Icon,
  Text,
  COLOR,
  SPACING,
  IconButton,
} from '@homee/ui-web-presentation';
import { formatDateTime } from '@homee/util-common';
import {
  useNotificationDeleteMutation,
  useNotificationReadMutation,
  NotificationFragment,
} from '../../lib/generated/types';
import { toastError, toastSuccess } from '../../components/Toaster';
import {
  RefetchActionType,
  RefetchContext,
} from '../../components/RefetchProvider';

type Props = {
  notification: NotificationFragment;
  showDelete?: boolean;
  onClick?: (notification: NotificationFragment) => void;
};

const Notification: React.FC<Props> = ({
  notification,
  showDelete = true,
  onClick,
}: Props) => {
  const isReadStatus = useMemo(() => !!notification.readAt, [notification]);

  const href = notification.url;

  const { dispatch: refetchDispatch } = useContext(RefetchContext);

  const [notificationDelete] = useNotificationDeleteMutation();
  const [notificationRead] = useNotificationReadMutation();
  const handleDelete = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await notificationDelete({ variables: { id: notification.id } });
      toastSuccess('Notification Deleted.');
      refetchDispatch({ type: RefetchActionType.Changed });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      toastError(e, { defaultMessage: 'Error deleting notification.' });
    }
  };

  const handleRowClick = async (
    e:
      | React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>
      | React.KeyboardEvent<HTMLAnchorElement | HTMLSpanElement>,
  ) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();

    if (
      (e as React.KeyboardEvent).key &&
      (e as React.KeyboardEvent).key !== 'Enter'
    ) {
      return;
    }

    if (!isReadStatus) {
      try {
        await notificationRead({ variables: { id: notification.id } });
        refetchDispatch({ type: RefetchActionType.Changed });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        toastError(e, { defaultMessage: 'Error reading notification.' });
      }
    }

    onClick?.(notification);
  };

  const contents = (
    <>
      <div className="read-icon">
        <Icon
          size={17}
          icon="fiberManualRecord"
          color={isReadStatus ? COLOR.mediumGray : COLOR.blue}
        />
      </div>
      <div className="descriptions">
        <Text
          className="description"
          size="small"
          weight={isReadStatus ? 'regular' : 'bold'}
        >
          {notification.message}
        </Text>
        <Text size="small" color="gray">
          {formatDateTime(moment(notification.createdAt))}
        </Text>
      </div>
      {showDelete && (
        <div className="delete">
          <IconButton
            width={40}
            height={23}
            glyph="delete"
            label="Delete Notification"
            color={COLOR.mediumGray}
            onClick={handleDelete}
          />
        </div>
      )}
    </>
  );

  return (
    <li className="notification-item">
      {href ? (
        <a
          href={href}
          role="button"
          onClick={handleRowClick}
          onKeyPress={handleRowClick}
        >
          {contents}
        </a>
      ) : (
        <span onClick={handleRowClick} onKeyPress={handleRowClick}>
          {contents}
        </span>
      )}
      <style jsx>
        {`
          .notification-item {
            border-bottom: 1px solid ${COLOR.lightGray};
            list-style: none;
            width: 100%;
            height: 80px;
          }
          .notification-item :global(a),
          .notification-item :global(span) {
            text-decoration: none;
            display: flex;
            padding: ${SPACING.sm}px 0;
            height: 100%;
          }
          .notification-item :global(a:hover),
          .notification-item :global(a:focus),
          .notification-item :global(span:hover),
          .notification-item :global(span:focus) {
            background: #fafafa;
          }
          .notification-item :global(.descriptions) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            max-height: 48px;
            display: flex;
            flex: 1;
            overflow: hidden;
          }
          .notification-item :global(.descriptions p.description) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .notification-item :global(.read-icon) {
            padding: 7px ${SPACING.sm}px;
          }
          .notification-item :global(.delete) {
            margin-top: 7px;
          }
        `}
      </style>
    </li>
  );
};

export default Notification;
