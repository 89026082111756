import React from 'react';
import {
  FONT_SIZE,
  LINE_HEIGHT,
  FONT_WEIGHT,
  LETTER_SPACING,
  COLOR,
} from './theme';

const variants = {
  h1: {
    fontSize: FONT_SIZE.s40,
    lineHeight: LINE_HEIGHT.s40,
    letterSpacing: LETTER_SPACING.md,
    fontWeight: FONT_WEIGHT.heading,
  },
  h2: {
    fontSize: FONT_SIZE.s40,
    lineHeight: LINE_HEIGHT.s40,
    letterSpacing: LETTER_SPACING.md,
    fontWeight: FONT_WEIGHT.heading,
  },
  h3: {
    fontSize: FONT_SIZE.s32,
    lineHeight: LINE_HEIGHT.s32,
    letterSpacing: LETTER_SPACING.sm,
    fontWeight: FONT_WEIGHT.heading,
  },
  h4: {
    fontSize: FONT_SIZE.s24,
    lineHeight: LINE_HEIGHT.s24,
    letterSpacing: LETTER_SPACING.sm,
    fontWeight: FONT_WEIGHT.bold,
  },
  h5: {
    fontSize: FONT_SIZE.s18,
    lineHeight: LINE_HEIGHT.s18,
    letterSpacing: LETTER_SPACING.xs,
    fontWeight: FONT_WEIGHT.bold,
  },
  h6: {
    fontSize: FONT_SIZE.s13,
    lineHeight: LINE_HEIGHT.s13,
    letterSpacing: LETTER_SPACING.lg,
    fontWeight: FONT_WEIGHT.heading,
  },
} as const;

export type HeadingVariant = keyof typeof variants;

const colors = {
  default: {
    light: COLOR.black,
    dark: COLOR.white,
  },
  gray: {
    light: COLOR.darkGray,
    dark: COLOR.softGray,
  },
  selected: {
    light: COLOR.blue,
    dark: COLOR.blue,
  },
  success: {
    light: COLOR.green,
    dark: COLOR.green,
  },
  error: {
    light: COLOR.red,
    dark: COLOR.red,
  },
  link: {
    light: COLOR.blue,
    dark: COLOR.blue,
  },
  disabled: {
    light: COLOR.lightGray,
    dark: COLOR.softGray,
  },
} as const;

type Props = {
  color?: keyof typeof colors;
  element?: React.ElementType;
  onDark?: boolean;
  variant?: HeadingVariant;
} & React.HtmlHTMLAttributes<HTMLElement>;

export const Heading: React.FC<Props> = ({
  children,
  color = 'default',
  element,
  onDark = false,
  style = {},
  variant = 'h1',
  ...props
}) => {
  const type = element || variant || 'h1';

  const textStyle = {
    fontSize: variants[variant].fontSize,
    lineHeight: variants[variant].lineHeight,
    fontWeight: variants[variant].fontWeight,
    letterSpacing: variants[variant].letterSpacing,
    color: onDark ? colors[color].dark : colors[color].light,
    textTransform: variant === 'h6' ? 'uppercase' : 'none',
  };
  const mergedStyles = { ...textStyle, ...style };

  return React.createElement(
    type,
    {
      style: mergedStyles,
      ...props,
    },
    children,
  );
};

export default Heading;
