import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  API_DATE_FORMAT,
  DATE_FORMAT,
  DATE_FORMAT_NO_YEAR,
  DATE_NUMERIC_FORMAT,
  DATE_SHORT_FORMAT,
  DAY_MONTH_DATE_FORMAT,
  DAY_SHORT_FORMAT,
  EN_DASH,
  MONTH_DATE_FORMAT,
  TIME_FORMAT,
  UNIX_TIMESTAMP_FORMAT,
} from './date.constants';

dayjs.extend(localizedFormat);

type DateInputType = string | number | Date | Dayjs | null | undefined;

export const DateFormat = {
  /**
   *
   * @param {DateInputType} start - The date instance that holds the range's start time
   * @param {DateInputType} end - The date instance that holds the range's end time
   * @return {string} Formatted date range, example: "8:30 AM-9:30 AM"
   */
  hourRange(start: DateInputType, end: DateInputType): string {
    const startString = DateFormat.hourMinute(start);
    const endString = DateFormat.hourMinute(end);

    return `${startString}${EN_DASH}${endString}`;
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @return {string} Formatted date, example: "Dec 7, 2020"
   */
  date(date: DateInputType): string {
    date = dayjs(date);

    return date.format(DATE_FORMAT);
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @param {boolean} includeYear - Whether to include the year in the formatted date if the date is the current year
   * @return {string} Formatted date, example: "Dec 7, 2020, 8:59 PM" or "Dec 7, 8:59 PM"
   */
  dateTime(date: DateInputType, includeYearIfCurrent: boolean): string {
    date = dayjs(date);

    const time = DateFormat.hourMinute(date);

    if (!includeYearIfCurrent && date.year() === dayjs().year()) {
      return date.format(`${DATE_FORMAT_NO_YEAR}, ${time}`);
    }

    const dateString = DateFormat.date(date);

    return `${dateString}, ${time}`;
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @return {string} Formatted numeric date, example: "12/7/2020"
   */
  dateNumeric(date: DateInputType): string {
    date = dayjs(date);

    return date.format(DATE_NUMERIC_FORMAT);
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @return {string} Formatted numeric date time, example: "12/7/2020, 8:59 PM"
   */
  dateTimeNumeric(date: DateInputType): string {
    date = dayjs(date);

    const time = DateFormat.hourMinute(date);
    const dateString = DateFormat.dateNumeric(date);

    return date.format(`${dateString}, ${time}`);
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @return {string} Formatted date, example: "Fri, Dec 7"
   */
  shortDayMonthDate(date: DateInputType): string {
    date = dayjs(date);

    return date.format(DATE_SHORT_FORMAT);
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @return {string} Formatted date, example: "Friday, December 07"
   */
  dayMonthDate(date: DateInputType): string {
    date = dayjs(date);

    return date.format(DAY_MONTH_DATE_FORMAT);
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @return {string} Formatted date, example: "Fri"
   */
  shortDay(date: DateInputType): string {
    date = dayjs(date);

    return date.format(DAY_SHORT_FORMAT);
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @return {string} Formatted date, example: "8:59 AM"
   */
  hourMinute(date: DateInputType): string {
    date = dayjs(date);

    return date.format(TIME_FORMAT);
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @return {string} Formatted Unix timestamp, example: "1360013296"
   */
  timestamp(date: DateInputType): string {
    date = dayjs(date);

    return date.format(UNIX_TIMESTAMP_FORMAT);
  },

  /**
   *
   * @param {DateInputType} date - The date instance to format
   * @return {string} Formatted date, example: "Dec 7"
   */
  monthDate(date: DateInputType): string {
    date = dayjs(date);

    return date.format(MONTH_DATE_FORMAT);
  },

  /**
   *
   * @param {DateInputType} date - The date to format
   * @param {DateInputType | undefined} today - The optional current day
   * @return {string} Formatted date, examples: "Today", "Tomorrow", "Yesterday", "12/7/2020"
   */
  descriptiveDate(date: DateInputType, today?: DateInputType): string {
    date = dayjs(date);
    today = today ? dayjs(today) : dayjs();

    if (date.isSame(today, 'day')) {
      return 'Today';
    }

    if (date.isSame(today.clone().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    }

    if (date.isSame(today.clone().add(1, 'day'), 'day')) {
      return 'Tomorrow';
    }

    return date.format('L');
  },

  /**
   *
   * @param date  - The date instance to format
   * @returns  - Formatted time, example: "59m ago", "2h ago", "3d ago"
   */
  timeAgo(date: DateInputType): string {
    date = dayjs(date);
    const diffInMinutes = Math.floor(
      (dayjs().valueOf() - date.valueOf()) / 60000,
    );
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`;
    }
    if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    }
    return `${diffInDays}d ago`;
  },
  /**
   * Condense into these basic formats when someone picks up ticket
   *
   * date
   * dateTime
   * duration
   */
};
