import { Role } from '@homee/pro-portal/types-api';
import {
  BREAKPOINT_VALUE,
  COLOR,
  Icon,
  LINE_HEIGHT,
  SPACING,
  Text,
  Z_INDEX,
} from '@homee/ui-web-presentation';
import Link from 'next/link';
import React, { useCallback, useEffect } from 'react';
import AccessAllowed from '../../../components/AccessAllowed';
import { PageRoles } from '../../../lib/types/next';
import ChildNavItem from './ChildNavItem';
import NavItem from './NavItem';
import Image from 'next/image';
import { signOut } from 'next-auth/react';

type Props = {
  setIsMobileNavOpen: (boolean) => void | Promise<void>;
};

const MainNav: React.FC<Props> = ({ setIsMobileNavOpen }) => {
  const [isNavCollapsed, setIsNavCollapsed] = React.useState<boolean>(false);

  useEffect(() => {
    if (!document.body.className.includes('nav-state')) {
      document.body.className += `nav-state-open`;
    }
  }, []);

  const handleNavToggle = useCallback(
    (collapsed) => {
      setIsNavCollapsed(collapsed);
      document.body.className = document.body.className.replace(
        /nav-state-[^ ]+/,
        `nav-state-${!collapsed ? 'open' : 'closed'}`,
      );
    },
    [setIsNavCollapsed],
  );

  const mobileCloseNav = () => {
    void setIsMobileNavOpen(false);
  };

  return (
    <div className="global-nav">
      <div className="mobile-header">
        <button
          className="exit"
          type="button"
          onClick={() => setIsMobileNavOpen((prev) => !prev)}
        >
          <Icon icon="close" />
        </button>
        <Link href="/jobs" aria-label="HOMEE" className="home">
          <Image
            src="/sidebar-logo-2023.svg"
            alt="HOMEE PRO PORTAL"
            width="130"
            height="130"
          />
        </Link>
      </div>
      <nav className={isNavCollapsed ? 'collapsed' : undefined}>
        <div className="inner">
          <Link href="/">
            <div className="sidebar-mask">
              <div className="sidebar-logo">
                {isNavCollapsed ? (
                  <Image
                    src="/collapsed-logo.svg"
                    alt="HOMEE PRO PORTAL"
                    width="26"
                    height="33"
                    priority
                  />
                ) : (
                  <Image
                    src="/sidebar-logo-2023.svg"
                    alt="HOMEE PRO PORTAL"
                    width="130"
                    height="130"
                    priority
                  />
                )}
              </div>
            </div>
          </Link>
          <div className="trigger-collapse">
            <button
              type="button"
              onClick={() => handleNavToggle(!isNavCollapsed)}
            >
              <figure className="icon">
                <Icon icon="arrowBack" />
              </figure>
              <Text element="span">
                {isNavCollapsed ? 'Show Menu' : 'Hide Menu'}
              </Text>
            </button>
          </div>
          <ul className="main">
            {/* <AccessAllowed roles={[PageRoles.Any]}>
              <NavItem
                className="dashboard"
                label="Dashboard"
                href="/dashboard"
                icon="statusOnSite"
                isNavCollapsed={isNavCollapsed}
                onClick={mobileCloseNav}
              />
            </AccessAllowed> */}
            <AccessAllowed roles={[PageRoles.Any]}>
              <NavItem
                className="jobs"
                label="Jobs"
                href="/jobs"
                icon="assignmentTurnedIn"
                isNavCollapsed={isNavCollapsed}
                onClick={mobileCloseNav}
              />
            </AccessAllowed>
            {/* <AccessAllowed roles={[Roles.ProAdmin, Roles.ProDispatcher]}>
              <NavItem
                className="financials"
                label="Financials"
                href="/financials"
                icon="trendingUp"
                isNavCollapsed={isNavCollapsed}
                onClick={mobileCloseNav}
              />
            </AccessAllowed> */}
            {/* <AccessAllowed roles={[Roles.ProAdmin]}>
              <NavItem
                className="reporting"
                label="Reporting"
                href="/reporting"
                icon="statusRequest"
                isNavCollapsed={isNavCollapsed}
                onClick={mobileCloseNav}
              />
            </AccessAllowed> */}
            <AccessAllowed roles={[Role.ProAdmin, Role.ProDispatcher]}>
              <NavItem
                className="company"
                label="Company"
                href="/company"
                icon="accountBalance"
                isNavCollapsed={isNavCollapsed}
                onClick={mobileCloseNav}
              >
                <ChildNavItem
                  label="Company Profile"
                  href="/company"
                  onClick={mobileCloseNav}
                />
                <ChildNavItem
                  label="Employees List"
                  href="/company/employees-list"
                  onClick={mobileCloseNav}
                />
              </NavItem>
            </AccessAllowed>
          </ul>
          <ul className="account">
            <AccessAllowed roles={[PageRoles.Any]}>
              <NavItem
                className="help"
                label="Help"
                href="/help"
                icon="chat"
                isNavCollapsed={isNavCollapsed}
                onClick={mobileCloseNav}
              />
            </AccessAllowed>
            <AccessAllowed roles={[PageRoles.Any]}>
              <NavItem
                label="Logout"
                href="/auth/signout"
                icon="logout"
                isNavCollapsed={isNavCollapsed}
                onClick={async (e) => {
                  e.preventDefault();
                  mobileCloseNav();
                  await signOut();
                }}
              />
            </AccessAllowed>
          </ul>
        </div>
      </nav>
      <style jsx>
        {`
          .global-nav {
            background-color: ${COLOR.brandNavy};
            box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12);
            width: auto;
            height: 100vh;
            position: relative;
            z-index: 100;
          }

          .global-nav > nav {
            background-color: ${COLOR.brandNavy};
            width: 230px;
            position: relative;
            height: 100%;
          }
          .global-nav > nav > .inner {
            background-color: ${COLOR.brandNavy};
            width: 230px;
            display: flex;
            position: fixed;
            flex-direction: column;
            height: 100%;
          }
          .global-nav > nav ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }

          .global-nav
            > nav
            ul
            :global(li.expanded > .parent-item:not(.active) figure) {
            color: ${COLOR.white};
          }

          nav.collapsed .sidebar-mask {
            overflow: hidden;
          }

          ul.account {
            margin: auto 0 ${SPACING.sm}px 0 !important;
          }

          /* Mobile nav overlay header */
          .mobile-header {
            display: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            width: 100%;
            margin-top: ${SPACING.md}px;
          }

          .mobile-header :global(.home) :global(svg) {
            color: ${COLOR.brandRed};
          }

          .mobile-header .exit {
            position: absolute;
            top: ${SPACING.xs}px;
            right: ${SPACING.xxs}px;
            background: none;
            border: none;
            padding: ${SPACING.sm}px;
            color: ${COLOR.white};
            display: flex;
            align-items: center;
          }

          .mobile-header .exit :global(svg) {
            width: ${SPACING.md}px;
            height: ${SPACING.md}px;
          }

          .global-nav .sidebar-logo {
            padding: ${SPACING.md}px ${SPACING.sm}px ${SPACING.xs}px
              ${SPACING.sm}px;
            cursor: pointer;
          }

          /* Trigger to Collapse Nav */
          .trigger-collapse {
            padding: ${SPACING.sm}px;
          }

          .trigger-collapse > button {
            border: none;
            background: none;
            padding: 0;
            margin: ${isNavCollapsed ? 'auto' : '0'};
            display: flex;
            align-items: center;
            white-space: nowrap;
          }

          .trigger-collapse > button :global(span) {
            line-height: ${LINE_HEIGHT.s24} !important;
            color: ${COLOR.softGray} !important;
          }

          .trigger-collapse > button:hover :global(span) {
            filter: brightness(85%);
          }

          .trigger-collapse > button .icon {
            background: rgba(151, 151, 151, 0.5);
            border-radius: 100%;
            width: 23px;
            height: 23px;
            margin: ${isNavCollapsed ? 'auto' : `0 11px 0 5px`};
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
          }

          .trigger-collapse > button :global(svg) {
            color: ${COLOR.softGray};
          }

          .trigger-collapse > button:hover :global(svg) {
            filter: brightness(85%);
          }

          /* Only apply collapsed styles to desktop */
          @media (min-width: ${BREAKPOINT_VALUE.sm - 0.02}px) {
            .sidebar-logo {
              display: flex;
              justify-content: center;
            }

            .global-nav > nav.collapsed,
            .global-nav > nav.collapsed .inner {
              width: 64px;
            }

            .global-nav > nav.collapsed .trigger-collapse button :global(svg) {
              transform: rotate(180deg);
            }

            .global-nav > nav.collapsed :global(ul.expanded) {
              display: none;
            }

            .global-nav > nav.collapsed :global(.parent-item) {
              justify-content: center;
            }

            .global-nav > nav.collapsed :global(.parent-item span),
            .global-nav > nav.collapsed :global(.parent-item .accordion-arrow),
            .global-nav > nav.collapsed .trigger-collapse button :global(span) {
              display: none;
            }
          }

          /* Mobile Nav */
          @media (max-width: ${BREAKPOINT_VALUE.sm - 0.02}px) {
            .global-nav {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%; //100vh;
              z-index: ${Z_INDEX.mobileMenu};
            }

            .global-nav > nav {
              width: 100%;
              display: block;
              height: auto;
              margin-top: 124px;
            }

            .global-nav > nav .inner {
              position: relative;
            }

            .mobile-header {
              display: flex;
              background-color: ${COLOR.brandNavy};
            }

            .sidebar-logo,
            .trigger-collapse {
              display: none;
            }

            .global-nav > nav ul {
              padding: 0 ${SPACING.xs}px;
            }

            .global-nav > nav ul.main {
              margin-top: ${SPACING.xs}px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default MainNav;
