import type { Dayjs } from 'dayjs';

export function roundToNearestFiveMinutes(date: Dayjs) {
  // Extract minutes and seconds from the given date
  const minutes = date.minute();
  const seconds = date.second();

  // Calculate the number of 5-minute blocks and the remainder
  const remainder = minutes % 5;
  const isRoundUp = remainder >= 3 || (remainder === 0 && seconds >= 30);

  // Determine the rounded minutes
  const roundedMinutes = isRoundUp
    ? minutes + (5 - remainder) // Round up to the next 5-minute increment
    : minutes - remainder; // Round down to the previous 5-minute increment

  // Create a new dayjs object with the rounded time
  const roundedDate = date.minute(roundedMinutes).second(0).millisecond(0);

  return roundedDate;
}
