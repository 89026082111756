import { CSSProperties, PropsWithChildren, useEffect, useRef } from 'react';

type Props = PropsWithChildren<{
  className?: string;
  style?: CSSProperties;
  onRequestClose?: () => void;
}>;

export const Closable: React.FC<Props> = ({
  onRequestClose,
  className,
  style,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (!containerRef?.current?.contains(event.target as Node)) {
      onRequestClose?.();
    }
  };

  useEffect(() => {
    globalThis.addEventListener('click', handleOutsideClick, false);

    return () => {
      globalThis.removeEventListener('click', handleOutsideClick, false);
    };
  }, [handleOutsideClick]);

  return (
    <div className={className} ref={containerRef} style={style}>
      {children}
    </div>
  );
};
