import classnames from 'classnames';
import css from 'styled-jsx/css';
import React from 'react';

import Icon, { type IconProps, type Icons } from './Icon';
import { COLOR, SPACING, FONT_SIZE, LINE_HEIGHT, TRANSITION } from './theme';

type ButtonVariant = 'primary' | 'secondary' | 'light' | 'disabled';

const colors = {
  secondary: {
    light: { normal: COLOR.black, hover: COLOR.darkGray },
    dark: { normal: COLOR.white, hover: COLOR.lightGray },
  },
  disabled: {
    light: { normal: COLOR.darkGray, hover: COLOR.darkGray },
    dark: { normal: COLOR.lightGray, hover: COLOR.lightGray },
  },
  primary: {
    light: { normal: COLOR.blue, hover: COLOR.blueHover },
    dark: { normal: COLOR.blue, hover: COLOR.blueHover },
  },
  light: {
    light: { normal: COLOR.white, hover: COLOR.lightGray },
    dark: { normal: COLOR.black, hover: COLOR.darkGray },
  },
} as const;

const { className: glyphClassName, styles: glyphStyles } = css.resolve`
  .icon {
    font-size: ${FONT_SIZE.s18};
    line-height: ${LINE_HEIGHT.s18};
  }
`;

const getColor = (
  variant: ButtonVariant,
  disabled = false,
  onDark: boolean,
): string => {
  if (disabled) {
    return onDark ? colors.disabled.dark.normal : colors.disabled.light.normal;
  }

  return onDark ? colors[variant].dark.normal : colors[variant].light.normal;
};

const getHoverColor = (
  variant: ButtonVariant,
  disabled = false,
  onDark: boolean,
): string => {
  if (disabled) {
    return onDark ? colors.disabled.dark.hover : colors.disabled.light.hover;
  }

  return onDark ? colors[variant].dark.hover : colors[variant].light.hover;
};

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  className?: string;
  disabled?: boolean;
  glyph?: Icons | React.ReactElement;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: Record<string, string | number>;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariant;
  onDark?: boolean;
  label: string;
} & Omit<IconProps, 'icon' | 'tooltip'>;

export const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      disabled = false,
      glyph,
      id,
      onClick,
      style,
      type = 'button',
      variant = 'primary',
      onDark = false,
      label,
      children,
      ...iconProps
    },
    ref,
  ) => {
    const iconColor = getColor(variant, disabled, onDark);

    const opacity = disabled ? 0.6 : 1;

    return (
      <button
        aria-live="polite"
        aria-label={label}
        className={classnames('icon-button', className, variant)}
        disabled={disabled}
        id={id}
        onClick={onClick}
        ref={ref}
        style={style}
        type={type} // eslint-disable-line react/button-has-type
        data-testid={
          className || typeof glyph === 'string' ? glyph : 'icon-button'
        }
        {...iconProps}
      >
        {typeof glyph === 'string' && (
          <Icon
            className={`icon ${glyphClassName}`}
            icon={glyph}
            {...iconProps}
            style={{
              color: iconColor,
            }}
          />
        )}
        {glyph && typeof glyph !== 'string' && (
          <div
            className={`icon ${glyphClassName}`}
            style={{
              color: iconColor,
            }}
          >
            {glyph}
          </div>
        )}
        {children}
        <style jsx>
          {`
            /* Root */
            .icon-button {
              border: none;
              background: none;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              padding: 0 ${SPACING.xs}px;
              position: relative;
              border-color: ${iconColor};
            }

            .icon-button:disabled {
              pointer-events: none;
            }

            .icon-button :global(.icon),
            .icon-button :global(span) {
              opacity: ${opacity};
              transition: color ${TRANSITION.duration}
                ${TRANSITION.timingFunction};
            }

            .icon-button:hover :global(.icon),
            .icon-button:hover :global(span),
            .icon-button:focus :global(.icon),
            .icon-button:focus :global(span) {
              color: ${getHoverColor(variant, disabled, onDark)} !important;
              border-color: ${getHoverColor(
                variant,
                disabled,
                onDark,
              )} !important;
            }
          `}
        </style>
        {glyphStyles}
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
