import classNames from 'classnames';
import type { CSSProperties } from 'react';
import { COLOR, SHADOW, SPACING } from './theme';
import ThreeLoadingDots from './ThreeLoadingDots';

type Props = { loading: boolean; className?: string; style?: CSSProperties };

export const LoadingIndicator: React.FC<Props> = ({
  loading,
  className,
  style,
}) => {
  if (!loading) return null;

  return (
    <div className={classNames(className, 'wrapper')} style={style}>
      <div className="loading">
        <ThreeLoadingDots height={30} color={COLOR.mediumGray} />
      </div>
      <style jsx>{`
        .wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        .loading {
          padding: ${SPACING.md}px ${SPACING.lg}px;
          font-size: 1.2em;
          color: ${COLOR.black};
          background: ${COLOR.white};
          border: 1px solid ${COLOR.lightGray};
          border-radius: 4px;
          box-shadow: ${SHADOW.dp2};
        }
      `}</style>
    </div>
  );
};
