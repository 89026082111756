import React from 'react';

import paths from './paths.json';

export type Icons = keyof typeof paths;

export function getIcons(): Icons[] {
  return Object.keys(paths) as Icons[];
}

export type IconProps = {
  icon: Icons;
  size?: number | string;
  tooltip?: React.ReactElement<any>;
  viewbox?: string;
  fillRule?: string;
} & React.SVGProps<SVGSVGElement>;

export const isIconKey = (icon: string | undefined): icon is Icons => {
  if (!icon) return false;

  return Object.keys(paths).includes(icon);
};

export const Icon: React.FunctionComponent<IconProps> = ({
  icon,
  size,
  tooltip,
  viewbox = '0 0 24 24',
  fillRule = 'inherit',
  ...svgProps
}) => (
  <React.Fragment>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={size}
      width={size}
      fill="currentColor"
      data-for={tooltip && tooltip.props.id}
      data-tip={tooltip ? 'true' : undefined}
      viewBox={viewbox}
      fillRule={fillRule}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...svgProps}
    >
      <path d={paths[icon]} />
    </svg>
    {tooltip}
  </React.Fragment>
);

Icon.defaultProps = {
  size: '1.5em',
};

export default Icon;
