import type { LogLevel } from '../log-level';
import {
  HomeeError,
  HomeeErrorOptions,
  buildHomeeErrorOptions,
} from './homee.error';

export class ValidationError extends HomeeError {
  constructor(message: string, options?: HomeeErrorOptions | unknown) {
    const o = buildHomeeErrorOptions(options, {
      statusCode: 400,
      logLevel: 'warn',
    });

    super(message, o);

    this.name = 'ValidationError';
  }
}
