import React, { ElementType, MutableRefObject, ReactElement } from 'react';
import css from 'styled-jsx/css';

import Icon, { Icons } from './Icon';
import InputHelperText from './InputHelperText';
import Label from './Label';
import type { TextSize } from './Text';
import { COLOR, FONT_WEIGHT, SPACING } from './theme';
import getInputAccentColor from './utils/getInputAccentColor';

const { className: glyphClassName, styles: glyphStyles } = css.resolve`
  svg {
    left: 8px;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export type InputProps<
  TProps extends
    React.InputHTMLAttributes<HTMLInputElement> = React.InputHTMLAttributes<HTMLInputElement>,
> = {
  glyph?: Icons;
  glyphRight?: Icons;
  onGlyphClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onGlyphRightClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  hasVisibilityToggle?: boolean;
  helperText?: string;
  imitateFocused?: boolean;
  isInvalid?: boolean;
  label?: React.ReactNode;
  labelClassName?: string;
  labelWeight?: keyof typeof FONT_WEIGHT;
  labelSize?: TextSize;
  ref?: MutableRefObject<HTMLInputElement>;
  element?: ElementType;
} & TProps;

export const Input = <
  TInputProps extends
    React.InputHTMLAttributes<HTMLInputElement> = React.InputHTMLAttributes<HTMLInputElement>,
  TProps extends InputProps<TInputProps> = InputProps<TInputProps>,
>({
  className,
  disabled = false,
  glyph,
  glyphRight,
  onGlyphClick,
  onGlyphRightClick,
  hasVisibilityToggle = false,
  helperText,
  imitateFocused = false,
  isInvalid = false,
  label,
  labelClassName,
  labelWeight,
  labelSize,
  onBlur,
  onFocus,
  required = false,
  type = 'text',
  ref,
  element,
  ...inputAttrs
}: TProps) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [isFieldValueVisible, setIsFieldValueVisible] =
    React.useState<boolean>(false);

  const accentColor = getInputAccentColor({
    disabled,
    isFocused: isFocused || imitateFocused,
    isInvalid,
  });

  let fieldType: string = type;
  if (hasVisibilityToggle) {
    fieldType = isFieldValueVisible ? 'text' : 'password';
  }

  const props = {
    ref,
    disabled,
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(event);
      }
    },
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(event);
      }
    },
    required,
    ['aria-required']: required ? 'true' : 'false',
    type: fieldType,
    ...inputAttrs,
  } as unknown as TProps;

  const InputElement = element ?? 'input';

  return (
    <div className={className}>
      <Label
        label={label}
        className={labelClassName}
        size={labelSize}
        weight={labelWeight}
        required={required}
        isFocused={isFocused}
        imitateFocused={imitateFocused}
        disabled={disabled}
        isInvalid={isInvalid}
      >
        <div>
          <InputElement {...props} />
          {glyph && (
            <Icon
              className={glyphClassName}
              icon={glyph}
              onClick={onGlyphClick}
              style={{
                color: accentColor,
              }}
            />
          )}
          {glyphRight && !hasVisibilityToggle && (
            <Icon
              className={glyphClassName}
              icon={glyphRight}
              onClick={onGlyphRightClick}
              style={{
                color: COLOR.darkGray,
                left: 'unset',
                right: SPACING.xs,
              }}
            />
          )}
          {hasVisibilityToggle && (
            <button
              className="glyph-wrapper"
              type="button"
              onClick={() => {
                setIsFieldValueVisible(!isFieldValueVisible);
              }}
            >
              <Icon
                icon={isFieldValueVisible ? 'visibility' : 'visibilityOff'}
              />
            </button>
          )}
        </div>
        <style jsx>
          {`
            div {
              position: relative;
            }

            label {
              display: block;
              margin: 0;
              padding: 0;
              text-align: left;
            }

            input {
              border: none;
              border-bottom: 2px solid;
              border-radius: 0;
              display: block;
              font-size: 1rem;
              line-height: 1rem;
              margin: 0;
              min-height: 40px;
              width: 100%;
            }

            input::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+  */
              color: ${COLOR.darkGray};
              opacity: 1;
            }
            input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: ${COLOR.darkGray};
            }
            input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: ${COLOR.darkGray};
            }

            input:active,
            input:focus {
              outline: none;
            }

            .glyph-wrapper {
              border: none;
              background: none;
              color: ${COLOR.darkGray};
              padding: 0;
              margin: 0;
              line-height: 1;
              position: absolute;
              top: 0;
              right: 8px;
              height: 40px;
            }

            input::-webkit-strong-password-auto-fill-button {
              visibility: hidden;
              display: none !important;
              pointer-events: none;
              position: absolute;
              right: 0;
            }
          `}
        </style>
        <style jsx>
          {`
            input {
              background-color: ${disabled ? '#fbfcfc' : COLOR.white};
              border-bottom-color: ${accentColor};
              color: ${disabled ? COLOR.neutral30 : COLOR.black};
              padding: 0 ${glyphRight || hasVisibilityToggle ? 42 : 10}px 0
                ${glyph ? 42 : 10}px;
            }
          `}
        </style>
        {glyphStyles}
      </Label>
      {helperText && (
        <InputHelperText isInvalid={isInvalid}>{helperText}</InputHelperText>
      )}
    </div>
  );
};

export default Input;
