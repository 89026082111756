import type { Falsey } from 'lodash';
import {
  assertIsArray,
  assertIsDefined,
  throwValidationError,
} from './validation';

/**
 * Filters out any falsey elements in an array
 */
export function filterFalsey<T>(input: T[]): Exclude<T, Falsey>[] {
  return (input ?? []).filter((x) => !!x) as Exclude<T, Falsey>[];
}

/**
 * Filters out any undefined elements in an array
 */
export function filterUndefined<T>(input: (T | undefined)[]): T[] {
  return (input ?? []).filter((x): x is T => x !== undefined);
}

/**
 * Return the first element of an array
 */
export function getFirstElement<T>(arr?: T[]): T | undefined {
  if (!arr?.length) return undefined;

  return arr[0];
}

export function assertArrayNonEmpty<T>(
  arr: T[] | undefined,
  errorMessage?: string | Error,
): asserts arr is { 0: T } & T[] {
  assertIsDefined(arr, errorMessage);

  if (!arr.length || arr.length < 1) {
    throwValidationError(
      errorMessage ??
        `The array was expected to have at least one element, but it had length=${arr.length}`,
    );
  }
}

/**
 * Return the first element of an array, or fail if not possible.
 */
export function mustGetFirstElement<T>(
  arr: T[] | undefined,
  errorMessage?: string | Error,
): T {
  assertArrayNonEmpty(arr, errorMessage);
  return arr[0];
}

/**
 * Return the last element of an array, or fail if not possible.
 */
export function mustGetLastElement<T>(
  arr: T[] | undefined,
  errorMessage?: string | Error,
): T {
  assertArrayNonEmpty(arr, errorMessage);
  return arr[arr.length - 1];
}

/**
 * Return the only element of an array, or fail if not possible.
 */
export function mustGetOnlyElement<T>(
  arr: T[] | undefined,
  errorMessage?: string | Error,
): T {
  assertIsDefined(arr, errorMessage);

  if (!arr.length || arr.length !== 1) {
    throwValidationError(
      errorMessage ??
        `The array was expected to have exactly one element, but it had length=${arr.length}`,
    );
  }
  return arr[0];
}

/**
 * Returns an array of the extracted property from the elements.
 */
export function mapField<TItem, TKey extends keyof TItem>(
  arr: TItem[],
  property: TKey,
): TItem[TKey][] {
  return arr.map((element) => element[property]);
}

/**
 * Returns the number of unique elements in an array.
 * Value equality is based on the SameValueZero algorithm:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Equality_comparisons_and_sameness#same-value-zero_equality
 */
export function uniqueCount<T>(xs: T[]): number {
  return new Set(xs).size;
}
