import {
  Button,
  FONT_SIZE,
  FONT_WEIGHT,
  LoadingOverlay,
  PointyModal,
  SHADOW,
  SPACING,
  Text,
} from '@homee/ui-web-presentation';
import { useRouter } from 'next/router';
import React from 'react';
import type { NotificationFragment } from '../../lib/generated/types';
import { toastError } from '../../components/Toaster';
import NotificationsAllReadButton from '../../features/notifications/NotificationsAllReadButton';
import Notification from '../../features/notifications/Notification';
import { useConfirmCompanyChange } from '../../components/ConfirmCompanyChangeProvider';

type Props = {
  isOpen?: boolean;
  isLoading?: boolean;
  onRequestClose: () => void;
  notifications: NotificationFragment[];
  hasUnread: boolean;
};

const NotificationsModal: React.FC<Props> = ({
  isOpen,
  isLoading,
  onRequestClose,
  notifications,
  hasUnread,
}) => {
  const { push } = useRouter();
  const { confirmCompanyChange } = useConfirmCompanyChange();

  const handleNotificationClick = async (
    notification: NotificationFragment,
  ) => {
    try {
      const { url } = notification;

      if (!url) return;

      if (notification.companyId) {
        const doSwitch = await confirmCompanyChange(notification.companyId);

        if (!doSwitch) return;
      }

      await push(url);
    } catch (e) {
      toastError(e);
    }
  };

  return (
    <>
      {isOpen && (
        <PointyModal
          onRequestClose={onRequestClose}
          offsetX={-230}
          offsetY={22}
          style={{ width: 'fit-content' }}
        >
          <LoadingOverlay loading={isLoading}>
            <div className="notifications">
              <div className="header">
                <Text size="medium" weight="bold">
                  NOTIFICATIONS
                </Text>
                <div className="sub-header">
                  {!!notifications.length && hasUnread && (
                    <NotificationsAllReadButton onClick={onRequestClose} />
                  )}
                </div>
              </div>
              {!!notifications.length && (
                <React.Fragment>
                  <ul>
                    {notifications.slice(0, 6).map((notification) => (
                      <Notification
                        key={notification.id}
                        notification={notification}
                        onClick={handleNotificationClick}
                      />
                    ))}
                  </ul>
                  <div className="view-all">
                    <Button
                      onClick={() => {
                        onRequestClose();
                        void push('/notifications', `/notifications`);
                      }}
                      type="button"
                      size="x-small"
                      variant="ghost"
                    >
                      View All
                    </Button>
                  </div>
                </React.Fragment>
              )}
              {!notifications.length && (
                <div className="no-notification">
                  <Text size="large">No Updates</Text>
                </div>
              )}
            </div>
          </LoadingOverlay>
        </PointyModal>
      )}
      <style jsx>
        {`
          button :global(.action-text):hover {
            font-weight: 800 !important;
          }

          .notifications {
            box-shadow: ${SHADOW.dp2};
            width: 344px;
            border-radius: 4px;
          }
          .header {
            display: flex;
            background: #FAFAFA;
            align-items: center;
            justify-content: space-between;
            padding: ${SPACING.xs}px ${SPACING.sm}px;
          }
          .header > :global(p) {
            letter-spacing: 0.07rem !important;
          }
          .notifications :global(button.mark-as-read) {
            background-color: transparent;
            border-style: none;
          }
          .notifications :global(button.mark-as-read span) {
            text-decoration: underline !important;
            font-weight ${FONT_WEIGHT.regular} !important;
            font-size: ${FONT_SIZE.s11} !important;

          }
          .no-notification {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 140px;
          }
          .no-notification :global(p){
            font-size: ${FONT_SIZE.s18} !important;
            color: #a7a7a7;
            margin-bottom: ${SPACING.sm}px;
          }
          ul {
            padding: 0;
            margin: 0;
            list-style: none;
          }
          .view-all {
            display: flex;
            background: #FAFAFA;
            justify-content: center;
            align-items: center;
            padding: ${SPACING.xs}px;
            height: 32px;
          }
          .view-all :global(button) {
            background-color: transparent;
            border-style: none;
            width: 100%;
          }
        `}
      </style>
    </>
  );
};

export default NotificationsModal;
