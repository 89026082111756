import mime from 'mime';

export const defaultMimeType = 'application/octet-stream';

export function getMimeTypeFilter<T extends string | unknown>(
  mimeTypes: string[],
  transform: (item: T) => string = (item) =>
    typeof item === 'string' ? item : defaultMimeType,
): (item: T) => boolean {
  mimeTypes = normalizeMimeTypes(mimeTypes);
  return (item: T) => {
    const mimeType = transform(item);

    return isAllowedMimeType(mimeType, mimeTypes);
  };
}

function isAllowedMimeType(mimeType: string, accepts: string[]) {
  mimeType = mimeType.toLowerCase();

  return accepts.some((type) => mimeType.startsWith(type));
}

/**
 * Determines if the given mime-type or file extension is allowed
 */
export function isAllowedMimeTypeOrExtension(args: {
  /* mime-types e.g. image/jpeg. Should be validated to match extension */
  mimeType?: string;
  /* file extension e.g. .pdf. Can be extracted using `getFileExtension` */
  extension?: string;
  /* Array of mime-types (image/jpeg or image/*) or file extensions (.pdf) */
  accepts: string[];
}) {
  // eslint-disable-next-line prefer-const
  let { mimeType, extension, accepts } = args;

  if (!accepts?.length) return true;

  const normalizedAccepts = normalizeMimeTypes(accepts);
  mimeType = mimeType?.toLowerCase();
  extension = extension?.toLowerCase();

  const out = normalizedAccepts.some((type) => {
    return type.includes('/') ? mimeType?.startsWith(type) : type === extension;
  });

  return out;
}

export function normalizeMimeTypes(mimeTypes: string[]) {
  return mimeTypes.map((mimeType) => normalizeMimeType(mimeType));
}

export function normalizeMimeType(mimeType: string) {
  return mimeType.toLowerCase().replaceAll('*', '');
}

export function getExtensions(acceptedFileTypes: string[]) {
  const extSet = new Set(
    (acceptedFileTypes ?? []).map((x) => {
      if (x.endsWith('/*')) return x.slice(0, -2) + 's';

      if (x.includes('/')) {
        const ext = mime.getExtension(x);

        return ext ? `.${ext}` : '';
      }

      return x;
    }),
  );

  return [...extSet.values()].join(', ');
}
