import classnames from 'classnames';
import { COLOR, SPACING, FONT_SIZE } from '../theme';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  id: string;
  label: string;
  selectedOptionCount?: number;
  selectedMark?: boolean;
  isActive: boolean;
  isOpen?: boolean;
  onClick: () => void;
  hasOptions?: boolean;
};

export const FilterButton: React.FC<Props> = ({
  id,
  label,
  selectedOptionCount,
  selectedMark = false,
  isActive,
  isOpen = false,
  onClick,
  hasOptions = false,
}) => (
  <button
    id={id}
    type="button"
    className={classnames('filter-button', {
      active: isActive,
      open: isOpen,
    })}
    onClick={onClick}
  >
    <Text
      size="small"
      element="span"
      color={isActive ? 'selected' : 'default'}
      weight="semi"
      className="label"
      style={{ whiteSpace: 'nowrap' }}
    >
      {label}
    </Text>
    {isActive && selectedOptionCount && selectedOptionCount > 0 && (
      <Text size="small" element="span" onDark className="counter">
        {selectedOptionCount}
      </Text>
    )}
    {isActive && selectedMark && (
      <Icon
        icon="check"
        style={{ fontSize: 8, color: COLOR.white, padding: SPACING.xxs }}
        className="counter"
      />
    )}
    {hasOptions && (
      <Icon
        icon={isOpen ? 'expandLess' : 'expandMore'}
        className="icon"
        size={FONT_SIZE.s16}
      />
    )}
    <style jsx>
      {`
        /* Default */
        .filter-button {
          display: flex;
          align-items: center;
          border: 1px solid ${COLOR.darkGray};
          border-radius: ${SPACING.xxs}px;
          background-color: ${COLOR.white};
          padding: ${SPACING.xxs}px ${SPACING.xs}px;
          text-align: left;
        }

        .filter-button :global(.counter) {
          display: inline-block;
          background-color: ${COLOR.darkGray};
          border-radius: 100%;
          width: ${SPACING.sm}px;
          height: ${SPACING.sm}px;
          text-align: center;
          margin-left: ${SPACING.xxs}px;
        }

        .filter-button :global(.icon) {
          margin-left: ${SPACING.xxs}px;
          margin-right: -${SPACING.xxs}px;
        }

        /* Open Options */

        /* Hover Default */
        .filter-button:hover {
          border-color: ${COLOR.black};
          background-color: ${COLOR.softGray};
        }

        .filter-button:hover :global(i.icon.counter) {
          color: ${COLOR.white} !important;
        }
        .filter-button:hover :global(.counter) {
          background-color: ${COLOR.black};
        }

        .filter-button:hover :global(.icon),
        .filter-button:hover :global(.label) {
          color: ${COLOR.black} !important;
        }

        /* Active */
        .filter-button.active {
          border-color: ${COLOR.blue};
        }

        .filter-button.active :global(.counter) {
          background-color: ${COLOR.blue};
        }

        .filter-button.active :global(.icon) {
          color: ${COLOR.blue};
        }

        /* Hover Active */
        .filter-button.active:hover {
          border-color: ${COLOR.blueHover};
        }

        .filter-button.active:hover :global(.counter) {
          background-color: ${COLOR.blueHover};
        }

        .filter-button.active:hover :global(.icon),
        .filter-button.active:hover :global(.label) {
          color: ${COLOR.blueHover} !important;
        }
      `}
    </style>
  </button>
);

export default FilterButton;
