import type { AuthUser } from '@homee/pro-portal/types-api';
import type { ISODateString } from 'next-auth';
import { signOut, useSession, UseSessionOptions } from 'next-auth/react';

/**
 * React Hook that gives you access
 * to the logged in user's session data,
 * using the {@link AuthUser} type as the Session.user type
 *
 * Works identical to NextAuth's useSession
 * [Documentation](https://next-auth.js.org/getting-started/client#usesession)
 */
export default function useTypedSession<R extends boolean = false>(
  options?: UseSessionOptions<R>,
): TypedSessionContextValue<AuthUser, R> {
  const result = useSession(options);

  // This is to handle signaling between the nextauth middleware
  // when refreshing authentication and client side
  // since sign-out can't be called server side.
  const error = (result?.data?.user as AuthUser)?.e;

  if (error && window) {
    // TODO: better logging
    // eslint-disable-next-line no-console
    console.log(
      `There was an error generating or refreshing authorization. ${error}`,
    );

    void signOut();

    return {
      data: null,
      status: 'loading',
    };
  }

  return result as unknown as TypedSessionContextValue<AuthUser, R>;
}

/**
 *  Mirrors NextAuth {@link Session} using the TUser type as the Session.user type
 */
export type TypedSession<TUser> = Record<string, unknown> & {
  user: TUser;
  expires: ISODateString;
};

/**
 *  Mirrors NextAuth {@link SessionContextValue<R>} using the TUser type as the Session.user type
 */
export declare type TypedSessionContextValue<
  TUser,
  R extends boolean = false,
> = R extends true
  ?
      | {
          data: TypedSession<TUser>;
          status: 'authenticated';
        }
      | {
          data: null;
          status: 'loading';
        }
  :
      | {
          data: TypedSession<TUser>;
          status: 'authenticated';
        }
      | {
          data: null;
          status: 'unauthenticated' | 'loading';
        };
