import { assertIsTrue, isNumeric } from '../utils/validation';

/**
 * Formats input into a percentage string in the format ###.#%.
 *
 * Expects a number in the format 95 or 86.123, and returns a string in the format '95%' or '86.1%' respectively
 */
export const formatPercent = (input: number, fractionDigits = 0) => {
  assertIsTrue(
    fractionDigits >= 0 && fractionDigits <= 100,
    'fractionDigits must be between 0 and 100.',
  );
  if (!isNumeric(input) || !Number.isFinite(input)) {
    return '';
  }

  return `${input.toFixed(fractionDigits)}%`;
};

/**
 * Formats input into a percentage string in the format ###.#%.
 *
 * Expects a number in the format .95 or 0.3456, and returns a string in the format '95%' or '34.5%' respectively
 */
export const formatPercentFromPercent = (input: number, fractionDigits = 0) => {
  if (!isNumeric(input) || !Number.isFinite(input)) {
    return '';
  }

  const converted = input * 100.0;

  return formatPercent(converted, fractionDigits);
};
