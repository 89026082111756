import classNames from 'classnames';
import type { CSSProperties } from 'react';
import { Closable } from './Closable';
import { COLOR, SHADOW } from './theme';
import type { ChildrenProps } from './types/ChildrenProps';

type Props = ChildrenProps<{
  className?: string;
  minWidth?: number;
  offsetX?: number;
  offsetY?: number;
  style?: CSSProperties;
  onRequestClose?: () => void;
}>;

export const PointyModal: React.FC<Props> = ({
  className,
  style,
  onRequestClose,
  children,
  offsetX = 0,
  offsetY = 0,
}) => {
  return (
    <div className="wrapper">
      <Closable
        style={style}
        className={classNames('pointy-container', className)}
        onRequestClose={onRequestClose}
      >
        {children}
      </Closable>
      <style jsx>{`
        .wrapper {
          position: absolute;
          width: 0px;
          height: 0px;
        }
        .wrapper :global(.pointy-container) {
          position: relative;
          display: flex;
          flex-direction: column;
          background: ${COLOR.white};
          box-shadow: ${SHADOW.dp2};
          border-top: 1px solid ${COLOR.lightGray};
          z-index: 2;
          top: calc(100% + 20px + ${offsetY}px);
          left: calc(100% - 50px + ${offsetX}px);
        }
        .wrapper :global(.pointy-container:after) {
          content: '';
          position: absolute;
          top: -15px;
          right: 35px;
          border-style: solid;
          border-width: 0px 16px 15px 15px;
          border-color: ${COLOR.lightGray} transparent;
          display: block;
          width: 0;
          z-index: 0;
        }
        .wrapper :global(.pointy-container:before) {
          content: '';
          position: absolute;
          top: -14px;
          right: 36px;
          border-style: solid;
          border-width: 0px 15px 15px 15px;
          border-color: white transparent;
          display: block;
          width: 0;
          z-index: 1;
        }
      `}</style>
    </div>
  );
};
