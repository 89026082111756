import {
  BREAKPOINT_VALUE,
  Filter,
  Input,
  RadioOptions,
  RadioOptionValue,
  SPACING,
  Text,
} from '@homee/ui-web-presentation';
import React, { useMemo, useState } from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { AppActionType } from '../../components/AppContext';
import { toastError } from '../../components/Toaster';
import { useRouter } from 'next/router';

const SelectCompany: React.FC = () => {
  const { companies, selectedCompanyId, loading, dispatch } = useAppContext();
  const selectedCompany = companies.find(({ id }) => selectedCompanyId === id);
  const selectedCompanyOption = selectedCompany
    ? {
        id: selectedCompany.id,
        value: selectedCompany.id,
        label: selectedCompany.name,
        selected: true,
      }
    : null;
  const [selectedOption, setSelectedOption] = useState<RadioOptionValue | null>(
    selectedCompanyOption,
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState('');
  const router = useRouter();

  const onApply = () => {
    try {
      setIsOpen(false);
      dispatch({
        type: AppActionType.SelectCompany,
        companyId: selectedOption.id,
      });
      void router.push('/');
    } catch (e) {
      toastError(e);
    }
  };

  const options = useMemo(
    () =>
      companies
        .map(({ id, name, addresses }) => ({
          id,
          value: id,
          name,
          label: (
            <div>
              <Text size="medium" element="div">
                {name}
              </Text>
              {addresses?.physical && (
                <Text size="small" element="div">
                  {addresses.physical.city}, {addresses.physical.state}
                </Text>
              )}
            </div>
          ),
          selected: id === selectedOption?.id,
        }))
        .filter(({ name }) =>
          name.toLowerCase().includes(searchInput.toLowerCase()),
        ) || [],
    [companies, searchInput, selectedOption],
  );

  return (
    <div className="select-company">
      <Filter
        id="company"
        label={selectedCompany?.name}
        title={selectedCompany?.name}
        className="company-dropdown"
        isActive
        onApply={onApply}
        onClear={() => {
          setSelectedOption(selectedCompanyOption);
          setSearchInput('');
          setIsOpen(false);
        }}
        onReset={() => {
          setSelectedOption(selectedCompanyOption);
        }}
        loading={loading}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        onApplyButtonName="Switch"
        onClearButtonName="Cancel"
      >
        <Input
          placeholder="Search Company"
          glyph="search"
          value={searchInput}
          onChange={(event) => setSearchInput(event.currentTarget.value)}
        />
        <RadioOptions
          name="companies-list"
          options={options}
          onSelected={(company) => setSelectedOption(company)}
        />
      </Filter>
      <style jsx>
        {`
          .select-company {
            padding-left: ${SPACING.sm}px;
          }

          .select-company :global(#filter-company) {
            background: transparent;
            border: 1px solid #cccccc;
            border-radius: 3px;
            padding: ${SPACING.xxs}px ${SPACING.xs}px;
            min-height: 33px;
          }
          .select-company :global(#filter-company span) {
            font-size: 0.8rem !important;
            color: #888888 !important;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .select-company :global(#filter-company svg) {
            color: #909090 !important;
          }
          .select-company :global(.company-dropdown .dropdown-wrapper ul) {
            margin: ${SPACING.xs}px 0px;
          }

          .select-company :global(.company-dropdown .dropdown-wrapper) {
            min-width: 300px;
            margin-right: ${SPACING.sm}px;
          }

          @media (max-width: ${BREAKPOINT_VALUE.sm - 0.02}px) {
            .select-company :global(#filter-company span) {
              max-width: 100px;
            }

            .select-company :global(.company-dropdown .dropdown-wrapper) {
              min-width: 280px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default SelectCompany;
