import type { Moment } from 'moment';
import {
  API_DATE_FORMAT,
  TIME_FORMAT,
  EN_DASH,
  DATE_FORMAT,
  DATE_FORMAT_NO_YEAR,
  DATE_NUMERIC_FORMAT,
  DATE_SHORT_FORMAT,
  DAY_MONTH_DATE_FORMAT,
  DAY_SHORT_FORMAT,
  UNIX_TIMESTAMP_FORMAT,
  MONTH_DATE_FORMAT,
} from './date.constants';
import type { DateFormat } from './date';

/**
 * @example "2020-12-07 08:59:59"
 */
export const V1_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/**
 * @example "2020-12-07T08:59:59.000Z"
 */
export const V2_DATE_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';

/**
 * @example "7 Dec 2021"
 */
export const DATE_FORMAT_FR = 'D MMM YYYY';
/**
 * @example "7 Dec"
 */
export const DATE_FORMAT_NO_YEAR_FR = 'D MMM';
/**
 * @example "7 Dec"
 */
export const MONTH_DATE_FORMAT_FR = 'D MMM';
/**
 * @example "2020-12-07"
 */
export const DATE_NUMERIC_FR_FORMAT = 'YYYY-MM-DD';

/**
 * @deprecated Use {@link DateFormat.apiDate}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "2020-12-07"
 */
export const formatApiDate = (moment: Moment): string => {
  return moment.format(API_DATE_FORMAT);
};

/**
 * @deprecated This method will be removed in a future release.
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "2020-12-07 08:59:59"
 */
export const formatV1DateTime = (moment: Moment): string => {
  return moment.format(V1_DATE_TIME_FORMAT);
};

/**
 * @deprecated This method will be removed in a future release.
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "2020-12-07T08:59:59.000Z"
 */
export const formatV2DateTime = (moment: Moment): string => {
  return moment.format(V2_DATE_TIME_FORMAT);
};

/**
 * @deprecated Use {@link DateFormat.hourRange}
 * @param {Moment} start - The moment instance that holds the range's start time
 * @param {Moment} end - The moment instance that holds the range's end time
 * @return {string} Formatted date range, example: "8:30 AM-9:30 AM"
 */
export const formatHourRange = (start: Moment, end: Moment): string => {
  return `${start.format(TIME_FORMAT)}${EN_DASH}${end.format(TIME_FORMAT)}`;
};

/**
 * @deprecated Use {@link DateFormat.date}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "Dec 7, 2020"
 */
export const formatDate = (moment: Moment): string => {
  if (moment.locale() === 'fr') {
    return moment.format(DATE_FORMAT_FR);
  }

  return moment.format(DATE_FORMAT);
};

/**
 * @deprecated Use {@link DateFormat.dateTime}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "Dec 7, 2020, 8:59 PM"
 */
export const formatDateTime = (moment: Moment): string => {
  if (moment.locale() === 'fr') {
    return moment.format(`${DATE_FORMAT_FR}, ${TIME_FORMAT}`).replace('.', '');
  }

  return moment.format(`${DATE_FORMAT}, ${TIME_FORMAT}`);
};

/**
 * @deprecated Use {@link DateFormat.dateTime} with includeYearIfCurrent = false
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "Dec 7, 8:59 PM"
 */
export const formatDateTimeNoYear = (moment: Moment): string => {
  if (moment.locale() === 'fr') {
    return moment
      .format(`${DATE_FORMAT_NO_YEAR_FR}, ${TIME_FORMAT}`)
      .replace('.', '');
  }

  return moment.format(`${DATE_FORMAT_NO_YEAR}, ${TIME_FORMAT}`);
};

/**
 * @deprecated Use {@link DateFormat.dateNumeric}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted numeric date, example: "12/7/2020"
 */
export const formatDateNumeric = (moment: Moment): string => {
  if (moment.locale() === 'fr') {
    return moment.format(DATE_NUMERIC_FR_FORMAT);
  }

  return moment.format(DATE_NUMERIC_FORMAT);
};

/**
 * @deprecated Use {@link DateFormat.dateTimeNumeric}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted numeric date time, example: "12/7/2020, 8:59 PM"
 */
export const formatDateTimeNumeric = (moment: Moment): string => {
  if (moment.locale() === 'fr') {
    return moment.format(`${DATE_NUMERIC_FR_FORMAT}, ${TIME_FORMAT}`);
  }

  return moment.format(`${DATE_NUMERIC_FORMAT}, ${TIME_FORMAT}`);
};

/**
 * @deprecated Use {@link DateFormat.shortDayMonthDate}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "Fri, Dec 7"
 */
export const formatShortDate = (moment: Moment): string => {
  return moment.format(DATE_SHORT_FORMAT);
};

/**
 * @deprecated Use {@link DateFormat.dayMonthDate}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "Friday, December 07"
 */
export const formatDayMonthDate = (moment: Moment): string => {
  return moment.format(DAY_MONTH_DATE_FORMAT);
};

/**
 * @deprecated Use {@link DateFormat.shortDay}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "Fri"
 */
export const formatShortDay = (moment: Moment): string => {
  return moment.format(DAY_SHORT_FORMAT);
};

/**
 * @deprecated Use {@link DateFormat.hourMinute}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "8:59 AM"
 */
export const formatHourMinute = (moment: Moment): string => {
  return moment.format(TIME_FORMAT);
};

/**
 * @deprecated Use {@link DateFormat.timestamp}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted Unix timestamp, example: "1360013296"
 */
export const formatTimestamp = (moment: Moment): string => {
  return moment.format(UNIX_TIMESTAMP_FORMAT);
};

/**
 * @deprecated Use {@link DateFormat.monthDate}
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date, example: "Dec 7"
 */
export const formatMonthDate = (moment: Moment): string => {
  if (moment.locale() === 'fr') {
    return moment.format(MONTH_DATE_FORMAT_FR).replace('.', '');
  }

  return moment.format(MONTH_DATE_FORMAT);
};

/**
 * @deprecated This method will be removed in a future release.
 * @param {Moment} moment - The moment instance to format
 * @return {string} Formatted date range, example: "Dec 7, between 8:30 AM-9:30 AM
 */
export const formatUpcomingJobRange = (moment: Moment): string => {
  const date = moment.format(MONTH_DATE_FORMAT);
  const timeRangeMin = moment.format(TIME_FORMAT);
  const timeRangeMax = moment.clone().add(1, 'hour').format(TIME_FORMAT);

  return `${date}, ${timeRangeMin}${EN_DASH}${timeRangeMax}`;
};

/**
 * @deprecated Use {@link DateFormat.descriptiveDate}
 * @param {Moment} moment - The moment instance to format
 * @param {Moment} today - The moment instance of the current day
 * @return {string} Formatted date, examples: "Today", "Tomorrow", "Yesterday", "12/7/2020"
 */
export const formatDateDescriptive = (
  moment: Moment,
  today: Moment,
  format: FormatDateFunc = (moment) => formatDateNumeric(moment),
): string => {
  if (moment.isSame(today, 'day')) {
    return 'Today';
  }

  if (moment.isSame(today.clone().subtract(1, 'day'), 'day')) {
    return 'Yesterday';
  }

  if (moment.isSame(today.clone().add(1, 'day'), 'day')) {
    return 'Tomorrow';
  }

  return format(moment);
};

export type FormatDateFunc = (moment: Moment) => string;
