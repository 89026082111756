import React, { PropsWithChildren } from 'react';
import Head from 'next/head';

import { BREAKPOINT_VALUE, Heading } from '@homee/ui-web-presentation';
import { COLOR, SPACING } from '@homee/ui-web-presentation';
import classNames from 'classnames';

type Props = PropsWithChildren<{
  title?: string | React.ReactNode;
  className?: string;
  actionClassName?: string;
}>;

const PageTitle: React.FC<Props> = ({
  children,
  title,
  className,
  actionClassName,
}) => (
  <header>
    <Head>
      <title>{title} | HOMEE</title>
    </Head>
    <Heading className={className}>{title}</Heading>
    {children && (
      <div className={classNames('actions', actionClassName)}>{children}</div>
    )}
    <style jsx>
      {`
        header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: ${SPACING.md}px 0 ${SPACING.md}px;
          margin: 0 ${SPACING.md}px;
          flex: none;
          flex-wrap: wrap;
        }
        header > :global(h1),
        header > :global(h2),
        header > :global(h3),
        header > :global(h4),
        header > :global(h5),
        header > :global(h6) {
          white-space: nowrap;
          padding-right: ${SPACING.md}px;
          padding-bottom: ${SPACING.md}px;
        }
        .actions {
          display: flex;
          align-items: center;
          margin-left: auto;
        }
        @media (max-width: ${BREAKPOINT_VALUE.sm}px) {
          header {
            flex-direction: column;
            align-items: start;
            padding: ${SPACING.sm}px 0 ${SPACING.sm}px;
          }
          .actions {
            margin-left: 0;
          }
        }
      `}
    </style>
  </header>
);

export default PageTitle;
