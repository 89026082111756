import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

export function titleCase(input: string): string {
  if (!input) return '';

  return startCase(camelCase(input));
}

export function pascalCase(input: string) {
  return titleCase(input).replace(/ /g, '');
}
