/**
 * Generate a url safe slug from a string.
 * @param input The string to slugify.
 * @param maxLength The maximum length of the slug. default is no max length
 */
export function generateSlug(input: string, maxLength?: number): string {
  // remove diacritics
  let output = input.normalize('NFD').replaceAll(/\p{Diacritic}/gu, '');

  output = output.toLowerCase();

  // replace apostrophes with empty string
  output = output.replaceAll("'", '');

  // replace all non-alphanumeric characters with dashes
  output = output.replaceAll(/[^a-z0-9]/gi, '-');

  // replace multiple dashes with a single dash
  output = output.replaceAll(/-{1,}/g, '-');

  if (maxLength) {
    output = output.substring(0, maxLength);
  }

  // remove leading and trailing dashes
  output = output.replaceAll(/^-|-$/g, '');

  return output;
}
