import Link from 'next/link';
import React, { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';

import { Text } from '@homee/ui-web-presentation';
import { COLOR } from '@homee/ui-web-presentation';

type Props = PropsWithChildren<{
  label: string;
  href: string;
  isActive?: boolean;
  onClick?: (
    event: React.SyntheticEvent<HTMLAnchorElement>,
  ) => any | Promise<any>;
}>;

const ChildNavItem: React.FC<Props> = ({
  children,
  label,
  href,
  isActive = false,
  onClick = undefined,
}) => {
  const router = useRouter();
  const isItemActive = router.pathname === href;
  return (
    <li className="child-item">
      <Link
        href={href}
        role="link"
        className={isItemActive ? 'active' : undefined}
        onClick={onClick}
        onKeyDown={onClick}
        tabIndex={0}
      >
        <Text
          element="span"
          size="medium"
          color={isItemActive ? 'selected' : 'gray'}
          weight={isItemActive ? 'bold' : 'regular'}
        >
          {label}
          {children}
        </Text>
      </Link>
      <style jsx>
        {`
          .child-item :global(a) {
            display: inline-flex;
            align-items: center;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            outline: none;
          }

          .child-item :global(a:hover) {
            text-decoration: none;
          }

          .child-item :global(span) {
            color: ${COLOR.softGray} !important;
          }

          .child-item :global(a:hover) :global(span) {
            filter: brightness(85%);
          }

          .child-item :global(a.active:hover) :global(span) {
            color: ${COLOR.blueHover} !important;
          }
        `}
      </style>
    </li>
  );
};

export default ChildNavItem;
