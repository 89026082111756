import type { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { LoadingIndicator } from './LoadingIndicator';

type Props = PropsWithChildren<{ className?: string; loading: boolean }>;

export const LoadingOverlay: React.FC<Props> = ({
  className,
  loading,
  children,
}) => {
  return (
    <div className={classnames(className, 'wrapper')}>
      {loading && (
        <div className="overlay">
          <LoadingIndicator loading={loading} style={{ height: '100%' }} />
        </div>
      )}
      {children}
      <style jsx>{`
        .wrapper {
          position: relative;
        }
        .overlay {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0px;
          left: 0px;
          background: rgba(255, 255, 255, 0.5);
          z-index: 800;
        }
      `}</style>
    </div>
  );
};

export default LoadingOverlay;
