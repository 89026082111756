import { isNumeric } from '../utils/validation';
/**
 *
 * @param number ex: 10040
 * @returns formatted currency string with dollar sign and two places past the decimal. ex: $100.40
 */
export const formatNumberToCurrency = (num: number): string => {
  if (!isNumeric(num)) return '';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  const formattedNumber = formatter.format(num / 100);
  return formattedNumber;
};
