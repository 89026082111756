export function getFileExtension(filename: string): string {
  if (!filename || filename.indexOf('.') === -1) return '';

  const parts = filename.split('.');

  const lastPart = parts.pop();

  return lastPart ? `.${lastPart}` : '';
}

export function isFileSizeExceeded(
  fileSize: number,
  maxSize: number = 1000 * 1000 * 10,
): boolean {
  return fileSize > maxSize;
}
