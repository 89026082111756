import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useRef, useState } from 'react';
import Button, { ButtonProps } from './Button';
import SimpleModal from './SimpleModal';
import { Text } from './Text';
import { SPACING } from './theme';
import type { ChildrenProps } from './types/ChildrenProps';
import { asPromise } from '@homee/util-common';

export type DialogResults<TResults = unknown, TParam = unknown> =
  | TResults
  | ((params: TParam | undefined) => TResults);

export type DialogActionProps<TResults = unknown, TParam = unknown> = Omit<
  ButtonProps,
  'ref' | 'children'
> & {
  result: DialogResults<TResults, TParam>;
  title: string | React.ReactElement;
};

interface UseDialogOptions {
  isDefaultOpen?: boolean;
}

export interface UseDialogResult<TResult = unknown, TParam = unknown> {
  isOpen?: boolean;
  params?: TParam;
  result?: TResult;
  open(
    options?: Partial<DialogDisplayProps> & {
      params?: TParam;
    },
  ): Promise<UseDialogResult<TResult, TParam>['result']>;
  close(result?: UseDialogResult<TResult, TParam>['result']): void;
  displayProps?: Partial<DialogDisplayProps>;
}

export function useDialog<TResult = unknown, TParam = unknown>(
  config?: UseDialogOptions,
): UseDialogResult<TResult, TParam> {
  const [isOpen, setIsOpen] = useState<boolean>(!!config?.isDefaultOpen);
  const [params, setParams] = useState<TParam>();
  const [result, setResult] = useState<TResult>();
  const [displayProps, setProps] = useState<Partial<DialogDisplayProps>>();

  const close = useRef<UseDialogResult<TResult, TParam>['close']>(() => {
    // eslint-disable-next-line no-console
    console.warn(new Error('Trying to close dialog without opening it'));
  });

  const open = useCallback(
    (
      options?: Partial<DialogDisplayProps> & {
        params?: TParam;
      },
    ) => {
      const { params, ...displayProps } = options || {};
      setIsOpen(true);
      setParams(params);
      setProps(displayProps);

      return new Promise<UseDialogResult<TResult, TParam>['result']>(
        (resolve) => {
          close.current = (
            result: UseDialogResult<TResult, TParam>['result'] = undefined,
          ) => {
            setResult(result);
            setParams(undefined);
            setProps(undefined);
            setIsOpen(false);
            resolve(result);
          };
        },
      );
    },
    [],
  );

  return {
    isOpen,
    params,
    result,
    open,
    close: close.current,
    displayProps,
  };
}

export type DialogDisplayProps = {
  className?: string;
  title: string;
  description: string;
  dismissible?: boolean;
};

export type DialogProps<
  TResult = unknown,
  TParam = unknown,
> = DialogDisplayProps &
  ChildrenProps<{
    isOpen?: boolean;
    params?: TParam;
    loading?: boolean;
    onClose(result?: UseDialogResult<TResult, TParam>['result']): void;
    actions:
      | DialogActionProps<TResult, TParam>[]
      | ((params: TParam | undefined) => DialogActionProps<TResult, TParam>[]);
  }>;

export function Dialog<TResult = unknown, TParam = unknown>(
  props: DialogProps<TResult, TParam>,
) {
  const {
    className,
    isOpen,
    dismissible = false,
    title,
    description,
    children,
    params,
    actions: actionsFactory,
    onClose,
    loading,
  } = props;

  const actions = Array.isArray(actionsFactory)
    ? actionsFactory
    : actionsFactory(params);

  const handleActionClick = useCallback(
    (result: TResult | ((params: TParam | undefined) => TResult)) => {
      if (isFunction(result)) {
        result = result(params);
      }
      onClose(result);
    },
    [onClose, params],
  );

  return (
    <SimpleModal
      isOpen={isOpen}
      isLoading={loading}
      dismissible={dismissible}
      onRequestClose={() => {
        if (dismissible) {
          onClose();
        }
      }}
      className={classNames('dialog', className)}
      actions={actions.map(({ result, title, ...buttonProps }, i) => (
        <Button
          {...buttonProps}
          key={i}
          onClick={() => handleActionClick(result)}
        >
          {title}
        </Button>
      ))}
    >
      <div className="dialog-contents">
        <>
          <Text className="copy" color="gray" weight="bold">
            {title}
          </Text>
          {description && (
            <Text className="copy" color="gray">
              {description}
            </Text>
          )}
          {children}
        </>
      </div>

      <style jsx>{`
        .dialog-contents {
          margin-top: ${SPACING.lg}px;
        }
        .dialog-contents :global(.copy) {
          text-align: center;
          margin-bottom: ${SPACING.lg}px;
        }
        :global(.dialog .inner .button-group) {
          justify-content: center !important;
        }
        :global(.dialog .inner .button-group .button) {
          width: 100%;
        }
      `}</style>
    </SimpleModal>
  );
}
