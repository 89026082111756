import { Button } from '@homee/ui-web-presentation';
import { useCallback, useContext } from 'react';
import {
  RefetchActionType,
  RefetchContext,
} from '../../components/RefetchProvider';
import { toastSuccess, toastError } from '../../components/Toaster';
import { useNotificationReadAllMutation } from '../../lib/generated/types';

type Props = {
  onClick?: () => void;
};
const NotificationsAllReadButton: React.FC<Props> = ({ onClick }: Props) => {
  const [notificationReadAll] = useNotificationReadAllMutation();
  const { dispatch } = useContext(RefetchContext);
  const handleMarkAllAsRead = useCallback(async () => {
    try {
      onClick?.();
      await notificationReadAll();
      toastSuccess('Notifications marked as read.');
      dispatch({ type: RefetchActionType.Changed });
    } catch (e) {
      toastError(e, { defaultMessage: 'Error updating notifications' });
      return false;
    }
  }, [onClick, notificationReadAll, dispatch]);

  return (
    <Button
      className="mark-as-read"
      onClick={() => handleMarkAllAsRead()}
      type="button"
      size="x-small"
      variant="ghost"
    >
      Mark all as read
    </Button>
  );
};

export default NotificationsAllReadButton;
