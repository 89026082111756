import { assertNever } from './validation';

/** Function comparing two items that returns a number defining the sort order between them items.
 *  Return values have the following meanings:
 *  - zero (0) representing equality between a and b, and original order maintained
 *  - negative (-1) indicates that a should be sorted after b
 *  - positive (1) indicates that a should should be sorted before b */
export type Comparer<T> = (a: T, b: T) => number;

/**
 * Encapsulates comparison operations using standard sort comparers, enabling
 * writing expressive code when comparing two things that can't be compared
 * directly with native operators.
 */
export class Compare<T> {
  private base: T;
  private comparer: Comparer<T>;
  constructor(base: T, comparer: Comparer<T>) {
    this.base = base;
    this.comparer = comparer;
  }
  isLessThan(other: T): boolean {
    return this.comparer(this.base, other) === -1;
  }
  isGreaterThan(other: T): boolean {
    return this.comparer(this.base, other) === 1;
  }
  isEqual(other: T): boolean {
    return this.comparer(this.base, other) === 0;
  }
  isNotEqual(other: T): boolean {
    return this.comparer(this.base, other) !== 0;
  }
  isLessThanOrEqual(other: T): boolean {
    return this.comparer(this.base, other) <= 0;
  }
  isGreaterThanOrEqual(other: T): boolean {
    return this.comparer(this.base, other) >= 0;
  }
}

export function compare<T>(base: T, comparer: Comparer<T>): Compare<T> {
  return new Compare(base, comparer);
}

export function compareDate(date: Date): Compare<Date> {
  return new Compare(date, dateComparer);
}

/**
 * Create a comparer composed of multiple other comparers. The operands will be
 * tested in order that the comparers are provided.
 */
export function combinedComparer<T>(
  ...comparers: Array<Comparer<T>>
): Comparer<T> {
  return function (a: T, b: T): number {
    for (let i = 0; i < comparers.length; i++) {
      const result = comparers[i](a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
}

/**
 * Create a comparer that tests the value of a property provided
 */
export function subValueComparer<TIn, TProp>(
  getValue: (item: TIn) => TProp,
  comparer?: Comparer<TProp> | undefined,
): Comparer<TIn> {
  const innerComparer = comparer ?? getAnyComparer();

  return function (a: TIn, b: TIn): number {
    const aValue = getValue(a);
    const bValue = getValue(b);
    return innerComparer(aValue, bValue);
  };
}

/**
 * Create a comparer that tests the value of a property provided
 */
export function propertyComparer<T, TKey extends keyof T = keyof T>(
  key: TKey,
  comparer?: Comparer<T[TKey]> | undefined,
): Comparer<T> {
  const innerComparer = comparer ?? getAnyComparer();

  return function (a: T, b: T): number {
    return innerComparer(a[key], b[key]);
  };
}

/**
 * Comparer for numbers
 */
export const numberComparer: Comparer<number> = valueComparer;
export const booleanComparer: Comparer<boolean> = valueComparer;
export const stringComparer: Comparer<string> = valueComparer;
export const dateComparer: Comparer<Date> = (a: Date, b: Date) => {
  return valueComparer(a.valueOf(), b.valueOf());
};

function valueComparer(
  a: string | number | boolean | bigint,
  b: string | number | boolean | bigint,
) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function reverseComparer<T>(comparer: Comparer<T>): Comparer<T> {
  return (a: T, b: T) => {
    return comparer(b, a);
  };
}

/**
 * Create a comparer that prioritizes based on the ordinal position of an item
 * in an array
 */
export function getArrayIndexComparer<T>(arr: T[]): Comparer<T> {
  return (a: T, b: T) => {
    return numberComparer(arr.indexOf(a), arr.indexOf(b));
  };
}

/**
 * Creates a comparer for all type, using a user-provided object comparer.
 * If no object comparer is provided, it will compare dates for exact equality,
 * and otherwise always return -1 for unhandled objects.
 */
export function getAnyComparer(objectComparer?: Comparer<unknown>) {
  const finalObjectComparer =
    objectComparer ??
    ((a: unknown, b: unknown) => {
      if (a instanceof Date && b instanceof Date) {
        return dateComparer(a, b);
      }
      return -1;
    });

  return (a: unknown, b: unknown) => {
    if (typeof a !== typeof b) {
      return -1;
    }
    const type = typeof a;
    switch (type) {
      case 'bigint':
      case 'number':
      case 'boolean':
      case 'string':
        return valueComparer(
          a as bigint | number | boolean | string,
          b as bigint | number | boolean | string,
        );
      case 'symbol':
      case 'function':
        return a === b ? 0 : -1;
      case 'undefined':
        return 0;
      case 'object':
        break;
      default:
        assertNever(type);
    }
    if (a === null && b === null) {
      return 0;
    }
    return finalObjectComparer(a, b);
  };
}

export function getDateComparer(toleranceSeconds: number): Comparer<Date> {
  const toleranceMs = toleranceSeconds * 1000;
  return function (a: Date, b: Date): number {
    const ms1 = a.valueOf();
    const ms2 = b.valueOf();
    if (ms1 < ms2 - toleranceMs) return -1;
    if (ms2 < ms1 - toleranceMs) return 1;
    return 0;
  };
}
