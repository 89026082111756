import { customAlphabet } from 'nanoid/async';
import { asPromise } from './promises';

export const DEFAULT_SHORT_CODE_CHARACTERS = '23456789BCDFGHJKLMNPQRSTVWXYZ';
export const DEFAULT_SHORT_CODE_LENGTH = 8;

/**
 * Returns a short code string using specified characters and length
 * @param length The length of the short code. default is 8
 * @param characters The alphabet to use for the short code. default is uppercase letters and numbers excluding 0, 1, I, O to avoid confusion
 * @returns a short code string
 *
 * Note: this is a wrapper around nanoid. The probability of a collision for the default settings
 * is a 1% per ~100K ids generated.
 * Based on the calculator here https://zelark.github.io/nano-id-cc/
 */
export function generateShortCode(
  length = DEFAULT_SHORT_CODE_LENGTH,
  characters = DEFAULT_SHORT_CODE_CHARACTERS,
): Promise<string> {
  return customAlphabet(characters)(length);
}

/**
 * Returns a short code string using specified characters and length,
 * and checks against the provided existence check function to ensure uniqueness
 * up to the a maximum number of iterations
 *
 * @param check The function to check if the code already exists. A return value of true indicates the code exists
 * @param maxIterations The maximum number of iterations to try before throwing an error. default is 100
 * @param length The length of the short code. default is 8
 * @param characters The alphabet to use for the short code. default is uppercase letters and numbers excluding 0, 1, I, O to avoid confusion
 * @returns a short code string
 *
 * Note: this is a wrapper around nanoid. The probability of a collision for the default settings
 * is a 1% per ~100K ids generated.
 * Based on the calculator here https://zelark.github.io/nano-id-cc/
 */
export async function generateShortCodeWithCheck(
  check: (code: string) => boolean | Promise<boolean>,
  maxIterations = 10,
  length = DEFAULT_SHORT_CODE_LENGTH,
  characters = DEFAULT_SHORT_CODE_CHARACTERS,
): Promise<string> {
  let exists = true;
  let code = '';
  let count = 0;

  while (exists) {
    if (count > maxIterations) {
      throw new Error(
        `Could not generate unique short code after ${maxIterations} attempts`,
      );
    }
    code = await generateShortCode(length, characters);

    exists = await asPromise(check(code));
    count++;
  }

  return code;
}
