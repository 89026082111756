interface ClassyConstructor {
  new <T extends object>(target: T): T;
}

interface ClassyPlaceholderConstructor {
  new <T extends object>(): T;
}

/**
 * Classy is an extensible Proxy implementation. (The javascript Proxy cannot be
 * directly extended.)
 *
 * Classy should be used for creating classes that are designed simply to pass through
 * (or extend) a type or interface but where a class is desired. It avoids having to
 * create boilerplate class property implementations when the goal is simply a class
 * that mirrors (or extends) a plain object. The most common use case is using classes
 * as tokens for tsyringe.
 */
export const Classy: ClassyConstructor = class<T extends object> {
  constructor(obj: T) {
    Object.assign(this, obj);
    // eslint-disable-next-line no-constructor-return
    return new Proxy(this, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set: (object: any, key: any, value: any) => {
        object[key] = value;
        return true;
      },
    });
  }
} as ClassyConstructor;

/**
 * ClassyPlaceholder is used for creating class based interfaces not intended for instantiation.
 *  The most common use case is using classes as tokens for tsyringe for an implementation that will not be a class at runtime.
 */
export const ClassyPlaceholder: ClassyPlaceholderConstructor = class<T> {
  private constructor() {
    throw new Error('This cannot be constructed');
  }
} as ClassyPlaceholderConstructor;

/**
 * ClassyDynamic is used for creating class implementing an interface where the interface implementation is
 * dynamically generated at runtime. And there a proxied implementation is not necessary
 */
export const ClassyDynamic: ClassyPlaceholderConstructor = class<T> {
  constructor() {
    /* noop */
  }
} as ClassyPlaceholderConstructor;
