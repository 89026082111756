import { serializeError, ErrorObject } from 'serialize-error';
import type { StatusCodes } from 'http-status-codes';
import type { LogLevel } from '../log-level';

export class HomeeAggregateError extends Error {
  /**
   * LogLevel to use when logging this error to system logger
   */
  logLevel: LogLevel;
  innerErrors?: ErrorObject[];

  constructor(message: string, errors: unknown[]) {
    super(message);
    this.name = 'HomeeError';
    this.logLevel = 'error';
    this.innerErrors = errors.map((e) => serializeError(e, { maxDepth: 3 }));
  }

  toString(): string {
    let out = this.message;
    if (this.innerErrors) {
      out += this.innerErrors
        .map((e) => `\n${JSON.stringify(e, null, 4)}`)
        .join('\n------------------------\n');
    }
    return out;
  }
}
