import type { MaybePromise } from './types';

export async function asPromise<T>(value: MaybePromise<T>): Promise<T> {
  if (isThenable(value)) {
    return value;
  }
  return Promise.resolve(value);
}

function isThenable(obj: any): boolean {
  return (
    !!obj &&
    (typeof obj === 'object' || typeof obj === 'function') &&
    typeof obj.then === 'function'
  );
}

export type AggregateSettledFulfilledResult<T> = { result: T; index: number };
export type AggregateSettledRejectedResult = { error: unknown; index: number };
export type AggregateSettledResult<T> = T | unknown;

export type AggregateSettledResults<T> = {
  results: AggregateSettledFulfilledResult<T>[];
  interleaved: (T | unknown)[];
  errors: AggregateSettledRejectedResult[];
};

export function aggregateNestedSettledRejections(
  settledPromises: PromiseSettledResult<unknown>[],
): unknown[] {
  const errors: unknown[] = [];

  for (const promise of settledPromises) {
    if (
      promise.status === 'fulfilled' &&
      isSettledPromiseArray(promise.value)
    ) {
      errors.push(...aggregateNestedSettledRejections(promise.value));
    } else if (promise.status === 'rejected') {
      errors.push(promise.reason);
    }
  }

  return errors;
}

export function getSettledResults<T>(
  settledPromises: PromiseSettledResult<T>[],
): AggregateSettledResults<T> {
  const interleaved: AggregateSettledResult<T>[] = [];
  const results: AggregateSettledFulfilledResult<T>[] = [];
  const errors: AggregateSettledRejectedResult[] = [];

  settledPromises.forEach((promise, index) => {
    if (promise.status === 'rejected') {
      const value = { error: promise.reason, index };
      errors.push({ error: promise.reason, index });
      interleaved.push(promise.reason);
    } else {
      const value = { result: promise.value, index };
      results.push(value);
      interleaved.push(promise.value);
    }
  });

  const out: AggregateSettledResults<T> = {
    results,
    interleaved,
    errors,
  };

  return out;
}

export function getSettledRejectionResults(
  settledPromises: PromiseSettledResult<unknown>[],
): AggregateSettledRejectedResult[] {
  const errors: AggregateSettledRejectedResult[] = [];

  settledPromises.forEach((promise, index) => {
    if (promise.status === 'rejected') {
      errors.push({ error: promise.reason, index });
    }
  });

  return errors;
}

export function getSettledFulfilledResults<T>(
  settledPromises: PromiseSettledResult<T>[],
): AggregateSettledFulfilledResult<T>[] {
  const results: AggregateSettledFulfilledResult<T>[] = [];

  settledPromises.forEach((promise, index) => {
    if (promise.status === 'fulfilled') {
      results.push({ result: promise.value, index });
    }
  });

  return results;
}

function isSettledPromiseArray(
  item: any,
): item is PromiseSettledResult<unknown>[] {
  return (
    Array.isArray(item) &&
    item.length > 0 &&
    (item[0].status === 'fulfilled' || item[0].status === 'rejected')
  );
}
