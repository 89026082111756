export function getFormattedBytes({
  decimals = 0,
  bytesPerKB = 1000,
  maxSize,
}: {
  decimals?: number;
  bytesPerKB?: number;
  maxSize: number;
}) {
  if (maxSize === 0) return '0 Bytes';

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(maxSize) / Math.log(bytesPerKB));

  const out =
    parseFloat((maxSize / Math.pow(bytesPerKB, i)).toFixed(decimals)) +
    ' ' +
    sizes[i];

  return out;
}
