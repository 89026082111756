import type { Role } from '@homee/pro-portal/types-api';
import type { NextPage } from 'next';
import type { ReactElement, ReactNode } from 'react';

export type LayoutGetter = (page: ReactElement) => ReactNode;

// eslint-disable-next-line @typescript-eslint/ban-types
export type CustomNextPage<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: LayoutGetter;
  allowedRoles?: PageRole[];
};

export enum PageRoles {
  Public = 'public',
  Any = 'any',
}

export type PageRole = Role | PageRoles;
