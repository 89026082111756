import type { ILogger } from '../logger';

/**
 * Introducing ArraySlicer, a utility class that allows us to iterate over an
 * array of things, each with a user-specified slice size.
 *
 * Usage:
 *
 * const slicer = new ArraySlicer([1,2,3,4,5], 2);
 *
 * for (const slice of slicer) {
 *   ... do something with slice
 * }
 *
 */
export class ArraySlicer<T> {
  private currentIndex = 0;

  /**
   * Constructs an instance of ArraySlicer
   *
   * @param values the array of values
   * @param sliceSize the slice size
   * @param logger an implementation of ILogger
   */
  constructor(
    private readonly values: T[],
    private readonly sliceSize = 100,
    private readonly logger?: ILogger,
  ) {}

  [Symbol.iterator]() {
    return {
      next: () => {
        const first = this.currentIndex * this.sliceSize;
        const last = (this.currentIndex + 1) * this.sliceSize;
        this.logger?.info(
          `ArraySlicer#nextSlice first: ${first}, last: ${last}, length: ${this.values.length}`,
        );
        const slice = this.values.slice(first, last);

        this.currentIndex++;
        if (slice.length) {
          return {
            done: false,
            value: slice,
          };
        }

        return {
          done: true,
          value: [],
        };
      },
    };
  }
}

export function arrayIncludes<T extends any[]>(array: T, value: any): boolean {
  return array.includes(value);
}
