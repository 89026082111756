import { parseJson, stringifyJson } from './json';
import { mapObject } from './object-traverser';
import type { MaybeNullToUndefined, Paths, PickByPath } from './types';

/**
 * Deep clone an object. Use with caution. Cloning objects is inherently likely
 * to not do exactly what you want because of all the possible edge cases. This should
 * be used only for things like simple JSON structures.
 */
export function deepClone<T>(obj: T): T {
  return parseJson(stringifyJson(obj)) as T;
}

/**
 * Delete any keys with "undefined" values
 */
export function deleteUndefined<T extends Record<string, unknown>>(obj: T): T {
  if (!obj) return obj;

  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
}

/**
 * Delete any keys with `undefined` values
 */
export function deleteUndefinedOrNull<
  T extends Record<string, unknown> | undefined | null,
>(obj: T): MaybeNullToUndefined<T> {
  if (!obj) return undefined as MaybeNullToUndefined<T>;

  Object.keys(obj).forEach((key) => {
    if (obj[key] == null) {
      delete obj[key];
    }
  });

  return obj as MaybeNullToUndefined<T>;
}

/**
 * Delete any keys with `undefined` or `null` values
 */
export function deleteUndefinedNullOrEmpty<
  T extends Record<string, unknown> | undefined | null,
>(obj: T): MaybeNullToUndefined<T> {
  if (!obj) return undefined as MaybeNullToUndefined<T>;

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    // doing a loose null check to match null or undefined
    // then checking if there's a length prop and if its falsy (0)
    if (
      value == null ||
      (Object.hasOwn(value as any, 'length') && !(value as any).length)
    ) {
      delete obj[key];
    }
  });

  return obj as MaybeNullToUndefined<T>;
}

export function deepDeleteUndefined<T extends Record<string, unknown>>(
  obj: T,
): T {
  return mapObject(obj, (node) => {
    if (node.value !== undefined) {
      node.setValue(node.value);
    }
  });
}

/**
 * Removes keys from the provided object corresponding to the provided array of keys.
 * Mirrors the behavior of the Typescript Omit<> generic utility type
 *
 * @param item The object to remove fields from
 * @param keys The keys to exclude
 * @returns An object with the excluded fields removed
 */
export function excludeFields<
  T extends Record<string, any>,
  TKeys extends keyof T,
>(item: T, keys: TKeys[]): Omit<T, TKeys> {
  if (!item || !keys?.length) return item;

  const fields = Object.entries(item).reduce(
    (o, [k, v]) => {
      const key = k as TKeys & Exclude<keyof T, TKeys>;
      if (!keys.includes(key)) {
        o[key as Exclude<keyof T, TKeys>] = v;
      }

      return o;
    },
    {} as Omit<T, TKeys>,
  );

  return fields;
}

/**
 * Removes keys from the object that do not corresponding to the provided array of keys.
 * Mirrors the behavior of the Typescript Pick<> generic utility type
 *
 * @param item The item to select fields from
 * @param keys The field keys to select
 * @returns An object that only includes the selected fields
 */
export function pickFields<
  T extends Record<string, any>,
  TKeys extends keyof T,
>(item: T, keys: TKeys[]): Pick<T, TKeys> {
  if (!item) return item;
  keys = keys ?? [];

  const fields = Object.entries(item).reduce(
    (o, [k, v]) => {
      const key = k as TKeys;
      if (keys.includes(key)) {
        o[key] = v;
      }

      return o;
    },
    {} as Pick<T, TKeys>,
  );

  return fields;
}

export function pickByPath<T, TKey extends Paths<T>>(
  input: T,
  path: TKey,
): PickByPath<T, TKey> {
  const parts = path.split('.');
  let current: any = input;
  while (parts.length) {
    const part = parts.shift();
    if (!part) break;

    if (isScalar(current)) return current as PickByPath<T, TKey>;

    current = current[part];

    if (Array.isArray(current)) {
      const subPath = parts.join('.');
      return current.map((item) => pickByPath(item, subPath)) as PickByPath<
        T,
        TKey
      >;
    }
  }

  return current as PickByPath<T, TKey>;
}

export function isScalar(value: unknown): boolean {
  if (value === null) return true;

  if (value instanceof Date) return true;

  const type = typeof value;

  switch (type) {
    case 'string':
    case 'number':
    case 'boolean':
    case 'undefined':
    case 'bigint':
    case 'symbol':
      return true;
    default:
      return false;
  }
}

/**
 * Like @see pickByPath but used in places where we can't guarantee the path
 * is part of the input at compile time.
 *
 * When an undefined or null is found in the path, but it isn't the leaf node, it returns undefined.
 */
export function pickByPathUnsafe<T>(input: T, path: string): any {
  const parts = path.split('.');
  let current: any = input;
  while (parts.length) {
    const part = parts.shift();
    if (!part) break;

    if (current === undefined || current === null) return undefined;

    current = current[part];

    if (Array.isArray(current)) {
      const subPath = parts.join('.');
      return current.map((item) => pickByPathUnsafe(item, subPath));
    }
  }

  return current;
}
