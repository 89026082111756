import isEqual from 'lodash/isEqual';

export type DifferenceResult<TExisting, TIncoming> = {
  create: TIncoming[];
  remove: TExisting[];
  update: { existing: TExisting; incoming: TIncoming }[];
};

/**
 * Computes a list of items to create, update, and remove from 2 lists of items using an identifier and comparator function.
 *
 * default identifier is the item itself
 * default comparator is lodash isEqual
 *
 * If identifier on incoming item is falsy, the item will be added to the create list.
 * If identifier on incoming item is truthy:
 *   If no matching identifier in existing, the incoming item will be added to the create list.
 *   If comparator is falsy for matched incoming and existing the incoming item will be added to the update list.
 * All unmatched existing items will be added to the remove list.
 */

/**
 * Computes a list of items to create, update, and remove from 2 lists of items using an identifier and comparator function.
 *
 * If identifier on incoming item is falsy, the item will be added to the create list.
 * If identifier on incoming item is truthy:
 *   If no matching identifier in existing, the incoming item will be added to the create list.
 *   If comparator is falsy for matched incoming and existing the incoming item will be added to the update list.
 * All unmatched existing items will be added to the remove list.
 *
 * @param existing the existing list of items
 * @param incoming the new list of items
 * @param identifier function to get the identifier for a new or existing item. Used to match incoming and existing items. Default is the item itself.
 * @param comparator function to compare incoming and existing items. Used to determine if the item should be updated. Default is lodash isEqual.
 * @returns DifferenceResult that includes a list of items to create, update, and remove
 */
export function difference<TExisting, TIncoming>(
  existing?: TExisting[],
  incoming?: TIncoming[],
  identifier: (item: TExisting | TIncoming) => any = (item) => item,
  comparator: (existing: TExisting, incoming: TIncoming) => boolean = (a, b) =>
    isEqual(a, b),
): DifferenceResult<TExisting, TIncoming> {
  const existingMap = new Map(existing?.map((i) => [identifier(i), i]) ?? []);

  const reduced = (incoming ?? []).reduce(
    (acc, i) => {
      if (!i) return acc;

      const id = identifier(i);

      if (!id) {
        acc.create.push(i);
      } else {
        if (existingMap.has(id)) {
          const e = existingMap.get(id)!;
          if (!comparator(e, i)) {
            acc.update.push({ existing: e, incoming: i });
          }
          acc.remove.delete(id);
        } else {
          acc.create.push(i);
        }
      }

      return acc;
    },
    {
      create: [] as TIncoming[],
      remove: existingMap,
      update: [] as { existing: TExisting; incoming: TIncoming }[],
    },
  );

  const out: DifferenceResult<TExisting, TIncoming> = {
    ...reduced,
    remove: Array.from(reduced.remove.values()),
  };

  return out;
}
