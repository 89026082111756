export const repairJobDocFileAcceptValues = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
];

export const imageFileAcceptValues = ['image/png', 'image/jpeg', 'image/webp'];

export const videoFileAcceptValues = ['video/mp4'];

export const estimateDocFileAcceptValues = [
  '.esx',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
