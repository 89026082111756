import type { AuthUser } from '@homee/pro-portal/types-api';
import { getSession, GetSessionParams, signOut } from 'next-auth/react';
import type { TypedSession } from '../../hooks/useTypedSession';

/**
 * Gives you access to the logged in user's session data,
 * using the {@link AuthUser} type as the Session.user type
 *
 * Works identical to NextAuth's getSession
 * [Documentation](https://next-auth.js.org/getting-started/client#getsession)
 */
export async function getTypedSession(params?: GetSessionParams) {
  const session = (await getSession(params)) as TypedSession<AuthUser>;

  const error = (session?.user as AuthUser)?.e;

  if (error && global.window) {
    // TODO: better logging
    // eslint-disable-next-line no-console
    console.log(
      `There was an error generating or refreshing authorization. ${error}`,
    );

    await signOut();

    return;
  }

  return session as unknown as TypedSession<AuthUser>;
}
