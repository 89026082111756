import {
  BREAKPOINT_VALUE,
  COLOR,
  Icon,
  MOBILE_HEADER_HEIGHT,
  SPACING,
  Z_INDEX,
} from '@homee/ui-web-presentation';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import UserAvatar from '../../components/UserAvatar';
import { useAppContext } from '../../hooks/useAppContext';
import { useUser } from '../../hooks/useUser';
import NotificationButton from './NotificationButton';
import SelectCompany from './SelectCompany';

type Props = {
  setIsMobileNavOpen: (boolean) => void | Promise<void>;
};

const Header: React.FC<Props> = ({ setIsMobileNavOpen }) => {
  const { user } = useUser();
  const { companies } = useAppContext();

  return (
    <header>
      <div className="logo">
        <Link href="/">
          <Image
            src="/pro-portal.svg"
            alt="HOMEE PRO PORTAL"
            height="34"
            width="33"
          />
        </Link>
      </div>
      <div className="mobile-actions">
        <button
          className="menu-button"
          type="button"
          onClick={() => {
            void setIsMobileNavOpen((prev) => !prev);
          }}
        >
          <Icon icon="menu" />
        </button>
      </div>
      {companies?.length > 1 && (
        <div className="select-company-wrapper">
          <SelectCompany />
        </div>
      )}
      <div className="notification-wrapper">
        <NotificationButton />
      </div>
      <div className="account-wrapper">
        <Link href="/account">
          <UserAvatar size="mini" user={user} />
        </Link>
      </div>
      <div className="search-wrapper"></div>
      <style jsx>
        {`
          header {
            display: flex;
            align-items: center;
            flex: none;
            height: 64px;
            z-index: ${Z_INDEX.mainHeader};
          }

          .logo,
          .notification-wrapper,
          .account-wrapper {
            padding: 0 ${SPACING.sm}px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .account-wrapper {
            order: 5;
          }

          .search-wrapper {
            flex: 1;
            padding: 0;
            order: 2;
          }

          .select-company-wrapper {
            order: 3;
            margin-right: ${SPACING.md}px;
          }

          .notification-wrapper {
            order: 4;
            margin-right: ${SPACING.sm}px;
          }

          .notification-wrapper button {
            background: none;
          }

          .mobile-actions {
            display: none;
            order: 5;
          }

          .logo a {
            display: block;
            width: 33px;
            color: ${COLOR.brandRed};
            text-align: center;
            margin-top: 3px;
          }

          .logo a :global(svg) {
            max-width: ${SPACING.md}px;
          }

          .menu-button {
            background: none;
            border: none;
            color: ${COLOR.darkGray};
            width: ${SPACING.md}px;
            height: ${SPACING.md}px;
            padding: 0;
            display: flex;
            align-items: center;
          }

          .menu-button:hover,
          .menu-button:focus {
            color: ${COLOR.black};
          }

          .logo :global(svg),
          .menu-button :global(svg) {
            width: 100%;
            height: auto;
          }

          .logo {
            display: none;
          }

          @media (min-width: ${BREAKPOINT_VALUE.xl + 0.02}px) {
            .logo {
              flex: 1;
              justify-content: left;
              max-width: 233px; // market selector + account
            }
          }

          @media (max-width: ${BREAKPOINT_VALUE.sm - 0.02}px) {
            .logo {
              display: flex;
            }

            header {
              width: 100%;
              position: fixed;
              flex-flow: row wrap;
              height: ${MOBILE_HEADER_HEIGHT}px;
              justify-content: space-between;
              padding: ${SPACING.sm}px 0;
            }

            .logo,
            .notification-wrapper,
            .mobile-actions,
            .account-wrapper {
              height: 32px;
            }

            .account-wrapper {
              display: none;
            }

            .select-company-wrapper {
              margin-right: 0;
            }

            .notification-wrapper {
              margin-left: 0;
              margin-right: 0;
            }

            .logo,
            .mobile-actions {
              padding: 0 ${SPACING.sm}px;
            }
            .mobile-actions {
              display: flex;
              align-items: center;
            }
          }
        `}
      </style>
    </header>
  );
};

export default Header;
