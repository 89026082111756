import type {
  AuthUserV0,
  AuthUserV1,
  AuthUserV2,
  MinimalUser,
} from './auth-user.types';
import { LanguageCodes } from './common.types';
import { CURRENT_AUTH_USER_VERSION, PartialAuthUser } from './types';

export enum MigrationStatus {
  NotMigrated = 1,
  Migrated = 2,
  RequiresRefresh = 3,
}

export type MigrationResult<TUser extends PartialAuthUser = PartialAuthUser> = {
  status: MigrationStatus;
  user: TUser;
};

function migrateFrom0(
  prevUser: AuthUserV0,
): MigrationResult<Omit<AuthUserV1, 'stat' | 'cos'>> {
  const {
    status,
    languageCode,
    firstName,
    lastName,
    apiToken,
    graphToken,
    id,
    email,
    roles,
  } = prevUser;

  const user: Omit<AuthUserV1, 'stat' | 'cos'> = {
    v: 1,
    id,
    email,
    roles,
    lang: languageCode ?? LanguageCodes.English,
    name: `${firstName} ${lastName}`.trim(),
    at: apiToken,
    gt: graphToken,
  };

  return { status: MigrationStatus.RequiresRefresh, user };
}

function migrateFrom1(
  prevUser: AuthUserV1,
):
  | { status: MigrationStatus.Migrated; user: AuthUserV2 }
  | { status: MigrationStatus.RequiresRefresh; user: Omit<AuthUserV2, 'r'> } {
  const { cos, v, val, id, email, stat, lang, name, at, gt, roles, e } =
    prevUser;

  const key = Object.keys(cos ?? {}).find((x) => !!x);
  const company = key ? cos[key] : undefined;
  const companyRoles = company?.roles;

  const partialUser: Omit<AuthUserV2, 'r'> = {
    val,
    id,
    email,
    stat,
    lang,
    name,
    at,
    gt,
    e,
    v: 2,
  };

  if (companyRoles) {
    const user: AuthUserV2 = { ...partialUser, r: companyRoles };

    return { status: MigrationStatus.Migrated, user };
  }

  return { status: MigrationStatus.RequiresRefresh, user: partialUser };
}

const migrations = new Map<number, (x: any) => MigrationResult>([
  [0, migrateFrom0],
  [1, migrateFrom1],
]);

export function migrate(user: MinimalUser): MigrationResult {
  let status = MigrationStatus.NotMigrated;
  while (user.v !== CURRENT_AUTH_USER_VERSION) {
    const version = user.v ?? 0;
    const migration = migrations.get(version);

    if (!migration)
      return { status: MigrationStatus.RequiresRefresh, user: { id: user.id } };

    const { status: currentStatus, user: migratedUser } = migration(user);
    user = migratedUser;
    status = Math.max(status, currentStatus);
  }

  return { status, user };
}
